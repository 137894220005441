import React, {Component, Fragment} from "react";
import Arealist from "./AreaList";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import Layout from "../Layout/Custom_Layout/Layout";
import { isAdmin } from "../../helpers/userPolicy";
import { ReservationContext } from "../../contexts/ReservationContext";

class AreaRate extends Component{
  static contextType = ReservationContext;

  componentDidMount() {
    if (!isAdmin()){
      this.props.history.push("/unauthorized");
    }
  }
  
  render(){
    const { onlyAreaNames, showSpinner } = this.context
    return (
       <Layout sidebarSelectedKey="rates">
        <Fragment>
          <Row>
            <Col> 
              <Arealist 
                areaList = {onlyAreaNames}
                showSpinner = {showSpinner}
              />
            </Col>
          </Row>
        </Fragment>
      </Layout>
    )
  }
}
export default withRouter(AreaRate);