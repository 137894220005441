import React, { Component, Fragment } from "react";
import Media from "react-media";
import Sidebar from "../sidebar/Sidebar";
import { Layout, Icon, Dropdown, Row, Col } from "antd";
import "../css/layout.css";

const { Header, Sider, Content, Footer } = Layout;

class CustomLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      dropdownVisibility: false,
      mobileView: false
    };
    this.toggle = this.toggle.bind(this)
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  handleDropDownVisibility = () => {
    var element = document.getElementsByClassName("ant-layout")[0];
    if(!this.state.dropdownVisibility) {
      element.style.visibility = 'hidden';
      this.setState({
        dropdownVisibility: true,
        mobileView: true
      })
    }else{
      element.style.visibility = 'visible';
      this.setState({
        dropdownVisibility: false,
        mobileView: false
      })
    }
  }

  render() {
  const { innerWidth, innerHeight } = window
    return (
      <Layout>
        <Media
          queries={{
            small: "(max-width: 599px)",
            medium: "(min-width: 600px) and (max-width: 1199px)",
            large: "(min-width: 1200px)"
          }}
        >
          {matches => (
            <Fragment>
              {matches.small ? null : (
                <Sider
                  trigger={null}
                  collapsible
                  collapsed={
                    matches.large
                      ? this.state.collapsed
                      : !this.state.collapsed
                  }
                  style={{ padding: "24px 0", background: "#fcfcfc" }}
                >
                  <Sidebar selectedKey={this.props.sidebarSelectedKey} />
                </Sider>
              )}

              <Layout>
                <Header style={{ background: "#fff", padding: 0 }}>
                  {matches.small ? (
                    <Row>
                      <Col offset={1}>
                        <Dropdown
                          visible = {this.state.dropdownVisibility}
                          overlay={
                            <Sidebar
                              smallScreen = {true}
                              mobileView = {this.state.mobileView}
                              toggle = {this.handleDropDownVisibility}
                            />
                          }
                          trigger={["click"]}
                          onClick={()=> this.handleDropDownVisibility()}
                          overlayStyle={{width: innerWidth, height: innerHeight}}
                        >
                          <a className="ant-dropdown-link" href="#asdfg">
                            <span>
                              <Icon type="menu" style = {{fontSize: '1.5em'}}/>
                            </span>
                          </a>
                        </Dropdown> &nbsp;
                        <span>SPOT N PARK</span>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={1}>
                        <Icon
                          className="trigger"
                          type={
                            this.state.collapsed ? "menu-unfold" : "menu-fold"
                          }
                          onClick={this.toggle}
                        />
                      </Col>
                      <Col offset={1}>
                        <span style={{ margin: "1em" }}><i><b>SPOT N PARK</b></i></span>
                      </Col>
                    </Row>
                  )}
                </Header>

                <Content
                  style={{
                    margin: "2em 1em",
                    background: "#fff",
                    minHeight: matches.small
                      ? "10em"
                      : matches.medium
                      ? "30em"
                      : "50em",
                    padding: "1em"
                  }}
                >
                  {this.props.children}
                </Content>

                <Footer style={{ textAlign: "center" }}>
                  Codyssey Design ©2019 Created by Codyssey Web Nepal
                </Footer>
              </Layout>
            </Fragment>
          )}
        </Media>
      </Layout>
    );
  }
}

export default CustomLayout;
