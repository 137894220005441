import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  DatePicker,
  Collapse,
  Select,
  Input,
} from "antd";
import { withRouter } from "react-router-dom";
import {
  startTime,
  endTime
} from "../../constants/ParkedVehicleFilterConstants";

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

class FilterForm extends Component {
  constructor() {
    super();
    this.state = {
      startTime: startTime,
      endTime: endTime,
    };
  }

  startTimeValidator = (rule, value, callback) => {
    if (this.state.endTime && value && value > this.state.endTime) {
      callback("Invalid start time");
    }
    callback();
  };

  endTimeValidator = (rule, value, callback) => {
    if (this.state.startTime && value && value < this.state.startTime) {
      callback("Invalid end time");
    }
    callback();
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.handleFilter(values);
      }
    });
  };

  onChange = (value, dateString) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);

  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { users, areas, handleClear, showSpinner, vehicleTypes } = this.props;
    const dateFormat = "MM/DD/YYYY HH:mm";

    return (
      <Collapse>
        <Panel
          header="Filter Parked Vehicles"
          key="1"
          style={{ width: "100%" }}
        >
          <div>
            <Form name="advanced_search" onSubmit={this.onSubmit}>
              <Row gutter={10}>
                <Col xl={8} sm={12}>
                  <span>Vehicle Type:</span>
                  <Form.Item style={{ marginBottom: 5 }}>
                    {getFieldDecorator("vehicle_cat")(
                      <Select
                        showSearch
                        placeholder="Select Vehicle Type"
                        optionFilterProp="children"
                        allowClear={true}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {vehicleTypes.map((vehicleType) => {
                          return (
                            <Option
                              value={vehicleType.vehicle_cat}
                              key={vehicleType.id}
                            >
                              {vehicleType.vehicle_cat}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xl={8} sm={12}>
                  <span>Parked By:</span>
                  <Form.Item style={{ marginBottom: 5 }}>
                    {getFieldDecorator("user_id")(
                      <Select
                        showSearch
                        placeholder="Select attendant"
                        optionFilterProp="children"
                        allowClear={true}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {users.map((user) => {
                          return (
                            (user.role === "admin" ||
                              user.role === "employee") && (
                              <Option value={user.id} key={user.id}>
                                {user.name}
                              </Option>
                            )
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xl={8} sm={12}>
                  <span>Area:</span>
                  <Form.Item style={{ marginBottom: 5 }}>
                    {getFieldDecorator("area_id")(
                      <Select
                        showSearch
                        placeholder="Select Area"
                        optionFilterProp="children"
                        allowClear={true}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {areas.map((area) => {
                          return (
                            <Option value={area.id} key={area.id}>
                              {area.street}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xl={8} sm={12}>
                  <span>Vehicle Number:</span>
                  <Form.Item name={"vehicle_no"} style={{ marginBottom: 5 }}>
                    {getFieldDecorator("vehicle_no")(
                      <Input placeholder="Enter Vehicle Number" />
                    )}
                  </Form.Item>
                </Col>

                <Col xl={8} sm={12}>
                  <span>Date:</span>
                  <Form.Item name={"date_range"} style={{marginBottom: 5}}>
                    {getFieldDecorator("date_range", {
                    })(
                      <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format={dateFormat}
                        disabledDate={(current) => {
                          let end = new Date();
                          if (current > end) {
                            return true;
                          }
                          return false;
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={showSpinner}
                  >
                    Filter
                  </Button>
                  <Button
                    style={{ margin: "0 8px" }}
                    disabled={showSpinner}
                    onClick={() => {
                      handleClear();

                      this.props.form.resetFields();
                    }}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Panel>
      </Collapse>
    );
  }
}

export default withRouter(Form.create({})(FilterForm));
