import React, { Component } from "react";
import { withRouter } from "react-router";
import { Input, Row, Col, Button, Form, Tooltip, Icon } from "antd";
import { reservationActions } from "../../actions/ReservationActions";
import { ReservationContext } from "../../contexts/ReservationContext";
import { error, success } from "../../helpers/Notification";
import { isAdmin, isEmployee } from "../../helpers/userPolicy";
import { getErrorMessages } from "../../helpers/getErrors"
import { parkedVehicleActions} from "../../actions/ParkedVehicleActions";
import { endTime, startTime } from "../../constants/ParkedVehicleFilterConstants";

class FormReservation extends Component {
  static contextType = ReservationContext;

  constructor() {
    super();
    this.state = {
      createButton: false,
    };
  }

  componentDidMount() {
    this.previousContext = this.context;
  }

  componentDidUpdate() {
    if (this.previousContext.spotId !== this.context.spotId) {
      this.props.form.resetFields("vehicle_no");
    }
    this.previousContext = this.context;
  }

  handleParkSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {   
      if (!err) {
        this.setState({ createButton: true });
        parkedVehicleActions.directPark(values).then(response => {
          this.setState({ createButton: false });
          this.context.onClose();
          if (response.status === 201) {
            this.context.setDirectParked(true, response.data)
            this.context.setSpots(this.context.areaId, this.context.vehicleTypeId);
            this.props.form.resetFields();
            success("Parked Sucessfully!");
            this.props.history.push(
              `/parked-vehicles?start_date=${startTime}&end_date=${endTime}&page=1`
            );
          } else if (response.data.errors) {
              getErrorMessages(response.data.errors)
          } else {
              error("Something went wrong. Please try again.");
          }
        });
      }
    });
  };

  handleReserveSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ createButton: true });
          reservationActions.createReservation(values).then(response => {
            if (response.status === 201) {
              this.context.onReversed();
              this.props.history.push("/reservations/user");
            } else if (response.data.errors) {
                getErrorMessages(response.data.errors)
            } else {
                error("Something went wrong. Please try again.");
            }
            this.setState({ createButton: false });
            this.context.onClose();
          });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { areaId, spot } = this.context;
  
    return (
      <div>
        <Form>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span>
                    Vehicle No.&nbsp;
                    <Tooltip title="Number of your Vehicle">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("vehicle_no", {
                  rules: [
                    {
                      whitespace: true
                    }
                  ]
                })(
                  <Input
                    placeholder={"Enter your Vehicle Number"}
                    name="vehicle_no"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={0}>
              <Form.Item>
                {getFieldDecorator("vehicle_cat", {
                  rules: [
                    {
                      required: true
                    }
                  ],
                  initialValue: spot.spot_vehicle_type
                })(<Input type="hidden" name="user_id" />)}
              </Form.Item>
            </Col>

            <Col span={0}>
              <Form.Item>
                {getFieldDecorator("hourly_rate", {
                  rules: [
                    {
                      required: true
                    }
                  ],
                  initialValue: spot.spot_rate
                })(<Input type="hidden" name="user_id" />)}
              </Form.Item>
            </Col>

            <Col span={0}>
              <Form.Item>
                {getFieldDecorator("area_id", {
                  rules: [
                    {
                      required: true
                    }
                  ],
                  initialValue: areaId
                })(<Input type="hidden" name="area_id" />)}
              </Form.Item>
            </Col>

            <Col span={0}>
              <Form.Item>
                {getFieldDecorator("spot_id", {
                  rules: [
                    {
                      required: true
                    }
                  ],
                  initialValue: spot.id||spot.spot_id
                })(<Input type="hidden" name="user_id" />)}
              </Form.Item>
            </Col>

            <Col span={isAdmin() || isEmployee() ? 10 : 22} offset="1">
              <Form.Item>
                <Button
                  type="primary"
                  onClick={this.handleReserveSubmit}
                  block
                  className="btn-right"
                  id="reserve"
                  disabled={this.state.createButton}
                >
                  Reserve
                </Button>
              </Form.Item>
            </Col>

            {isAdmin() || isEmployee() ? (
              <Col span="10" offset="1">
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleParkSubmit}
                    block
                    className="btn-right"
                    disabled={this.state.createButton}
                  >
                    Park
                  </Button>
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(Form.create({})(FormReservation));
