import React, { Component } from "react";
import { Row, Col, Divider, Alert, Modal, List, Card, Tag } from "antd";
import Media from "react-media";
import { checkStatus } from "../../helpers/SpotCheckStatus"
import LoadingSpinner from "../loadSpinner/loadSpinner";

class ShowBoard extends Component {
  render() {
    const { showBoardVisible, onCancel, board } = this.props;
    const {total_available_spots, total_parked_spots, total_booked_spots, total_spots, total_unavailable_spots} = this.props.spotCounts
    const spots = board.spots;
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)"
        }}
      >
        {(matches) => (
          <Modal
            title=""
            centered
            visible={showBoardVisible}
            onCancel={onCancel}
            footer={null}
            width={matches.medium ? "54em" : "75em"}
          >
          { this.props.isLoading ? (
            <LoadingSpinner/>
          ) : (
            <div>
            <br />
            <Row>
              <Col
                offset={2}
                span={20}
                style={{ background: "rgb(217, 217, 217, 217)", height: 35 }}
              >
                <b>
                  <Alert message="Board Details" type="default" />
                </b>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col offset={3} span={18} gutter={2}>
                <Alert message={board.name} type="info" showIcon />
              </Col>
            </Row>
            <Row>
              <Col offset={3} span={18}>
                <Divider />
              </Col>
            </Row>
            <Row justify="space-around">
              <Col offset={1} span={3} style={{marginLeft:"6em"}}>
              <Tag 
                  style={{ fontSize: "110%"}}
                  color="geekblue"
                > {`Total Spots: ${total_spots}`}
                </Tag>
              </Col>
              <Col offset={1} span={3}>
              <Tag 
                  style={{fontSize: "110%"}}
                  color="green"
                > {`Available Spots: ${total_available_spots}`}
                </Tag>
              </Col>
              <Col offset={1} span={3}>
                <Tag 
                    style={{fontSize:'110%'}}
                    color="blue"
                  > {`Booked Spots: ${total_booked_spots}`}
                </Tag>
              </Col>
              <Col offset={1} span={3}>
              <Tag 
                  style={{fontSize:'1.2em'}}
                  color="gold"
                > {`Parked Spots: ${total_parked_spots}`}
                </Tag>
              </Col>
              <Col offset={1} span={3}>
              <Tag 
                  style={{fontSize: "110%"}}
                  color="red"
                > {`Unavailable Spots: ${total_unavailable_spots}`}
                </Tag>
              </Col>
            </Row> 
            <Row>
              <Col offset={2} span={20} gutter={2}>
                <Divider />
              </Col>
            </Row>
            <Row>
              <Col offset={2} span={20} gutter={2}>
                <div style={{ 
                      overflow: "auto",
                      maxHeight: `${matches.medium ? "330px" : "500px"}`
                }}>
                  <List 
                     grid={{
                      gutter: 3,
                      xs: 4,
                  }}>
                    {spots && spots.map(spot => {
                      const { spot_name, street_name }  = spot
                      const { color, symbol } = checkStatus(spot)
                      return(
                        <List.Item>
                          <Card size="small">
                            <h3>{spot_name}</h3>
                            <Tag color={color}>{symbol}</Tag>
                            <p> Area: {street_name}</p>
                          </Card>
                        </List.Item>
                      )
                    })}
                  </List>
                </div>
              </Col>
            </Row>
          </div>
           )}
          </Modal>
        )}
      </Media>
    );
  }
}
export default ShowBoard;
