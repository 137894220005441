import customHttp from "../helpers/CustomHttp";
import { authHeader } from "../helpers/AuthHeader";

export const areaSlipDetailActions = {
  createAreaSlipDetail,
  getAreaSlipDetail,
  updateAreaSlipDetail
};

function createAreaSlipDetail(values){
  const requestOptions={
    method: 'POST',
    headers: authHeader(),
    url: '/slip_details',
    data: values
  }
  return customHttp(requestOptions).then(response =>{
    return response
  })
}

function getAreaSlipDetail(areaId){
  const requestOptions={
    method: 'GET',
    headers: authHeader(),
    url : `/area/slip_details?area_id=${areaId}`
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function updateAreaSlipDetail(id, values) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    url : `/slip_details/${id}`,
    data: values
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}