import React, { Component } from "react";
import { Table, Divider, Modal, Switch, Icon, Tooltip, Pagination} from "antd";
import { areaActions } from "../../actions/AreaActions";
import { spotActions } from "../../actions/SpotActions";
import { withRouter} from "react-router-dom";
import Show from "./ShowArea";
import Edit from "./EditArea";
import EditSpot from "../Spot/EditSpot";
import { success, error } from "../../helpers/Notification";
import { areaHelper } from "./AreaHelper";
import { getErrorMessages } from "../../helpers/getErrors";
import { ReservationContext } from "../../contexts/ReservationContext";
import queryString from "query-string";
import Spinner from "../loadSpinner/loadSpinner";

const { confirm } = Modal;


class TableArea extends Component {
  static contextType = ReservationContext;

  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      showVisible: false,
      editSpot: false,
      areaId: "",
      spot: {},
      area: {},
      spotArea: {},
      areaEditButton: false,
      spotEditButton: false,
      areaSpots: [],
      expandedRowKeys: "",
      showSpinner: false,
      updatedAreaSwitch: ''
    };
    this.setShowModalVisible = this.setShowModalVisible.bind(this);
    this.setEditModalVisible = this.setEditModalVisible.bind(this);
    this.deleteArea = this.deleteArea.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  setShowModalVisible(area) {
    this.setState({ showVisible: true });
    this.setState({ area });
  }

  setEditModalVisible(area) {
    this.setState({ editVisible: true });
    this.setState({ area });
  }

  setSpotEditModalVisible(spot, area) {
    this.setState({ editSpot: true, spot, spotArea: area });
  }

  handleCancel = () => {
    this.setState({ editVisible: false, showVisible: false, editSpot: false });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  fetchSpots = async (expanded, areaId) => {
    this.setState({showSpinner: true, expandedRowKeys: areaId})
    if(!expanded) {
      this.setState({expandedRowKeys: ""})
    }else {
      await areaActions.fetchAreaSpots(areaId).then(async response => {
        await this.setState({areaSpots: response.data, showSpinner: false})
      });
    }
  }

  expandedRowRender = e => {
    if (this.state.showSpinner){
      return (<Spinner />)
    }else{
      const columns = [
        { title: "Name", dataIndex: "name", key: "name" },
        {
          title: "Availability",
          key: "availability",
          render: record => {
            return (
              <span>
                <Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  checked={record.availability}
                  onChange={e => this.updateBooleanAttribute(e, record.id, 'availability')}
                />
              </span>
            );
          }
        },
        {
          title: "Is Reservable?",
          key: "is_reservable",
          render: record => {
            return (
              <span>
                <Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  checked={record.is_reservable}
                  onChange={e => this.updateBooleanAttribute(e, record.id, 'is_reservable')}
                />
              </span>
            );
          }
        },
        {
          title: "Vehicle Category",
          dataIndex: "spot_vehicle_type",
          key: "spot_vehicle_type"
        },
        {
          title: "Action",
          key: `spotActionOfArea${e.id}`,
          render: record => {
            return (
              <span>
                <Tooltip title="Edit Spot">
                  <span
                    onClick={() =>
                      this.setSpotEditModalVisible(record, e)
                    }
                  >
                    <Icon type="edit" title="edit" />
                  </span>
                </Tooltip>

                <Divider type="vertical" />
                <Tooltip title="Delete Spot">
                  <span onClick={() => this.showSpotConfirm(record)}>
                    <Icon type="delete" />
                  </span>
                </Tooltip>
              </span>
            );
          }
        }
      ];
      return <Table
        columns={columns}
        dataSource={this.state.areaSpots}
        pagination={{ defaultPageSize: 5}}
        rowKey={column => column.id}
      />
    }
  };

  // **************Area*****************
  handleEdit = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ areaEditButton: true });
        values["area_shape"] = areaHelper.formatValues(values["coordinates"]);
        delete values["coordinates"];
        if (values.spots_attributes) {
          values = areaHelper.prepareSpotShape(values);
        }
        areaActions.updateArea(values, this.state.area.id).then(response => {
          if (response.status === 204) {
            this.props.fetchAreas(this.props.location.search);
            this.fetchSpots(this.state.expandedRowKeys === "" ? false : true, this.state.area.id);
            this.setState({ editVisible: false });
            success("Area has been sucessfully updated.");
          } else {
            getErrorMessages(response.data.errors)
          }
          this.setState({ areaEditButton: false });
        });
      }
    });
  };

  showConfirm(area) {
    confirm({
      title: "Do you want to delete this area?",
      content: "Area street => " + area.street,
      onOk: () => this.deleteArea(area.id),
      onCancel() {}
    });
  }

  deleteArea(areaId) {
    areaActions.deleteArea(areaId).then(response => {
      if (response.status === 204) {
          this.props.fetchAreas(this.props.location.search);
          this.setState({ editVisible: false });
          success("Area has been sucessfully deleted.");
      } else {
        getErrorMessages(response.data.errors);
      }
    });
  }

  updateAreaAttributes = (checked, areaId, updatedKey) => {
    var formattedValues = {}
    formattedValues[updatedKey] = checked
    this.setState({updatedAreaSwitch: areaId})
    areaActions.updateArea(formattedValues, areaId).then(response => {
      if (response.status === 204) {
        if(this.state.expandedRowKeys === areaId) {
          this.fetchSpots(true, areaId)
        }
        this.props.fetchAreas(this.props.location.search);
        success("Area has been sucessfully updated.");
      } else {
        getErrorMessages(response.data.errors || "Something went wrong. Please try again.");
      }
      this.setState({updatedAreaSwitch: ''})
    });
  };
  // **************Area*****************

  // **************Spot*****************
  handleSpotEdit = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ spotEditButton: true });
        var newValue = {
          spot: {}
        };

        newValue["spot"] = {
          id: this.state.spot.id,
          name: values["name"],
          vehicle_type_id: values["vehicle_type_id"],
          spot_shape: areaHelper.formatValues(values["coordinates"])
        };
        values = newValue;
        spotActions
          .updateSpot(values, this.state.spot.id)
          .then(response => {
            if (response.status === 204) {
             this.fetchSpot(this.state.spot.id);
              this.setState({ editSpot: false });
              success("Spot has been sucessfully updated.");
            } else {
              getErrorMessages(response.data.errors);
            }
            this.setState({ spotEditButton: false });
          });
      }
    });
  };

  showSpotConfirm(spot) {
    confirm({
      title: "Do you want to delete this spot?",
      content: "Spot name => " + spot.name,
      onOk: () => this.deleteSpot(spot.id),
      onCancel() {}
    });
  }

  deleteSpot(spotId) {
    spotActions.deleteSpot(spotId).then(response => {
      if (response.status === 204) {
        this.setState({areaSpots: this.state.areaSpots.filter(el => el.id !== spotId)})
        this.setState({ editSpot: false });
        success("Spot has been sucessfully deleted.");
      } else {
        error(response.data.error || "Something went wrong. Please try again.");
      }
    });
  }

  updateBooleanAttribute = (checked, spotId, attribute) => {
    var formattedValues = {}
    formattedValues[attribute] = checked
    spotActions.updateSpot(formattedValues, spotId).then(response => {
      if (response.status === 204) {
        this.fetchSpot(spotId)
        success("Spot has been sucessfully updated.");
      } else {
        getErrorMessages(response.data.errors);
      }
    });
  };

  fetchSpot = (spotId) => {
    spotActions.showSpot(spotId).then(response=>{
      var areaSpots = this.state.areaSpots
      var repacableIndex = this.state.areaSpots.findIndex(ele => ele.id === spotId)
      areaSpots[repacableIndex] = response.data
      this.setState({areaSpots: areaSpots})
    });
  };

  onPaginationChange=(page)=>{
    let parsed = queryString.parse(this.props.location?.search);
    parsed['page'] = page; 
    this.props.history.push(`/areas?${queryString.stringify(parsed)}`)
  }

  render() {
    let parsed = queryString.parse(this.props.location?.search);
    const currentPage = parsed.page ? parseInt(parsed.page) : 1
    const columns = [
      {
        title: "Street Name",
        dataIndex: "street",
        key: "street"
      },
      {
        title: "Availability",
        key: "areaAvailability",
        render: record => {
          return (
            <span>
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={record.availability}
                loading={this.state.updatedAreaSwitch === record.id ? true : false}
                onChange={e => this.updateAreaAttributes(e, record.id, 'availability')}
              />
            </span>
          );
        }
      },
      {
        title: "Action",
        key: "action",
        render: record => {
          return (
            <span>
              <Tooltip title="View Area">
                <span onClick={() => this.setShowModalVisible(record)}>
                  <Icon type="eye" />
                </span>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Edit Area">
                <span onClick={() => this.setEditModalVisible(record)}>
                  <Icon type="edit" title="edit" />
                </span>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Delete Area">
                <span onClick={() => this.showConfirm(record)}>
                  <Icon type="delete" />
                </span>
              </Tooltip>
            </span>
          );
        }
      },
      {
        title: "Clean up parked vehicle?",
        key: "public",
        render: record => {
          return (
            <span>
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={record.public}
                onChange={e => this.updateAreaAttributes(e, record.id, 'public')}
              />
            </span>
          );
        }
      }
    ];
    const { areas, totalAreas } = this.props;

    return (
      <div>
        <Table
          columns={columns}
          dataSource={areas}
          rowKey={column => column.id}
          pagination={false}
          expandedRowRender={e => this.expandedRowRender(e)}
          onExpand={(expanded, record)=> this.fetchSpots(expanded, record.id)}
          expandedRowKeys={this.state.expandedRowKeys}
          bordered
        />

        <Pagination 
          style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          onChange={this.onPaginationChange}
          total={totalAreas}
          disabled= {false}
          current= {currentPage}
          defaultPageSize= {5}
        />

        {this.state.editVisible && (
          <Edit
            wrappedComponentRef={this.saveFormRef}
            editVisible={this.state.editVisible}
            onCancel={this.handleCancel}
            onEdit={this.handleEdit}
            area={this.state.area}
            areas={areas}
            areaEditButton={this.state.areaEditButton}
          />
        )}

        {this.state.showVisible && (
          <Show
            showVisible={this.state.showVisible}
            onCancel={this.handleCancel}
            area={this.state.area}
          />
        )}

        {this.state.editSpot && (
          <EditSpot
            wrappedComponentRef={this.saveFormRef}
            editVisible={this.state.editSpot}
            onCancel={this.handleCancel}
            onEdit={this.handleSpotEdit}
            spot={this.state.spot}
            area={{...this.state.spotArea, ...{spots: this.state.areaSpots}}}
            spotEditButton={this.state.spotEditButton}
            fetchAreas={this.props.fetchAreas}
          />
        )}
      </div>
    );
  }
}

export default withRouter(TableArea);
