import React, { Component, Fragment } from "react";
import { List, Descriptions, Tooltip, Tag, Empty } from "antd";
import { ReservationContext } from "../../contexts/ReservationContext";
import Media from "react-media";
import { areaActions } from "../../actions/AreaActions";

class AreaList extends Component {
  static contextType = ReservationContext;
  constructor() {
    super();
    this.state = {
      areas: [],
      loading: false
    }
  }

  componentDidMount() {
    this.setState({loading: true})
    areaActions.fetchAreasDetail().then(response => {
      this.setState({ areas: response.data, loading: false });
    });
  }

  calculateStatus = area => {
    let title = "";
    let style = "";
    let color = "";
    if (area.availability) {
      title = "Area is available now.";
      style = "available";
      color = "green";
    } else {
      title = "This Area is currently unavailable.";
      style = "unavailable";
      color = "red";
    }

    return {
      title,
      style,
      color,
    };
  };

  getSpotDetails(area, attribute) {
    let selectedArea = this.state.areas.find(ele => ele.id === area.id)
    if(!selectedArea) { return }

    return selectedArea[attribute]
  }

  renderLoading() {
    return <div style={{color: '#37C6FF'}}>Loading data...</div>
  }

  onAreaChange = (areaId, areaStreet) => {
    this.context.fitMap(areaId);
    this.context.resetAreaHighlight();
    this.context.onAreaChange(areaId, null, areaStreet);
  };

  render() {
    const { areas, highlightArea, resetAreaHighlight } = this.context;
    return (
      <List itemLayout="vertical" size="small">
        {areas.map(area => {
          var distance = area.distance ? " ( " + area.distance + "m far )" : "";
          let {
            title,
            style,
            color,
          } = this.calculateStatus(area);

          return (
            <Media
              queries={{
                small: "(max-width: 599px)",
                medium: "(min-width: 600px) and (max-width: 991px)",
                specialMedium: "(min-width: 992px) and (max-width: 1199px)",
                large: "(min-width: 1200px)"
              }}
            >
              {matches => (
                <Fragment>
                  <Tooltip key={area.id} title={title}>
                    <div className={style + " area-hover"}>
                      <List.Item
                        key={area.id}
                        onClick={
                          area.availability
                            ? () => this.onAreaChange(area.id, area.street)
                            : null
                        }
                        onMouseOver={() => highlightArea(area.id)}
                        onMouseLeave={() => resetAreaHighlight()}
                      >
                        <div style={{ paddingLeft: "1em" }}>
                          {matches.small ? (
                            <Descriptions
                              title={area.street + distance}
                              size="small"
                            >
                              <Descriptions.Item label="Area Status" span={3}>
                                <Tag color={color}>{style}</Tag>
                              </Descriptions.Item>
                            </Descriptions>
                          ) : (
                            <Descriptions
                              title={area.street + distance}
                              size="small"
                            >
                              <Descriptions.Item label="Area Status" span={3}>
                                <Tag color={color}>{style}</Tag>
                              </Descriptions.Item>
                              <Descriptions.Item label="No. of Spots" span={3}>
                                {this.state.loading ? this.renderLoading() : this.getSpotDetails(area, 'total_spots')}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label="No. of Available Spots"
                                span={3}
                              >
                                {this.state.loading ? this.renderLoading() : this.getSpotDetails(area, 'total_available_spot')}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label="No. of Unavailable"
                                span={3}
                              >
                                {this.state.loading ? this.renderLoading() : this.getSpotDetails(area, 'total_unavailable_spot')}
                              </Descriptions.Item>
                              <Descriptions.Item label="No. of Booked" span={3}>
                                {this.state.loading ? this.renderLoading() : this.getSpotDetails(area, 'total_booked_spot')}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label="No. of Parked Spots"
                                span={3}
                              >
                                {this.state.loading ? this.renderLoading() : this.getSpotDetails(area, 'total_parked_spot')}
                              </Descriptions.Item>
                            </Descriptions>
                          )}
                        </div>
                      </List.Item>
                    </div>
                    <br />
                  </Tooltip>
                </Fragment>
              )}
            </Media>
          );
        })}
        {areas?.length === 0 ? <Empty /> : null}
      </List>
    );
  }
}

export default AreaList;
