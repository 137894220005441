import React, {Component} from "react";
import Layout from "../Layout/Custom_Layout/Layout";
import { isAdmin, isEmployee, isAccountant } from "../../helpers/userPolicy";
import { Modal } from "antd";
import InvoiceTable from "./InvoiceTable";
import { userActions } from "../../actions/UserActions";
import { invoiceActions } from "../../actions/InvoiceActions";
import { withRouter } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import {
  endDate,
  startDate
} from "../../constants/InvoiceFilterConstants";
import { Icon} from "antd";

const { confirm } = Modal;

class IndexInvoice extends Component {

  constructor(props) {
    super(props);
    const dateFormat = "MM/DD/YYYY";
    this.state = {
      invoices: [],
      users: [],
      isInvoiceLoading: true,
      initialValue: {
        date_range: [
          moment(startDate, dateFormat),
          moment(endDate, dateFormat),
        ],
      },
      grandTotalPayment: 0,
      currentPage: 1,
      total_invoices: 1
    };
    this.handleFilterInvoices = this.handleFilterInvoices.bind(this)
  }

  componentDidMount() {
    if (Object.keys(this.props.location.search).length === 0) {
      this.props.history.replace(
        `/invoice?date_gteq=${startDate}&date_lteq=${endDate}&page=1`
      );
    }
    this.fetchUsers();
    this.fetchInvoiceUsingQuery();
    this.setFilterFormUsingQuery();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location?.search !== prevProps.location?.search) {
      this.fetchInvoiceUsingQuery();
      this.setFilterFormUsingQuery();
    }
  }

  fetchUsers = () => {
    if (isAdmin() || isEmployee() || isAccountant()) {
      userActions.fetchEmployeeAcToArea().then((response) => {
        this.setState({
          users: response.data,
        });
      });
    }
  };

  fetchInvoiceUsingQuery = () => {
    var filterValues = queryString.parse(this.props.location.search);
    this.setState({
      isInvoiceLoading: true,
      currentPage: parseInt(filterValues["page"]),
    });
    invoiceActions.fetchInvoice(filterValues).then((response) => {
      if (response.status === 200) {
        this.setState({
          invoices: response.data.invoices,
          total_invoices: response.data.meta.pagination.total_invoices,
          grandTotalPayment: response.data.meta.pagination.grand_total_payment,
          isInvoiceLoading: false
        });
      }
    });
  };

  setFilterFormUsingQuery = () => {
    const parsedJson = queryString.parse(this.props.location?.search);
    this.formRef.setFieldsValue({
      area_id: parsedJson.area_id,
      park_attendant_id: parsedJson.park_attendant_id,
      vehicle_type_id: parsedJson.vehicle_type_id,
      vehicle_no: parsedJson.vehicle_no,
      date_range:
        parsedJson["date_gteq"] && parsedJson["date_lteq"]
          ? [
              moment(parsedJson.date_gteq, "MM/DD/YYYY"),
              moment(parsedJson.date_lteq, "MM/DD/YYYY"),
            ]
          : [],
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  handleFilterInvoices = (e) => {
    e.preventDefault();
    this.formRef.validateFields((err, values) => {
      if (!err) {
        values = this.removeEmptyFromJson(values);
        const date_range = values["date_range"];
        if (date_range) {
          values["date_gteq"] = values["date_range"][0].format("M/D/YYYY");
          values["date_lteq"] = values["date_range"][1].format("M/D/YYYY");
          delete values["date_range"];
        }
        const query = queryString.stringify(values, { encode: false });
        this.props.history.push(
          `/invoice?${query.length > 0 ? query + "&" : ""}page=1`
        );
      }
    });
  };

  removeEmptyFromJson = (values) => {
    Object.keys(values).forEach((k) => {
      if (!values[k] || values[k].length === 0) {
        delete values[k];
      }
    });
    return values;
  };

  showDeleteConfirm = (invoiceId) => {
    confirm({
      title: "Do you want to delete the invoice?",
      onOk: () => this.removeInvoice(invoiceId),
      onCancel() {},
    });
  };

  removeInvoice = (invoiceId) => {
    invoiceActions.deleteInvoice(invoiceId).then((response) => {
      if (response.status === 204) {
        this.fetchInvoiceUsingQuery();
        this.setFilterFormUsingQuery();
      }
    });
  }

  render() {
    return (
      <Layout sidebarSelectedKey="invoice">
        <span style={{fontSize: "1.25rem"}}>Invoice &nbsp;
            <Icon type="sync" className = "reload-button"  onClick={() => this.fetchInvoiceUsingQuery()} spin = {this.state.isInvoiceLoading} title="Reload Invoice" />
            <br/>
          <br/>
        </span>
        <InvoiceTable
          invoices = {this.state.invoices}
          users = {this.state.users}
          isInvoiceLoading = {this.state.isInvoiceLoading}
          initialValue= {this.state.initialValue}
          grandTotalPayment = {this.state.grandTotalPayment}
          currentPage = {this.state.currentPage}
          total_invoices = {this.state.total_invoices}
          wrappedComponentRef={this.saveFormRef}
          handleFilterInvoices={this.handleFilterInvoices}
          showDeleteConfirm={this.showDeleteConfirm}
          fetchInvoiceUsingQuery={this.fetchInvoiceUsingQuery}
        />
      </Layout>
    );
  }
}
export default withRouter(IndexInvoice);
