import customHttp from "../helpers/CustomHttp";
import { authHeader } from "../helpers/AuthHeader";

export const dashboardActions = {
  fetchDashboardData
};

function fetchDashboardData(searchParameter) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    url: `/dashboards?date=${searchParameter}`,
  };
  return customHttp(requestOptions).then((response) => response);
}
