import React, { Component } from "react";
import { Form, Row, Col, DatePicker, Button, Collapse, Select, Input } from "antd";
import { isEmployee, isAdmin, isAccountant } from "../../helpers/userPolicy";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;

class FilterForm extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      users,
      areas,
      handleFilter,
      initialDateRange,
      handleClear,
      isInvoiceLoading,
      vehicleTypes,
    } = this.props;
    const dateFormat = "MM/DD/YYYY";
    return (
      <Collapse>
        <Panel header="Filter Invoice" key="1" style={{ width: "100%" }}>
          <div>
            <Form
              name="advanced_search"
              onSubmit={(values) => {
                handleFilter(values);
              }}
            >
              <Row gutter={10}>
                <Col xl={8} sm={12}>
                  <span>Vehicle Type:</span>
                  <Form.Item style={{marginBottom: 5}}>
                    {getFieldDecorator("vehicle_type_id")(
                      <Select
                        showSearch
                        placeholder="Select Vehicle Type"
                        optionFilterProp="children"
                        allowClear={true}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {vehicleTypes.map((vehicleType) => {
                          return (
                            <Option value={vehicleType.id} key={vehicleType.id}>
                              {vehicleType.vehicle_cat}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {isEmployee() || isAdmin() || isAccountant() ? (
                  <Col xl={8} sm={12}>
                    <span>Collected By:</span>
                    <Form.Item style={{marginBottom: 5}}>
                      {getFieldDecorator("park_attendant_id")(
                        <Select
                          showSearch
                          placeholder="Select attendant"
                          optionFilterProp="children"
                          allowClear={true}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {users.map((user) => {
                            return (
                              (user.role === "admin" ||
                                user.role === "employee") && (
                                <Option value={user.id} key={user.id}>
                                  {user.name}
                                </Option>
                              )
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                ) : null}
                <Col xl={8} sm={12}>
                  <span>Area:</span>
                  <Form.Item style={{marginBottom: 5}}>
                    {getFieldDecorator("area_id")(
                      <Select
                        showSearch
                        placeholder="Select Area"
                        optionFilterProp="children"
                        allowClear={true}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={isEmployee()}
                      >
                        {areas.map((area) => {
                          return (
                            <Option value={area.id} key={area.id}>
                              {area.street}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xl={8} sm={12}>
                  <span>Vehicle Number:</span>
                  <Form.Item name={"vehicle_no"} style={{ marginBottom: 5 }}>
                    {getFieldDecorator("vehicle_no")(
                      <Input placeholder="Enter Vehicle Number" />
                    )}
                  </Form.Item>
                </Col>
                <Col xl={8} sm={12}>
                  <span>Date:</span>
                  <Form.Item name={"date_range"} style={{marginBottom: 5}}>
                    {getFieldDecorator("date_range", {
                      initialValue: initialDateRange,
                    })(
                      <RangePicker
                        format={dateFormat}
                        disabledDate={(current) => {
                          let end = new Date();
                          if (current > end) {
                            return true;
                          }
                          return false;
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isInvoiceLoading}
                  >
                    Filter
                  </Button>
                  <Button
                    style={{ margin: "0 8px" }}
                    disabled={isInvoiceLoading}
                    onClick={() => {
                      handleClear();

                      this.props.form.resetFields();
                    }}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Panel>
      </Collapse>
    );
  }
}

export default FilterForm;
