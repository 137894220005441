import React, { Component, Fragment } from 'react';
import Media from "react-media";
import { Modal, Row, Col, Divider, Alert, Form, Input, Button } from 'antd';

class PasswordChangeEmployee extends Component {
  
  handlePasswordSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if(!err){
        this.props.onSubmit({ user: values})
      }
    })
  }

  passwordValidation = (rule, value, callback) => {
    if (value && value.length < 6) {
      callback("Password must be minimum length of 6 !");
    }
    callback();
  }
      
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  }

  render(){
    const { 
      employee,
      passwordVisible,
      onCancel,
      isSubmitButtonDisabled
    } = this.props
    const { getFieldDecorator } = this.props.form;
    return(
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(matches) => (
          <Fragment>
            <Modal 
              centered
              visible={passwordVisible}
              onCancel={onCancel}
              footer={null}
              width={matches.medium ? "40em" : "60em"}
            >
            <Row>
              <Col
                offset={2}
                span={20}
                style={{ background: "rgb(217, 217, 217)", height: 35 }}
              >
              <b>
                <Alert message={`Edit Password of ${employee.name}`}  type="default" />
              </b>
              </Col>
            </Row>
            <Divider />
            <Form layout={"vertical"} onSubmit={this.handlePasswordSubmit}>
              <Form.Item label="Password" hasFeedback>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      validator: this.passwordValidation,
                    },
                  ],
                })(
                  <Input.Password name="password" placeholder="Enter password" />
                )}
              </Form.Item>
              <Form.Item label="Confirm Password" hasFeedback>
                {getFieldDecorator("password_confirmation", {
                  rules: [
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(<Input.Password placeholder="Confirm Password" />)}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-right"
                  disabled={isSubmitButtonDisabled}
                >
                Update
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </Fragment>
        )}
      </Media>
    );
  }
}

export default Form.create({})(PasswordChangeEmployee);