import React, {Component} from "react";
import { isAdmin } from "../../helpers/userPolicy";
import {notificationActions} from "../../actions/NotificationActions";
import { Collapse, Button, Tooltip,Icon,Divider, Empty} from 'antd';
import CreateNotification from "./NotificationForm";
import UpdateNotification from "./NotificationForm";
import { Link } from "react-router-dom";
import { getErrorMessages } from "../../helpers/getErrors";
import {success} from "../../helpers/Notification";
import Media from "react-media";
import Spinner from "../loadSpinner/loadSpinner";

const {Panel} = Collapse;

class NotificationView extends Component{
  constructor(){
    super();
    this.state = { notifications: [], createModal: false, modalVisible: false, updateModal: false, notification: {}, hidden: true, isLoading: true }
  }

  componentDidMount() {
    if (isAdmin()){
      this.fetchNotification();
    }else {
      this.props.history.push("/unauthorized");
    }
  }

  componentDidUpdate(prevprops){
    if(this.props?.areaId !== prevprops?.areaId){
      this.fetchNotification();
    }
  }

  fetchNotification = () =>{
    this.setState({isLoading: true})
    notificationActions.showNotification(this.props.areaId).then(response => {
      this.setState({ notifications: response.data, isLoading: false})
    })
  }
  showCreateModal=()=>{
    this.setState({
      createModal: true,
      modalVisible: true,
      updateModal: false
    })
  }
  showUpdateModal=(notify)=>{
    this.setState({
      notification: notify,
      createModal:false,
      modalVisible: true,
      updateModal: true
    })
    this.checkMessageType(notify.message_type)
  }
  
  handleCancel = () => {
    const { form } = this.formRef.props;
    this.setState({ modalVisible: false });
    form.resetFields();
  };

  onCreate = e =>{
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values)=>{
      if (!err){
        notificationActions.createNotification(values).then(response =>{
          if (response.status === 201){
            success("Notification Created Sucessfully!");
            this.fetchNotification();
            form.resetFields();
            this.setState({modalVisible: false})
          }else{
            getErrorMessages(response.data.errors)
          }
        });
      }
    });
  }

  onUpdate = e => {
    e.preventDefault();
    const {form} = this.formRef.props;
    form.validateFields((err, values)=>{
      if(!err){
        notificationActions.updateNotification(this.state.notification.id, values).then(response =>{
          if (response.status === 204) {
            success("Notification Updated");
            this.fetchNotification();
            form.resetFields();
            this.setState({modalVisible: false})
          }else{
            getErrorMessages(response.data.errors)
          }
        });
      }
    });
  }

  changeMessageType=(event)=>{
   this.checkMessageType(event)
  }

  checkMessageType=(message_type)=>{
    if (message_type === "offers"){
      this.setState({hidden: false})
    }else{
      this.setState({hidden: true})
    }
  }

  saveFormRef = formRef => {
  this.formRef = formRef;
  };

  notificationFeatures = (notify) => (
    <span>
      <Tooltip title="Update Notification">
        <Link onClick={() => this.showUpdateModal(notify)}>
          <Icon type="edit" title="edit" />
        </Link>
      </Tooltip>
      <Divider type = "vertical"/>
      <Divider type = "vertical"/>
      <Tooltip title="Broadcast Notification">
        <Link onClick={() => this.broadcastnotification(notify)}>
          <Icon type="notification" title="broadcast" />
        </Link>
      </Tooltip>
    </span>
  )

  broadcastnotification=(notify)=>{
    notificationActions.pushNotification(notify.id).then(response =>{
      if(response.status===200){
        success("Broadcasted Notification")
      }
    })
  }

  render(){
    const {notifications, isLoading} = this.state
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)"
        }}
      >
      {(matches) => (
        <div style={{width: matches.large?"70%":"100%",float:"left", paddingLeft: 5}}>
          {!matches.large && <Divider>Notifications</Divider> }
          { isLoading ? <Spinner /> :
            <>
              {notifications.length > 0 ?
                <Collapse>
                  {notifications.map(notify  => {
                    return(
                      <Panel key={notify.id} header = {notify.publish_message} extra={this.notificationFeatures(notify)} style={{width: "100%"}}>
                        <div><b> BroadCast Date : </b>{notify.publish_date} </div>
                        <div><b> Message Type : </b>{notify.message_type} </div>
                        <div>
                          { notify.expired_date &&
                            <div>
                              <b> Expire Date : </b> {notify.expired_date}<br/>
                              <b> Expire Message : </b> {notify.expired_message}<br/>
                            </div>  
                          }
                        </div>
                      </Panel> 
                    )
                  })}
                </Collapse> : <Empty />
              }
            </>
          }
          <Button type="primary" className= "notify-btn"  onClick={() => this.showCreateModal()}>
            Create
          </Button>
        {
          this.state.createModal && (<CreateNotification
            wrappedComponentRef={this.saveFormRef}
            visible = {this.state.modalVisible}
            areaId = {this.props.areaId}
            onCancel = {this.handleCancel}
            onSubmit = {this.onCreate}
            title = "Create"
            changeMessageType = {this.changeMessageType}
            hidden = {this.state.hidden}
          />)}
          {
            this.state.updateModal && (<UpdateNotification
            wrappedComponentRef = {this.saveFormRef}
            visible = {this.state.modalVisible}
            areaId = {this.props.areaId}
            onCancel= {this.handleCancel}
            onSubmit = {this.onUpdate}
            title = "Update"
            notification = {this.state.notification}
            hidden = {this.state.hidden}
            changeMessageType=  {this.changeMessageType}
          />
          )}
        </div>
      )}
      </Media>
    )
  }
}
export default NotificationView;