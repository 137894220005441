import React, {Component} from "react";
import { Menu } from 'antd';
import SlipDetailForm from './SlipDetailForm';
import {areaSlipDetailActions} from "../../actions/SlipDetailActions";
import {getErrorMessages} from "../../helpers/getErrors";
import {success} from "../../helpers/Notification";
import {validateFormData} from "../../helpers/RemoveAttributeFromHash";

class DisplayArea extends Component{
  constructor(){
    super()
    this.state ={ areaId: null, areaSlipDetail: {}, areaClicked: false, isLoading: true, disableButton: true}
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange=()=>{
    const { form } = this.formRef.props;
    const slipDetail = validateFormData(this.state.areaSlipDetail, "id");
    const createData = validateFormData(form.getFieldsValue(), "area_id");
    if (Object.values(createData).every((data)=> data === ''))
    {
      this.setState({disableButton: true})
    } else if (JSON.stringify(form.getFieldsValue()) !== JSON.stringify(slipDetail)) {
      this.setState({disableButton: false})
    } else {
      this.setState({disableButton: true})
    }
  }

  onAreaChange= (area) =>{
    this.setState({
      areaId: area.id,
      areaName: area.street,
      areaClicked: true,
      isLoading: true
    })
    areaSlipDetailActions.getAreaSlipDetail(area.id).then(response=>{
      this.setState({
        areaSlipDetail: response.data,
        isLoading: false,
        disableButton: true
      })
    })
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  handleCreate = e => {
    const { form } = this.formRef.props;
    e.preventDefault();
    form.validateFields((err, values)=>{
      if (!err){
        areaSlipDetailActions.createAreaSlipDetail(values).then(response =>{
          if (response.status === 201){
            success("Created Sucessfully!");
            this.setState({areaSlipDetail: response.data})
            this.setState({disableButton: true})
            form.resetFields();
          }else{
            getErrorMessages(response.data.errors)
          }
        });
      }
    });
  }

  handleUpdate = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values)=>{
      console.log(this.state.areaSlipDetail)
      if (!err){
        areaSlipDetailActions.updateAreaSlipDetail(this.state.areaSlipDetail?.id, values).then(response =>{
          if (response.status === 200){
            success("Updated Sucessfully!");
            this.setState({areaSlipDetail: response.data})
            form.resetFields();
            this.setState({disableButton: true})
          }else{
            getErrorMessages(response.data.errors)
          }
        });
      }
    });
  }

  render(){
    const {areaList} = this.props
    return (
      <>
        <div style={{width: '20%', float: 'left'}}>
          <Menu>
            {areaList.map(area =>  (
              <Menu.Item
                key={area.id}
                onClick={()=> this.onAreaChange(area)}
              >
                {area.street}
              </Menu.Item>
            )
            )}
          </Menu>
        </div>
        {
          this.state.areaClicked ? (<SlipDetailForm
            areaId={this.state.areaId}
            areaName={this.state.areaName}
            wrappedComponentRef={this.saveFormRef}
            areaSlipDetail = {this.state.areaSlipDetail}
            buttonTitle = {this.state.areaSlipDetail ? "Update" : "Create"}
            handleSubmit = {this.state.areaSlipDetail ? this.handleUpdate : this.handleCreate}
            isLoading = {this.state.isLoading}
            handleChange = {this.handleChange}
            disableButton = {this.state.disableButton}
          />) :
           <h4><center>Please click the area to get the information!</center></h4>
        }
      </>
    )
  }
}
export default (DisplayArea);