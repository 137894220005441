import React, { Component, Fragment } from "react";
import { Row, Col, Form, Select, Icon, Tooltip } from "antd";
import { ReservationContext } from "../../contexts/ReservationContext";

const { Option } = Select;

class FilterForm extends Component {
  static contextType = ReservationContext;

  constructor() {
    super();
    this.onLocationChange = this.onLocationChange.bind(this);
  }

  componentDidMount() {
    this.context.setFilterForm(this.props.form);
  }

  onLocationChange(locationId, e) {
    this.context.popup.setPosition(undefined);
    this.context.setLocationId(locationId, e.props.children);
    this.props.form.resetFields("area_id");
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      areas,
      areaId,
      vehicleTypes,
      onAreaChange,
      onTypeChange,
      vehicleTypeId
    } = this.context;
    
    return (
      <Fragment>
        <Form layout={"vertical"}>
          <Row>
            <Col offset={1} span={22}>
              <span>
                Area&nbsp;
                <Tooltip title="Choose any Area">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
              <Form.Item>
                {getFieldDecorator("area_id", {
                  rules: [
                    {
                      required: true,
                      message: "Please select any one Area!",
                      showSearch: true
                    }
                  ],
                  initialValue: areaId || undefined
                })(
                  <Select
                    showSearch
                    placeholder="Select any one Area"
                    optionFilterProp="children"
                    onChange={onAreaChange}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {areas.map(area => {
                      return (
                        <Option value={area.id} key={area.id}>
                          {area.street}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            </Row>
            <Row>
            <Col offset={1} span={22}>
              <span>
                Vehicle Type&nbsp;
                <Tooltip title="Choose any one Vehicle Type">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
              <Form.Item>
                {getFieldDecorator("vehicle_type_id", {
                  rules: [
                    {
                      required: true,
                      message: "Please select any one Vehicle Type!",
                      showSearch: true
                    }
                  ],
                  initialValue: vehicleTypeId
                })(
                  <Select
                    showSearch
                    placeholder="Select any one Vehicle Type"
                    optionFilterProp="children"
                    onChange={onTypeChange}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="all">All</Option>
                    {vehicleTypes.map(type => {
                      return (
                        <Option value={type.id} key={type.id}>
                          {type.vehicle_cat}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Fragment>
    );
  }
}

export default Form.create({})(FilterForm);
