import React, { Component } from 'react'
import { userActions } from '../../actions/UserActions';
import { Icon } from "antd";

export default class Logout extends Component {

   onHandelClick=()=> {
    userActions.logout();
   }
    render() {
        return (
          <div className="ant-dropdown-link"  onClick={this.onHandelClick}>
            <Icon type="logout" />
            Logout
          </div>
        )
      }
}