import React, { Component, Fragment } from "react";
import { Form, Select, Input, Button, Icon, Tooltip, Radio } from "antd";
const { Option } = Select;

class FormEmployee extends Component {
  constructor(props) {
    super(props);
    this.assignedAreas = props.employee?.assigned_areas || [];
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateFirstPassword = (rule, value, callback) => {
    if (value && value.length < 6) {
      callback("Password must be minimum length of 6 !");
    }
    callback();
  };

  changePreviouslyAssignedArea = (value, areaId) => {
    this.assignedAreas.some((assignedArea, index) => {
      if (assignedArea.area_id === areaId) {
        this.assignedAreas[index]["_destroy"] = value;
        return true;
      }
    });
  };

  onAreaSelect = (areaId) => {
    if (
      this.props.employee?.assigned_areas.find(
        (assignedArea) => assignedArea.area_id === areaId
      )
    ) {
      this.changePreviouslyAssignedArea(0, areaId);
    } else {
      this.assignedAreas.push({ area_id: areaId });
    }
  };

  onAreaDeselect = (areaId) => {
    if (
      this.props.employee?.assigned_areas.find(
        (assignedArea) => assignedArea.area_id === areaId
      )
    ) {
      this.changePreviouslyAssignedArea(1, areaId);
    } else {
      this.assignedAreas = this.assignedAreas.filter(
        (assignedArea) => assignedArea.area_id !== areaId
      );
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        delete values["area_ids"];
        values["assigned_areas_attributes"] = this.assignedAreas;
        this.props.onSubmit({ user: values });
      }
    });
  };

  render() {
    const { areas, isSubmitButtonDisabled, employee } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Fragment>
        <Form layout={"vertical"} onSubmit={this.handleSubmit}>
          <Form.Item
            label={
              <span>
                Area&nbsp;
                <Tooltip title="Choose one area to add employee">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("area_ids", {
              rules: [
                {
                  required: true,
                  message: "Please select one area!",
                },
              ],
              initialValue: employee?.area_ids,
            })(
              <Select
                showSearch
                mode="multiple"
                placeholder="Select any one Area"
                optionFilterProp="children"
                onSelect={this.onAreaSelect}
                onDeselect={this.onAreaDeselect}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {areas.map((area) => {
                  return (
                    <Option value={area.id} key={area.id}>
                      {area.street}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="E-mail">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ],
              initialValue: employee?.email,
            })(<Input name="email" placeholder="Enter employee email" />)}
          </Form.Item>

          <Form.Item label= "Role">
           {getFieldDecorator("role",{
            rules: [
              {
                required: true,
                message: "Please select your role!"
              },
            ],
            initialValue: employee?.role,
           })(
              <Radio.Group name="radiogroup">
                <Radio value="employee">Employee</Radio>
                <Radio value="accountant">Accountant</Radio>
              </Radio.Group>
            )}
          </Form.Item>

          <Form.Item
            label={
              <span>
                Name&nbsp;
                <Tooltip title="Name of the employee">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input your name!",
                  whitespace: true,
                },
              ],
              initialValue: employee?.name,
            })(<Input name="name" placeholder="Enter employee name" />)}
          </Form.Item>
          {!employee && (
            <Form.Item label="Password" hasFeedback>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    validator: this.validateFirstPassword,
                  },
                ],
              })(
                <Input.Password name="password" placeholder="Enter password" />
              )}
            </Form.Item>
          )}
          {!employee && (
            <Form.Item label="Confirm Password" hasFeedback>
              {getFieldDecorator("password_confirmation", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(<Input.Password placeholder="Confirm Password" />)}
            </Form.Item>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-right"
              disabled={isSubmitButtonDisabled}
            >
              {employee ? "Update" : "Register"}
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    );
  }
}

export default Form.create({})(FormEmployee);
