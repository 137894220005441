import React, { Component } from 'react';
import Media from "react-media";
import { Modal, Form, Input, Button, Icon, Tooltip, Row, Col, Alert, Divider, Select } from 'antd';
import { areaActions } from '../../actions/AreaActions';
const { Option } = Select

class CreateBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: [],
      spots: [],
      isLoading: true
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    areaActions.fetchAreas().then(response => {
      this.setState({ areas: response.data, isLoading: false });
    });
  }

  handleChange = (areaId) => {
    areaActions.fetchAreaSpots(areaId).then(response => {
      this.setState({ spots: response.data, isLoading: false });
    })
  }

  render() {
    const { visible, onCancel, onCreate, createButton } = this.props;
    const { getFieldDecorator } = this.props.form;

    return ( 
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)"
        }}
      >
        {matches => (      
        <Modal
        centered
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width = {matches.medium ? "54em" : "75em" }
        >
          <div> 
            <Row>
              <Col
                offset={2}
                span={20}
                style={{
                  background: "rgb(217, 217, 217)",
                  height: "2.5em"
                }}
              >
                <b>
                  <Alert message="Add New Board" type="default" />
                </b>
              </Col>
            </Row>
            <Divider></Divider>
            <Form layout={"vertical"} onSubmit={onCreate} >
              <Row>
                <Col offset={2} span={18}>
                  <Form.Item
                    label={
                      <span>
                        Board Number &nbsp;
                        <Tooltip title="Board Number">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("boardNumber",{
                      rules:[
                        {
                          required: true,
                          message: "Please enter Board Number!",
                          whitespace: true
                        }
                      ],
                    })(
                      <Input
                        placeholder={"Enter Board Number"}
                        name="boardNumber"
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Board Name &nbsp; 
                        <Tooltip title="Name of the Board">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("boardName", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Board Name!",
                          whitespace: true
                        }
                      ]
                    })(
                      <Input
                        placeholder={"Enter your Board Name"}
                        name="boardName"
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Phone Number &nbsp; 
                        <Tooltip title="Phone Number">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("phoneNumber")(
                      <Input
                        placeholder={"Enter your Phone Number"}
                        name="phoneNumber"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col offset={2} span={18}>
                  <Form.Item
                          label={
                            <span>
                              Area&nbsp;
                              <Tooltip title="Choose Area To Select Spot">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            </span>
                          }
                        >
                        { getFieldDecorator("areaId", {
                          rules: [
                            {
                              required: true,
                              message: "Please select one Area!"
                            }
                          ],
                          })(
                            <Select
                              showSearch
                              placeholder="Please select an Area"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onSelect={e => this.handleChange(e)}
                            >
                                {this.state.areas.map(area => { 
                                  return (
                                      <Option value={area.id} key={area.id}>
                                        {area.street}
                                      </Option>
                                    );
                                  }  
                                )}
                            </Select>
                        )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col offset={2} span={18}>
                    <Form.Item
                      label={
                        <span>
                          Spots&nbsp;
                          <Tooltip title="Choose Spots for the Board">
                            <Icon type="question-circle-o" />
                          </Tooltip>
                        </span>
                      }
                    >
                    { getFieldDecorator("spotsIds", {
                      rules: [
                        {
                          required: true,
                          message: "Please select spots!"
                        }
                      ],
                      })(
                        <Select
                          showSearch
                          mode="multiple"
                          placeholder="Please select Spots!"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          // onSelect={e => this.handleChangeSpot(e)}
                        >
                            {this.state.spots.map(spot => { 
                              return (
                                  <Option value={spot.id} key={spot.id}>
                                    {spot.spot_name} 
                                  </Option>
                                );
                              }  
                            )}
                        </Select>
                      )}
                  </Form.Item>
                </Col>
              </Row>
                <Row>
                <Col>
                  <Divider></Divider>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-right"
                        disabled={createButton}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
        )}
      </Media>
     );
  }
}
 
export default Form.create({})(CreateBoard);