import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell
} from "@david.kucsai/react-pdf-table";
import getDate from "../../helpers/getDate";
import getTime from "../../helpers/getTime";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    color: "red"
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    objectFit: "cover"
  },
  invoice: {
    fontSize: 40,
    fontWeight: "demibold"
  },
  small_text: {
    fontSize: 10,
    fontWeight: "semibold"
  },
  header_text: {
    fontSize: 10,
    fontWeight: "ultralight",
    textAlign: "center"
  },
  break_line: {
    fontSize: 8
  },
  table_header: {
    fontSize: 13,
    fontWeight: "ultrabold",
    textAlign: "center",
    fontFamily: "Times-Roman"
  },
  table_content: {
    fontSize: 10,
    fontWeight: "ultralight",
    textAlign: "center"
  },
  float_right: {
    fontSize: 12,
    fontWeight: "hairline",
    textAlign: "right",
    fontFamily: "Times-Roman"
  },
  invoice_table_header: {
    fontSize: 15,
    fontWeight: "ultrabold",
    textAlign: "center",
    fontFamily: "Times-Roman"
  },
  invoice_table_content: {
    fontSize: 11,
    fontWeight: "hairline",
    textAlign: "center"
  },
  term_header: {
    fontSize: 15,
    fontWeight: "ultrabold",
    fontFamily: "Times-Roman"
  },
  term_title: {
    fontSize: 11,
    fontWeight: "hairline",
    fontFamily: "Times-Roman"
  },
  term_content: {
    marginLeft: 10,
    fontSize: 11,
    fontWeight: "hairline",
    fontFamily: "Times-Roman"
  }
});

// Create Document Component
const MyDocument = props => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Codyssey Parking App</Text>
        <Text style={styles.invoice}>Invoice</Text>
        <Text style={styles.small_text}>No. : #{props.invoice.invoice_id}</Text>
        <Text style={styles.small_text}>
          Invoice to : {props.invoice.user_name}
        </Text>
        <Text style={styles.small_text}>
          Date : {getDate(props.invoice.leave_timestamp)}
        </Text>
        <Text style={styles.break_line}>
          ______________________________________________________________________________________________________________________________
        </Text>
        <Text style={styles.break_line}> </Text>
        <Table
          data={[
            {
              areaName: props.invoice.area_name,
              spotName: props.invoice.spot_name
            }
          ]}
        >
          <TableHeader>
            <TableCell>
              <Text style={styles.table_header}>Area Name</Text>
            </TableCell>
            <TableCell>
              <Text style={styles.table_header}>Spot Name</Text>
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              getContent={r => (
                <Text style={styles.table_content}>{r.areaName}</Text>
              )}
            />
            <DataTableCell
              getContent={r => (
                <Text style={styles.table_content}>{r.spotName}</Text>
              )}
            />
          </TableBody>
        </Table>
        <Text style={styles.break_line}> </Text>
        <Table
          data={[
            {
              vehicleCat: props.invoice.vehicle_cat,
              rate: props.invoice.rate,
              vehicleNo: props.invoice.vehicle_no
            }
          ]}
        >
          <TableHeader>
            <TableCell>
              <Text style={styles.table_header}>Vehicle Type</Text>
            </TableCell>
            <TableCell>
              <Text style={styles.table_header}>Rate</Text>
            </TableCell>
            <TableCell>
              <Text style={styles.table_header}>Vehicle No.</Text>
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              getContent={r => (
                <Text style={styles.table_content}>{r.vehicleCat}</Text>
              )}
            />
            <DataTableCell
              getContent={r => (
                <Text style={styles.table_content}>Rs.{r.rate}/-</Text>
              )}
            />
            <DataTableCell
              getContent={r => (
                <Text style={styles.table_content}>{r.vehicleNo}</Text>
              )}
            />
          </TableBody>
        </Table>
        <Text style={styles.break_line}> </Text>
        <Table
          data={[
            {
              bookTime: getTime(props.invoice.book_timestamp),
              parkTime: getTime(props.invoice.entry_timestamp),
              LeaveTime: getTime(props.invoice.leave_timestamp)
            }
          ]}
        >
          <TableHeader>
            <TableCell>
              <Text style={styles.table_header}>Book Time</Text>
            </TableCell>
            <TableCell>
              <Text style={styles.table_header}>Park Time</Text>
            </TableCell>
            <TableCell>
              <Text style={styles.table_header}>Leave Time</Text>
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              getContent={r => (
                <Text style={styles.table_content}>{r.bookTime}</Text>
              )}
            />
            <DataTableCell
              getContent={r => (
                <Text style={styles.table_content}>{r.parkTime}</Text>
              )}
            />
            <DataTableCell
              getContent={r => (
                <Text style={styles.table_content}>{r.LeaveTime}</Text>
              )}
            />
          </TableBody>
        </Table>
        <Text style={styles.break_line}>
          ______________________________________________________________________________________________________________________________
        </Text>
        <Text style={styles.break_line}> </Text>

        <Table data={props.data}>
          <TableHeader>
            {props.columns.map(header => {
              return (
                <TableCell key={header.title}>
                  <Text style={styles.invoice_table_header}>
                    {header.title}
                  </Text>
                </TableCell>
              );
            })}
          </TableHeader>
          <TableBody>
            {props.columns.map(row => {
              return (
                <DataTableCell
                  getContent={r => (
                    <Text style={styles.invoice_table_content}>
                      {String(r[row.dataIndex])}
                    </Text>
                  )}
                />
              );
            })}
          </TableBody>
        </Table>

        <Text style={styles.break_line}>
          ______________________________________________________________________________________________________________________________
        </Text>
        <Text style={styles.break_line}> </Text>

        <Text style={styles.float_right}>
          <Text> Tax :</Text> <Text>Rs.{props.invoice.tax.toFixed(2)}/-</Text>
        </Text>
        <Text style={styles.float_right}>
          <Text> Discount :</Text>{" "}
          <Text>Rs.{props.invoice.discount.toFixed(2)}/-</Text>
        </Text>
        <Text style={styles.float_right}>
          <Text> Grand Total :</Text>{" "}
          <Text>Rs.{props.invoice.grand_total.toFixed(2)}/-</Text>
        </Text>

        <Text style={styles.break_line}>
          ______________________________________________________________________________________________________________________________
        </Text>
        <Text style={styles.break_line}> </Text>
        <Text style={styles.term_header}>Terms and Condition</Text>
        <Text style={styles.term_title}>
          As a condition of use, you promise not to use Services for any propose
          that is unlawful or probihited by these Terms.
        </Text>
        <Text style={styles.term_content}> • Term 1</Text>
        <Text style={styles.term_content}> • Term 2</Text>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
