import React, { Component } from "react";
import {rateActions} from "../../actions/RateActions";
import {success} from "../../helpers/Notification";
import CreateModalForm from "./EditRate";
import UpdateModalForm from "./EditRate";
import {getErrorMessages} from "../../helpers/getErrors"
import {
Tooltip,
Icon,
Divider,
Button,
Modal,
Table
} from "antd";
import Media from "react-media";

const { confirm } = Modal;

class CreateRate extends Component{
  constructor(){
    super();
    this.state = {modalVisible: false, countForm: [], vehicleTypeRate: {}, createModal: false, updateModal: false, areaRates: []};
  }

  componentDidMount(){
    this.fetchAreaRate();
  }

  componentDidUpdate(prevProps){
    if(this.props!== prevProps){
      this.fetchAreaRate();
    }
  }

  handleVariableRates = () => {
    const countArray = this.state.countForm
    countArray.push(this.state.countForm.length)
    this.setState({countForm: countArray})
  }

  removeRateForm = (index) => {
    const countArray = this.state.countForm
    countArray.splice(index, 1)
    this.setState({countForm: countArray})
  }

  fetchAreaRate= () => {
    rateActions.showRate(this.props.areaId).then(response=>{
      this.setState({
        areaRates: response.data
      })
    })
  }

  showCreateModal =()=> {
    this.setState({
      modalVisible: true,
      createModal: true,
      updateModal: false,
      countForm: []
    })
  };

  showUpdateModal =(areaRate)=>{
    this.setState({
      vehicleTypeRate: areaRate,
      modalVisible: true,
      updateModal: true,
      createModal: false,
      countForm: Array.from(Array(areaRate.variable_rates.length).keys())
    });
  }

  showConfirm(rateId) {
    confirm({
      title: "Do you want to delete this rate?",
      onOk: () => this.deleteRate(rateId),
      onCancel() {
        console.log("Cancel");
      }
    });
  }

  deleteRate= (areaRate) => {
    rateActions.deleteRate(areaRate.id).then(response => {
      if (response.status === 204) {
        success("Rate has been sucessfully deleted.");
        this.fetchRate(areaRate.area_id)
      }else{
        getErrorMessages(response.data.errors)
      }
    });
  }

  fetchRate = (areaId) => {
    rateActions.showRate(areaId).then(response => {
      if (response.status === 200 ){
        this.setState({areaRates: response.data, modalVisible: false})
      }
    });
  }

  handleCancel = () => {
    const { form } = this.formRef.props;
    this.setState({ modalVisible: false });
    form.resetFields();
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  onCreate = e =>{
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values)=>{
      if (!err){
        rateActions.createRate(values).then(response =>{
          if (response.status === 201){
            success("Created Sucessfully!");
            this.fetchRate(values.area_id)
            form.resetFields();
          }else{
            getErrorMessages(response.data.errors)
          }
        });
      }
    });
  }

  onUpdate = e =>{
    e.preventDefault();
    const { form } = this.formRef.props;
    const that = this
    form.validateFields((err, values)=>{
      if (!err){
        if (!values.variable_rates){
          values={...values, ...{variable_rates:[]}}
        }
        rateActions
          .updateRate(that.state.vehicleTypeRate.id, values)
          .then(response => {
            if (response.status === 204){
              success("Updated Sucessfully!");
              this.fetchRate(values.area_id)
              form.resetFields();
            }else{
              getErrorMessages(response.data.errors)
            }
        });
      }
    });
  }

  expandedRowRender = e => {
    const columns = [
      {
        title: "Charging Period Minutes",
        dataIndex: "charging_period_minutes",
        key: "charging_period_minutes"
      },
      {
        title: "Charging Period Rate",
        dataIndex: "charging_period_rate",
        key: "charging_period_rate"
      },
      {
        title: "Fixed Surcharge",
        dataIndex: "fixed_surcharge",
        key: "fixed_surcharge"
      },
      {
        title: "Grace Period",
        dataIndex: "grace_period",
        key: "grace_period"
      },
      {
        title: "Limit In Seconds",
        dataIndex: "limit_in_seconds",
        key: "limit_in_seconds"
      },
    ];
    return <Table
      columns={columns}
      dataSource={e.variable_rates}
      pagination={{ defaultPageSize: 5}}
      rowKey={column => column.id}
    />
  }
  render(){
    const {areaRates} = this.state
    const columns = [
      {
        title: "Vehicle Type",
        dataIndex: 'vehicle_type'
      },
      {
        title: "Charging Period Rate",
        dataIndex: "hourly_rate"
      },
      {
        title: "Charging period",
        dataIndex: "charging_period"
      },
      {
        title: "Grace Period",
        dataIndex: "grace_period"
      },
      {
        title: "Fixed Surcharge",
        dataIndex: "fixed_surcharge"
      },
      {
        title: "Action",
        width: '100px',
        key: "action",
        render: record => {
          return (
            <span>
              <Tooltip title="Edit Rate">
                <span
                  onClick={() =>
                    this.showUpdateModal(record)
                  }
                >
                  <Icon type="edit" title="edit" />
                </span>
              </Tooltip>

              <Divider type="vertical" />
              <Tooltip title="Delete Rate">
                <span onClick={() => this.showConfirm(record)}>
                  <Icon type="delete" />
                </span>
              </Tooltip>
            </span>
          );
        }
      }
    ];

    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)"
        }}
      >
      {(matches) => (
        <>
          <div style={{width: matches.large ? "70%":"100%",float:"left", paddingLeft: 5}}>
            <Button type="primary" className="btn-right"  onClick={() => this.showCreateModal()}>
              Create
            </Button>
            <br/><br/>
            <Table
              columns={columns}
              dataSource={areaRates}
              expandedRowRender={e => this.expandedRowRender(e)}
              rowKey={column => column.id}
              bordered
            />
            {this.state.createModal && ( <CreateModalForm
              wrappedComponentRef={this.saveFormRef}
              visible = {this.state.modalVisible}
              onSubmit = {this.onCreate}
              onCancel = {this.handleCancel}
              title = "Create"
              areaId = {this.props.areaId}
              countForm={this.state.countForm}
              handleVariableRates={this.handleVariableRates}
              removeRateForm={this.removeRateForm}
            />)}
            {this.state.updateModal && ( <UpdateModalForm
              wrappedComponentRef={this.saveFormRef}
              vehicleTypeRate = {this.state.vehicleTypeRate}
              visible = {this.state.modalVisible}
              onSubmit = {this.onUpdate}
              onCancel = {this.handleCancel}
              title = "Update"
              areaId = {this.props.areaId}
              vechicleTypeDisabled = {true}
              countForm={this.state.countForm}
              handleVariableRates={this.handleVariableRates}
              removeRateForm={this.removeRateForm}
            />)}
          </div>
        </>
      )}
      </Media>
    );
  }
}
export default (CreateRate)