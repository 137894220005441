import React, { Component } from "react";
import { Table, Tag } from "antd";
import Layout from "../Layout/Custom_Layout/Layout";
import { areaActions } from "../../actions/AreaActions";
import { isAccountant } from "../../helpers/userPolicy";

class AdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      areas: [],
      reservations: [],
      isLoading :true
    };
  }
  componentDidMount() {
    if(!isAccountant()){
      areaActions.fetchAreasDetail().then(response => {
        this.setState({ areas: response.data, isLoading: false });
      });
    }else{
      this.props.history.push("unauthorized")
    }
  }

  tableColumns = () => {
    return [
      { title: "Area Name", dataIndex: "street", key: "street" },
      {
        title: "Total number of Spots",
        key: "name",
        render: record => {
          return <span>{record.total_spots}</span>;
        }
      },
      {
        title: "Booked Spots",
        key: "upgradeNum",
        render: record => {
          return <span>{record.total_booked_spot}</span>;
        }
      },
      {
        title: "Parked Spots",
        key: "operation",
        render: record => {
          return <span>{record.total_parked_spot}</span>;
        }
      },
      {
        title: "Available Spots",
        key: "operation",
        render: record => {
          return <span>{record.total_available_spot}</span>;
        }
      },
      {
        title: "Unavailable Spots",
        key: "operation",
        render: record => {
          return <span>{record.total_unavailable_spot}</span>;
        }
      },
      {
        title: "Status",
        key: "operation",
        render: record => {
          let color = "";
          let text = "";
          if (record.availability) {
            color = "green";
            text = "Available";
          } else {
            color = "red";
            text = "Unavailable";
          }
          return <Tag color={color}>{text}</Tag>;
        }
      }
    ];
  }

  render() {
    const { isLoading, areas } = this.state;
    return (
      <Layout sidebarSelectedKey="spots_information">
        <Table 
          columns={this.tableColumns()}
          dataSource={areas}
          rowKey={column => column.id}
          loading={isLoading}
        />
      </Layout>
    );
  }  
}

export default AdminView;
