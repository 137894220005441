import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Row, Col, Layout, Drawer, Tag } from "antd";
import CustomLayout from "../Layout/Custom_Layout/Layout";
import { ReservationContext } from "../../contexts/ReservationContext";
import AreaList from "./AreaList";
import SpotList from "./SpotList";
import SpotDetail from "./SpotDetail";
import FilterForm from "./FilterForm";
import { ReservationMap } from "../Map/ReservationMap";
import { reservationActions } from "../../actions/ReservationActions";
import { isAdmin, isEmployee, isAccountant } from "../../helpers/userPolicy";
import Media from "react-media";
import ReservationBrowser from "./ReservationBrowser";
import LoadingSpinner from "../loadSpinner/loadSpinner";

const { Sider, Content } = Layout;

class ReservationView extends Component {
  static contextType = ReservationContext;

  constructor() {
    super();
    if (!isAdmin() && !isEmployee()) {
      reservationActions.usersReservationCount().then(response => {
        if (response.status === 200 && (response.data[0].total_reservation > 0 || response.data[0].total_parked_vehicle > 0)) {
          this.props.history.push("/reservations/user");
        }
      });
    }
  }

  componentDidMount() {
    if(isAccountant()) {
      this.props.history.replace("/unauthorized")
    }
  }

  render() {
    const {
      allReady,
      visible,
      onClose,
      isListItemLoaded,
      map
    } = this.context;
    return (
      <CustomLayout sidebarSelectedKey="reservation">
        <Media
          queries={{
            small: "(max-width: 599px)",
            medium: "(min-width: 600px) and (max-width: 991px)",
            specialMedium: "(min-width: 992px) and (max-width: 1199px)",
            large: "(min-width: 1200px)"
          }}
        >
          {matches => (
            <Fragment>
              <Layout theme="light" style={{ background: "#fff" }}>
                {matches.small ? (
                  <Fragment>
                    <Row>
                      <Col>
                        <div className="mobile-container">
                          {isListItemLoaded && map ? (
                            allReady ? (
                              <SpotList />
                            ) : (
                              <AreaList />
                            )
                          ) : (
                            <LoadingSpinner />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReservationBrowser />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mobile-reservation-map">
                          <ReservationMap />
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Sider
                      theme="light"
                      width={
                        matches.large
                          ? "28em"
                          : matches.specialMedium
                          ? "20em"
                          : "100%"
                      }
                      breakpoint="lg"
                      collapsedWidth={0}
                      collapsible
                      style={{ marginTop: "1rem" }}
                      onCollapse={() => {
                        this.context.updateMapSize();
                      }}
                    >
                      <Row>
                        <Col>
                          <FilterForm />
                        </Col>
                        <Col>
                          <div className="area-spot-selection-container">
                            {isListItemLoaded && map ? (
                              allReady ? (
                                <SpotList />
                              ) : (
                                <AreaList />
                              )
                            ) : (
                              <LoadingSpinner />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Sider>
                    <Layout>
                      <Row>
                        <Col span={11}>
                          <ReservationBrowser />
                        </Col>
                        <Col style={{ float: "right" }}>
                          <Tag color="green">Available</Tag>
                          <Tag color="blue">Booked</Tag>
                          <Tag color="orange">Parked</Tag>
                          <Tag color="red">Unavailable</Tag>
                        </Col>
                      </Row>
                      <Content className="reservation-map-large-container">
                        <ReservationMap />
                      </Content>
                    </Layout>
                  </Fragment>
                )}
              </Layout>
              <Drawer
                title="Spot Information"
                width={matches.small ? "100%" : 720}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
              >
                <SpotDetail />
              </Drawer>
            </Fragment>
          )}
        </Media>
      </CustomLayout>
    );
  }
}

export default withRouter(ReservationView);
