import React, {Component} from "react";
import {Menu} from 'antd';
import RateForm from './RateForm'
import Spinner from "../loadSpinner/loadSpinner";
import Media from "react-media";


class AreaList extends Component {
  constructor(){
    super()
    this.state ={ areaId: null, areaName: null }
  }

  onAreaChange= (area) =>{
   this.setState({
    areaId: area.id,
    areaName: area.street
   })
  }

  render(){
    const {areaList, showSpinner} = this.props
    if (showSpinner){
      return (<Spinner />)
    }else{
      return (
        <Media
          queries={{
            small: "(max-width: 599px)",
            medium: "(min-width: 600px) and (max-width: 1199px)",
            large: "(min-width: 1200px)"
          }}
        >
          {(matches) => (
            <>
            <div style={{width:matches.large? "30%" : "100%", float: "left",height: matches.large? "65vh":"40vh", overflow: "scroll", paddingRight: 5}}>
                {areaList.length>0 &&
                  <Menu mode="inline" defaultSelectedKeys={[areaList[0]&&areaList[0].id]}>
                    {areaList.map(area =>  (
                      <Menu.Item key={area.id}
                        onClick={()=> this.onAreaChange(area)}
                      >
                        {area.street}
                      </Menu.Item>
                    )
                    )}
                  </Menu>
                }
                </div>
                {areaList.length>0 && 
                  <RateForm
                    areaId= {this.state.areaId || areaList[0].id}
                    areaName = {this.state.areaName || areaList[0].street}
                />}
            </>
          )}
        </Media>
      )
    } 
  }
}
export default AreaList;