import React, { Component, Fragment } from "react";
import { Descriptions, Button, Collapse } from "antd";
import { ReservationContext } from "../../contexts/ReservationContext";
import FormReservation from "./FormReservation";
import { isAdmin, isEmployee } from "../../helpers/userPolicy";

const { Panel } = Collapse;

export default class SpotDetail extends Component {
  static contextType = ReservationContext;

  render() {
    const { spot } = this.context;
    return (
      <Fragment>
        <Descriptions bordered size="middle">
          <Descriptions.Item label="Spot Name">{spot.spot_name}</Descriptions.Item>
          <Descriptions.Item label="Vehicle Type">
            {spot.spot_vehicle_type}
          </Descriptions.Item>
          <Descriptions.Item label="Rate">
            Rs. {spot.spot_rate}/-
          </Descriptions.Item>
          <Descriptions.Item label="Extra Info" span={3}>
            none
          </Descriptions.Item>
        </Descriptions>

        <br />
        <br />
        <Collapse accordion>
          <Panel
            style={{
              width: "100%",
              float: "left",
              margin: "5px",
              padding: "2px"
            }}
            header={
              <Button type="primary" block ghost>
                {isAdmin() || isEmployee()? "Reserve / Park this Spot":"Reserve this Spot"}
              </Button>
            }
            showArrow={false}
          >
            <FormReservation
              rate={spot.spot_rate}
            />
            
          </Panel>
        </Collapse>
      </Fragment>
    );
  }
}
