import customHttp from "../helpers/CustomHttp";
import { authHeader } from "../helpers/AuthHeader";

export const rateActions = {
  updateRate,
  showRate,
  createRate,
  deleteRate
};

function updateRate(id, values) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    url : `/rates/${id}`,
    data: values
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function showRate(areaId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    url : `/rates?area_id=${areaId}`
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function createRate(values){
  const requestOptions={
    method: 'POST',
    headers: authHeader(),
    url: '/rates',
    data: values
  }
  return customHttp(requestOptions).then(response =>{
    return response
  })
}
function deleteRate(rateId){
  const requestOptions={
    method: 'DELETE',
    headers: authHeader(),
    url: `/rates/${rateId}`
  }
  return customHttp(requestOptions).then(response =>{
    return response
  })
}