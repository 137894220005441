import React, { Component, Fragment } from "react";
import { Modal, Row, Col, Alert, Divider } from "antd";
import FormEmployee from "./FormEmployee";
import Media from "react-media";

export class CreateEmployee extends Component {
  render() {
    const {
      createVisible,
      onCancel,
      onCreate,
      isSubmitButtonDisabled,
      areas,
    } = this.props;
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(matches) => (
          <Fragment>
            <Modal
              title=""
              centered
              visible={createVisible}
              onCancel={() => onCancel()}
              footer={null}
              width={matches.medium ? "40em" : "60em"}
            >
              <Row>
                <Col
                  offset={2}
                  span={20}
                  style={{ background: "rgb(217, 217, 217)", height: 35 }}
                >
                  <b>
                    <Alert message="Register Employee" type="default" />
                  </b>
                </Col>
              </Row>
              <Divider />
              <FormEmployee
                areas={areas}
                onSubmit={onCreate}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
              />
            </Modal>
          </Fragment>
        )}
      </Media>
    );
  }
}

export default CreateEmployee;
