import React, {Component} from 'react';
import {Menu, Icon} from 'antd';
import Cookie from 'js.cookie';
import {Link} from 'react-router-dom';
import Logout from '../../Logout/Logout';
import {isAdmin, isEmployee, isAccountant} from '../../../helpers/userPolicy';
import Logo from './Logo';
import {endDate, startDate} from '../../../constants/InvoiceFilterConstants';
import {
  endTime,
  startTime,
} from '../../../constants/InvoiceFilterConstants';
const {SubMenu} = Menu;

class Sidebar extends Component {
  render () {
    return (
      <div>
          <div className= {`logo ${this.props.mobileView ? "logo-mobile-view" : ''}`} >
            {this.props.smallScreen ? <Icon type="arrow-left" style={{fontSize: '3em', top: '12px', right: '19px', position: 'relative'}} onClick={()=>  this.props.toggle() } /> : null }
              { !this.props.smallScreen ?
                <Link to="/" style={{textDecoration: 'none'}}>
                  <Logo />
              </Link>
              :
              <h1>SPOT N PARK</h1>
            }
          </div>
        <Menu className= {this.props.mobileView ? "menu-mobile-view" : ''} mode="inline" defaultSelectedKeys={[this.props.selectedKey]}>
          {isAdmin ()
            ? <Menu.Item key="home">
                <Link to="/" style={{textDecoration: 'none'}}>
                  <Icon type="home" />
                  <span>Home</span>
                </Link>
              </Menu.Item>
            : null}
            {
              !isAccountant()
              ?
              <Menu.Item key="spots_information">
                <Link to="/spot_information" style={{textDecoration: 'none'}}>
                  <Icon type="dashboard" />
                  <span>Spots information</span>
                </Link>
              </Menu.Item>
              :
              null
            }
            {
              !isAccountant()
              ?
              <Menu.Item key="reservation">
                <Link
                  to={ isAdmin () ? '/reservations' : '/reservations/user'}
                  style={{textDecoration: 'none'}}
                >
                  <Icon type="ordered-list" />
                  <span>Reservations</span>
                </Link>
              </Menu.Item>
              :
              null
            }
          {isAdmin () || isEmployee () || isAccountant ()
            ? <Menu.Item key="parkedVehicle">
                <Link
                  to={{
                    pathname: `/parked-vehicles`,
                    search: `?start_date=${startTime}&end_date=${endTime}&page=1`,
                  }}
                  style={{textDecoration: 'none'}}
                >
                  <Icon type="car" theme="filled" />
                  <span>Parked Vehicles</span>
                </Link>
              </Menu.Item>
            : null}
          {isAdmin ()
            ? <Menu.Item key="vehicle">
                <Link to="/vehicle-types" style={{textDecoration: 'none'}}>
                  <Icon type="car" />
                  <span>Vehicle Type</span>
                </Link>
              </Menu.Item>
            : null}
          {isAdmin ()
            ? <Menu.Item key="area">
                <Link to={{pathname: `/areas`, search: `page=1`}} style={{textDecoration: 'none'}}>
                  <Icon type="gateway" />
                  <span>Area</span>
                </Link>
              </Menu.Item>
            : null}
          {isAdmin ()
            ? <Menu.Item key="board">
                <Link to="/boards" style={{textDecoration: 'none'}}>
                  <Icon type="barcode" />
                  <span>Board</span>
                </Link>
              </Menu.Item>
            : null}
          <Menu.Item key="invoice">
            <Link
              to={{
                pathname: `/invoice`,
                search: `?date_gteq=${startDate}&date_lteq=${endDate}&page=1`,
              }}
              style={{textDecoration: 'none'}}
            >
              <Icon type="bars" />
              <span>Invoice</span>
            </Link>
          </Menu.Item>
          {isAdmin ()
            ? <Menu.Item key="rates">
                <Link to="/rates" style={{textDecoration: 'none'}}>
                  <Icon type="dollar" />
                  <span>Area Rates</span>
                </Link>
              </Menu.Item>
            : null}

          {isAdmin ()
            ? <Menu.Item key="slip_record">
                <Link to="/area-slip-details" style={{textDecoration: 'none'}}>
                  <Icon type="gateway" />
                  <span>Area Slip Detail</span>
                </Link>
              </Menu.Item>
            : null}

          {isAdmin ()
            ? <Menu.Item key="notifications">
                <Link to="/notifications" style={{textDecoration: 'none'}}>
                  <Icon type="notification" />
                  <span>Notification</span>
                </Link>
              </Menu.Item>
            : null}

          {isAdmin ()
            ? <Menu.Item key="employees">
                <Link to= {{ pathname: "/employees", search: "employees" }} style={{textDecoration: 'none'}}>
                  <Icon type="team" />
                  <span>Employee</span>
                </Link>
              </Menu.Item>
            : null}

          {isAdmin ()
            ? <Menu.Item key="accountants">
                <Link to={{ pathname: "/employees", search: "accountants" }}  style={{textDecoration: 'none'}}>
                  <Icon type="solution" />
                  <span>Account Users</span>
                </Link>
              </Menu.Item>
            : null}

          <SubMenu
            key="user"
            title={
              <span>
                <Icon type="user" />
                <span>{Cookie.get ('uid')}</span>
              </span>
            }
          >
            <Menu.ItemGroup title={Cookie.get ('name')}>
              <Menu.Item key="logout">
                <Logout />
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
        </Menu>
      </div>
    );
  }
}

export default Sidebar;
