import React from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Icon,
  Tooltip,
  Row,
  Col,
  Divider,
  Alert,
  Upload
} from "antd";
import { error } from "../../helpers/Notification";

class EditVehicle extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      leftImageUrl: props.vehicleType.left_icon_url,
      rightImageUrl: props.vehicleType.right_icon_url
    }
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  normFile = (e, iconSide) => {
    if (Array.isArray(e)) {
      return e;
    }
    const fileCount = e.fileList.length;
    if (fileCount > 0) {
      this.getBase64(e.fileList[fileCount - 1].originFileObj, (imageUrl) => {
        this.setState({
          [iconSide]: imageUrl,
        });
      });
      return e && e.fileList[fileCount - 1].originFileObj;
    }
  };

  render() {
    const {
      editVisible,
      onCancel,
      onEdit,
      editButton,
      vehicleType
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Left Icon</div>
      </div>
    );
    const {leftImageUrl, rightImageUrl} = this.state
    return (
      <Modal
        title=""
        centered
        visible={editVisible}
        onCancel={onCancel}
        footer={null}
      >
        <div>
          <Row>
            <Col
              offset={2}
              span={20}
              style={{ background: "rgb(217, 217, 217)", height: 35 }}
            >
              <b>
                <Alert message="Edit Vehicle Type" type="default" />
              </b>
            </Col>
          </Row>
          <Divider />
          <Form layout={"vertical"} onSubmit={onEdit}>
            <Row>
              <Col offset={3} span={18}>
                <Form.Item
                  label={
                    <span>
                      Vehicle Type&nbsp;
                      <Tooltip title="Type of Vehicle">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("vehicle_cat", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the Vehicle Type!",
                        whitespace: true
                      }
                    ],
                    initialValue: vehicleType.vehicle_cat
                  })(
                    <Input
                      placeholder={"Please enter the Vehicle Type"}
                      name="vehicle_cat"
                      disabled
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Rate (per charging period)&nbsp;
                      <Tooltip title="Per charging period rate for Parking">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("hourly_rate", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the charging period rate for parking!"
                      }
                    ],
                    initialValue: vehicleType.hourly_rate
                  })(
                    <Input
                      placeholder={"Enter the hourly rate for parking"}
                      name="rate"
                      addonBefore={"NPR"}
                      type="number"
                      min='0'
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Sort Order&nbsp;
                      <Tooltip title="Sort Order">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("sort_order", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the sort order!"
                      }
                    ],
                    initialValue: vehicleType.sort_order
                  })(
                    <Input
                      placeholder={"Please enter the sort order"}
                      name="sort_order"
                      type='number'
                      min='0'
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col offset={3} span={7}>
                <Form.Item label={<span>Left Icon</span>}>
                  {getFieldDecorator("left_icon", {
                    valuePropsName: "fileList",
                    getValueFromEvent: (e) => this.normFile(e, "leftImageUrl"),
                  })(
                    <Upload
                      name="logo"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      // beforeUpload={this.beforeUpload}
                      customRequest={() => {}}
                      accept="image/*"
                    >
                      {leftImageUrl ? (
                        <img
                          src={leftImageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  )}
                </Form.Item>
              </Col>
              <Col offset={3} span={7}>
                <Form.Item label={<span>Right Icon</span>}>
                  {getFieldDecorator("right_icon", {
                    valuePropsName: "fileList",
                    getValueFromEvent: (e) => this.normFile(e, "rightImageUrl"),
                  })(
                    <Upload
                      name="logo"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      // beforeUpload={this.beforeUpload}
                      customRequest={() => {}}
                      accept="image/*"
                    >
                      {rightImageUrl ? (
                        <img
                          src={rightImageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Divider></Divider>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-right"
                    disabled={editButton}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default Form.create({})(EditVehicle);
