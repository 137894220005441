import React, { Component } from "react";
import { ReservationContext } from "../../contexts/ReservationContext";
import { Breadcrumb } from "antd";

export class ReservationBrowser extends Component {
  static contextType = ReservationContext;

  render() {
    const { areaStreet, resetArea } = this.context;
    return (
      <Breadcrumb>
        {areaStreet ? (
          <Breadcrumb.Item>
            <span
              onClick={resetArea}
              className="ant-menu-item-selected clickable-reservation-browser"
            >
              Area
            </span>
            <span>{`(${areaStreet})`}</span>
          </Breadcrumb.Item>
        ) : null}
      </Breadcrumb>
    );
  }
}

export default ReservationBrowser;
