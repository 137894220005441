export const validateFormData=(values, valueToBeRemoved)=> {
  const dataWithoutId = Object.keys(values || {}).reduce((accumulator, key)=> {
    if(key !== valueToBeRemoved){
      accumulator[key] = values[key]
    }
    return accumulator
  }, {})

  return dataWithoutId
}
