import React, { Component } from "react";
import { Button, Row, Col } from "antd";
import Layout from "../Layout/Custom_Layout/Layout";
import LoadingSpinner from "../loadSpinner/loadSpinner";
import { isAdmin } from "../../helpers/userPolicy";
import CreateBoard from "../Board/CreateBoard";
import TableBoard from "../Board/TableBoard";
import { success, error } from "../../helpers/Notification";
import { getErrorMessages } from "../../helpers/getErrors";
import { boardActions } from "../../actions/BoardActions";

class IndexBoard extends Component {
  constructor() {
    super();
    this.state = {
      boards: [],
      isLoading: true,
      createBoardVisible: false,
      createButton: false,
    };
  }

  componentDidMount() {
    if(isAdmin()){
      boardActions.fetchBoards().then((response) => {
        if (response.status === 200) {
          this.setState({
            boards: response.data,
            isLoading: false,
          });
        } else {
          error("Unable To Fetch Boards Data. Please Try Again! ");
        }
      });
    }else{
      this.props.history.push("unauthorized")
    }
  }

  showTable() {
    const { boards, isLoading } = this.state;
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <TableBoard
          boards={boards}
          updateBoards={this.updateBoards}
          onDelete={this.deleteBoard}
        />
      );
    }
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  updateBoards = (board) => {
    const boards = this.state.boards.filter(boardItem => boardItem.id !== board.id)
    this.setState({ 
      boards: [...boards, board ]
    })
  };

  setCreateModalVisible() {
    this.setState({ createBoardVisible: true });
  }

  handleBoardCreate = (e) => {
    e.preventDefault();
    const { form } = this.formRef.props;
    const { boards } = this.state;
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ createButton: true });
        boardActions.createBoard(values).then((response) => {
          if (response.status === 201) {
            this.setState({
              isLoading: false,
              createBoardVisible: false,
              boards: [response.data, ...boards],
            });
            success("Board has been sucessfully created.");
          } else {
            getErrorMessages(response.data.errors);
          }
          this.setState({ createButton: false });
        });
      }
    });
  };

  handleCreateBoardCancel = () => {
    this.setState({ createBoardVisible: false });
  };

  deleteBoard = (board) => {
    const { boards } = this.state;
    boardActions.deleteBoard(board.id).then((response) => {
      if (response.status === 204) {
        this.setState({
          boards: boards.filter((boardItem) => boardItem.id !== board.id),
        });
        success("Board has been successfully deleted.");
      } else {
        error(response.data.error || "Something went wrong. Please try again!");
      }
    });
  };

  render() {
    return (
      <Layout sidebarSelectedKey="board">
        {isAdmin() ? (
          <div>
            <Row style={{ padding: "1em 0 1em 0" }}>
              <Col offset={3} span={15}>
                <h5> Board Information </h5>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  onClick={() => this.setCreateModalVisible()}
                  className="btn-right"
                >
                  Create
                </Button>
              </Col>
            </Row>

            <Row>
              <Col offset={3} span={18}>
                {this.showTable()}
              </Col>
            </Row>
            {this.state.createBoardVisible && (
              <CreateBoard
                wrappedComponentRef={this.saveFormRef}
                visible={this.state.createBoardVisible}
                onCancel={this.handleCreateBoardCancel}
                onCreate={this.handleBoardCreate}
                createButton={this.state.createButton}
              />
            )}
          </div>
        ) : null}
      </Layout>
    );
  }
}

export default IndexBoard;
