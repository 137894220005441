export function checkStatus (spot) {
  let title = "";
  let symbol = "";
  let style = "";
  let color = "";

  if (spot.availability || spot.spot_availability) {
    if (spot.reservation) {
      title = "Spot was booked already.";
      symbol = "Booked";
      style = "unavailable";
      color = "blue";
    } else if (spot.parked_vehicle){
      title = "Vehicle was already parked here.";
      symbol = "Parked";
      style = "unavailable";
      color = "yellow";
    }else {
    title = "Spot is available now.";
    symbol = "Available";
    style = "available";
    color = "green";
    }
  }
  else {
    title = "This Spot is currently unavailable.";
    symbol = "Unavailable";
    style = "unavailable";
    color = "red";
  }
  return { title, symbol, style, color };
}