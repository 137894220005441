import customHttp from "../helpers/CustomHttp";
import { authHeader } from "../helpers/AuthHeader";

export const boardActions = {
  fetchBoards,
  createBoard,
  showBoard,
  updateBoard,
  deleteBoard,
  deleteBoardSpot,
};

function fetchBoards() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    url: `/boards`,
  };
  return customHttp(requestOptions).then((response) => response);
}

function createSpotAttributes(values) {
  const attributes = values.spotsIds.map((spot) => {
    return {
      spot_id: spot,
    };
  });
  const params = {
    board: {
      name: values.boardName,
      board_number: values.boardNumber,
      phone_number: values.phoneNumber,
      board_spots_attributes: attributes,
    },
  };
  return params;
}

function createBoard(values) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    url: `/boards`,
    data: createSpotAttributes(values),
  };
  return customHttp(requestOptions).then((response) => response);
}

function showBoard(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    url: `/boards/${id}`,
  };
  return customHttp(requestOptions).then((response) => response);
}

function updateBoard(values, id) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    url: `/boards/${id}`,
    data: values,
  };
  return customHttp(requestOptions).then((response) => response);
}

function deleteBoard(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    url: `/boards/${id}`,
  };
  return customHttp(requestOptions).then((response) => response);
}

function deleteBoardSpot(values) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    url: `/board_spots/remove_spots`,
    data: values,
  };
  return customHttp(requestOptions).then((response) => response);
}
