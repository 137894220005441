export const areaHelper={
  formatValues,
  prepareSpotShape
}

function formatValues(values) {
  var coordinates = values.split(",");
  var pairCount = 0;
  var tempPair = [];
  var tempCoordinates = [];
  coordinates.forEach(coordinate => {
    tempPair.push(coordinate);
    pairCount += 1;
    if (pairCount === 2) {
      tempCoordinates.push(tempPair);
      pairCount = 0;
      tempPair = [];
    }
  });
  return tempCoordinates;
};

function prepareSpotShape(values){
  values.spots_attributes = values.spots_attributes.filter(spot_attributes => spot_attributes);
  values.spots_attributes.forEach((spot_attributes, index) => {
    values.spots_attributes[index]["spot_shape"] = this.formatValues(
      spot_attributes["spotCoordinates"]
    );
  })

  values.spots_attributes.forEach((spot_attributes,index) => {
    delete spot_attributes["spotCoordinates"]
  })
  return values
}