import React, { Component, Fragment } from "react";
import Media from "react-media";
import { spotActions } from "../../actions/SpotActions";
import { success, error } from "../../helpers/Notification";
import {
  Modal,
  Form,
  Input,
  Button,
  Icon,
  Tooltip,
  Row,
  Col,
  Divider,
  Alert,
  Badge,
  Select,
} from "antd";
import { AreaMap } from "../Map/AreaMap";
import { ReservationContext } from "../../contexts/ReservationContext";
import Spinner from "../loadSpinner/loadSpinner";

const { Option } = Select;
const { confirm } = Modal;

class EditArea extends Component {
  static contextType = ReservationContext;

  constructor(props) {
    super(props);
    this.state = {
      area: props.area,
      areas: props.areas,
      spotCoordinates: [],
      spots: [],
      spotCount: [],
      spotsForm: [],
      showSpinner: false
    };
    this.child = React.createRef();
  }

  addSpot = (spotCoordinates) => {
    const spotsForm = this.state.spotsForm;
    let spotCount = this.state.spotCount;
    const newCount =
      spotsForm.length > 0 ? spotsForm[spotsForm.length - 1] + 1 : 0;
    spotCount.push(newCount);
    spotsForm.push(newCount);
    this.setState({
      spotCount,
      spotsForm,
      spotCoordinates: [
        ...this.state.spotCoordinates,
        spotCoordinates.toString(),
      ],
    });
  };

  deleteSpot = (k) => {
    if (this.state.spots[k]?.id) {
      confirm({
        title: "Are you sure you want to delete this spot?",
        onOk: () => {
          spotActions.deleteSpot(this.state.spots[k].id).then((response) => {
            if (response.status === 204) {
              const spotCount = this.state.spotCount.filter(
                (count) => count !== k
              );
              this.setState({
                spotCount,
              });
              this.child.removeSpotDraw(`spot-${k}`);
              this.props.fetchAreas(this.props.location.search)
              success("Spot has been sucessfully updated.");
            } else {
              error(
                response.data.error || "Something went wrong. Please try again."
              );
            }
          });
        },
        onCancel() {},
      });
    } else {
      const spotCount = this.state.spotCount.filter(
        (count) => count !== k
      );
      this.setState({
        spotCount,
      });
      this.child.removeSpotDraw(`spot-${k}`);
    }
  };

  deleteDrawnSpots = () => {
    const spotCount = this.state.spotCount.filter(
      (count) => count < this.state.spots.length
    );
    this.setState({
      spotCount,
    });
  };

  render() {
    const { editVisible, onCancel, onEdit, areaEditButton} = this.props;
    const { getFieldDecorator } = this.props.form;
    const { vehicleTypes } = this.context

    const { spots } = this.state;
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(matches) => (
          <Fragment>
            <Modal
              title=""
              centered
              visible={editVisible}
              onCancel={onCancel}
              footer={null}
              width={matches.medium ? "54em" : "75em"}
            >
              <div>
                <br />
                <Row>
                  <Col
                    offset={2}
                    span={20}
                    style={{
                      background: "rgb(217, 217, 217)",
                      height: "2.5em",
                    }}
                  >
                    <b>
                      <Alert message="Edit Area" type="default" />
                    </b>
                  </Col>
                </Row>
                <Divider></Divider>
                <Form layout={"vertical"} onSubmit={onEdit}>
                  <AreaMap
                    form={this.props.form}
                    id="editAreaMap"
                    area={{...this.props.area, ...{spots: this.state.spots}}}
                    deleteDrawnSpots={this.deleteDrawnSpots}
                    fromEdit={true}
                    addSpot={this.addSpot}
                    ref={(instance) => {
                      this.child = instance;
                    }}
                    setMapRendered={this.setMapRendered}
                    areas={this.state.areas}
                  />
                  <Row>
                    <Col offset={3} span={18}>
                      <Form.Item
                        label={
                          <span>
                            Area Name&nbsp;
                            <Tooltip title="Name of the Area according to Location">
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                        }
                      >
                        {getFieldDecorator("street", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter your Area Name!",
                              whitespace: true,
                            },
                          ],
                          initialValue: this.props.area.street,
                        })(
                          <Input
                            placeholder={"Enter your Area Name"}
                            name="street"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={3} span={18}>
                      <Form.Item
                        label={
                          <span>
                            Clean up?&nbsp;
                            <Tooltip title="Do you want to delete parked vehicles which is not departed?">
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                        }
                      >
                        {getFieldDecorator("cleanup_period", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter cleanup period!"
                            },
                          ],
                          initialValue: this.props.area.cleanup_period,
                        })(
                          <Input
                            placeholder={"Enter cleanup period!"}
                            name="cleanup_period"
                            type='number'
                            min="0"
                            addonAfter= {"Days"}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={3} span={18}>
                      <div>Total number of spots created: {this.props.area.total_spots}</div>
                      <div style={{color: '#37C6FF'}}>Note: You can edit each spots by expanding the area table. If you wish to create a new spot just draw a spot inside the area map you will get spot form below.</div>
                     </Col>
                  </Row>
                  { this.state.showSpinner ? <Spinner/> :
                    <>
                      <Row>
                        <Col offset={3} span={18}>
                          <Row>
                            {this.state.spotCount
                              ? this.state.spotCount.map((k) => {
                                  return (
                                    <Col
                                      offset={1}
                                      span={matches.small ? 22 : 5}
                                      key={k}
                                    >
                                      <div
                                        style={{
                                          border: "1px solid grey",
                                          padding: "0.3em",
                                          marginTop: "0.5em",
                                        }}
                                      >
                                        <Row>
                                          <Col span={1}>
                                            <p>
                                              <Badge
                                                count={k + 1}
                                                style={{
                                                  backgroundColor: "#fff",
                                                  color: "#7d7d7d",
                                                  boxShadow:
                                                    "0 0 0 1px #7d7d7d inset",
                                                  margin: "0 auto",
                                                }}
                                              />
                                            </p>
                                          </Col>
                                          <Col span="0">
                                            <Form.Item>
                                              {getFieldDecorator(
                                                `spots_attributes[${k}]id`,
                                                {
                                                  initialValue: spots[k]?.id
                                                    ? spots[k].id
                                                    : "",
                                                }
                                              )(<Input type="hidden" name="id" />)}
                                            </Form.Item>
                                            <Form.Item>
                                              {getFieldDecorator(
                                                `spots_attributes[${k}]spotCoordinates`,
                                                {
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        "Please draw spot on map",
                                                    },
                                                  ],
                                                  initialValue: this.state
                                                    .spotCoordinates[k],
                                                }
                                              )(
                                                <Input
                                                  type="hidden"
                                                  name="spotCoordinates"
                                                />
                                              )}
                                            </Form.Item>
                                          </Col>
                                          <Col>
                                            <Button
                                              type="danger"
                                              size="small"
                                              ghost
                                              onClick={() => this.deleteSpot(k)}
                                              style={{ float: "right" }}
                                            >
                                              <Icon type="minus" />
                                            </Button>
                                          </Col>
                                        </Row>

                                        <Form.Item>
                                          {getFieldDecorator(
                                            `spots_attributes[${k}]name`,
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  message:
                                                    "Please enter spot name!",
                                                },
                                              ],
                                              initialValue: spots[k]?.name
                                                ? spots[k].name
                                                : "",
                                            }
                                          )(
                                            <Input
                                              placeholder="Spot name"
                                              name="name"
                                            />
                                          )}
                                        </Form.Item>
                                        <Form.Item>
                                          {getFieldDecorator(
                                            `spots_attributes[${k}]vehicle_type_id`,
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  message:
                                                    "Please select vehicle type!",
                                                  showSearch: true,
                                                },
                                              ],
                                              initialValue: spots[k]
                                                ?.spot_vehicle_type_id
                                                ? spots[k].spot_vehicle_type_id
                                                : undefined,
                                            }
                                          )(
                                            <Select
                                              showSearch
                                              placeholder="Select vehicle type"
                                              optionFilterProp="children"
                                              filterOption={(input, option) =>
                                                option.props.children
                                                  .toLowerCase()
                                                  .indexOf(input.toLowerCase()) >= 0
                                              }
                                            >
                                              {vehicleTypes.map(
                                                (vehicle_type) => {
                                                  return (
                                                    <Option
                                                      value={vehicle_type.id}
                                                      key={vehicle_type.id}
                                                    >
                                                      {vehicle_type.vehicle_cat}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          )}
                                        </Form.Item>
                                      </div>
                                    </Col>
                                  );
                                })
                              : ""}
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Divider></Divider>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="btn-right"
                              disabled={areaEditButton}
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  }
                </Form>
              </div>
            </Modal>
          </Fragment>
        )}
      </Media>
    );
  }
}

export default Form.create({})(EditArea);
