import React, { Component, Fragment } from "react";
import { Button, Row, Col, Result ,Input} from "antd";
import TableEmployee from "./TableEmployee";
import Layout from "../Layout/Custom_Layout/Layout";
import CreateEmployee from "./CreateEmployee";
import { success, error } from "../../helpers/Notification";
import { userActions } from "../../actions/UserActions";
import { isAdmin } from "../../helpers/userPolicy";
import EditEmployee from "./EditEmployee";
import ShowEmployee from "./ShowEmployee";
import PasswordChangeEmployee from "./PasswordChangeEmployee";
import { ReservationContext } from "../../contexts/ReservationContext";


export default class IndexEmployee extends Component {
  static contextType = ReservationContext;

  constructor() {
    super();
    this.state = {
      createVisible: false,
      editVisible: false,
      showVisible: false,
      passwordVisible: false,
      isLoading: true,
      employees: [],
      isSubmitButtonDisabled: false,
      employee: {},
      filteredEmployees: []
    };
  }

  componentDidUpdate(prevProps){
    if(this.props.location?.search !== prevProps.location?.search){
      this.fetchEmployees();
    }
  }

  componentDidMount() {
    if(isAdmin()){
      this.fetchEmployees();
    }else{
      this.props.history.push("unauthorized")
    }
  }

  fetchEmployees = () => {
    userActions.fetchEmployees(this.props.location.search.replace('?','')).then((response) => {
      this.setState({
        employees: response.data,
        filteredEmployees: response.data,
        isLoading: false,
        isSubmitButtonDisabled: false,
        editVisible: false,
        createVisible: false
      });
    });
  };

  showTable = () => {
    const { filteredEmployees, isLoading } = this.state;
    return (
      <TableEmployee
        employees={filteredEmployees}
        isLoading={isLoading}
        setEditModalVisible={this.setEditModalVisible}
        setShowModalVisible={this.setShowModalVisible}
        setPasswordModalVisible={this.setPasswordModalVisible}
      />
    );
  };

  setCreateModalVisible = () => {
    this.setState({
      createVisible: true,
    });
  };

  setShowModalVisible = (employee) => {
    this.setState({
      employee,
      showVisible: true,
    });
  };

  setEditModalVisible = (employee) => {
    this.setState({
      employee,
      editVisible: true,
    });
  };

  setPasswordModalVisible = (employee) => {
    this.setState({
      employee,
      passwordVisible: true
    });
  };

  onCancel = () => {
    this.setState({
      editVisible: false,
      createVisible: false,
      showVisible: false,
      passwordVisible: false
    });
  };

  onCreate = (values) => {
    this.setState({
      isSubmitButtonDisabled: true,
    });
    userActions.createEmployee(values, this.props.history).then((response) => {
      if (response.status === 201) {
        this.fetchEmployees();
        success("Employee is registered succesfully");
      } else {
        Object.entries(response.data.errors).map(([key, values]) => {
          error(`${key} ${values}`);
        });
      }
      this.setState({
        isSubmitButtonDisabled: false,
      });
    });
  };

  onEdit = (values) => {
    this.setState({ isSubmitButtonDisabled: true });
    userActions
      .updateEmployee(values, this.state.employee.id)
      .then((response) => {
        if (response.status === 204) {
          this.fetchEmployees();
          success("Sucessfully updated employee.");
        } else {
          Object.entries(response.data.errors).map(([key, values]) => {
            error(`${key} ${values}`);
          });
        }
        this.setState({ isSubmitButtonDisabled: false });
      });
  };

  onPasswordChange = (values) => {
    this.setState({ isSubmitButtonDisabled: true});
    userActions
      .updateEmployee(values, this.state.employee.id)
      .then((response) => {
        if (response.status === 204) {
          this.setState({ passwordVisible: false})
          success("Password Updated Sucessfully!.");
        } else {
          Object.entries(response.data.errors).map(([key, values]) => {
            error(`${key} ${values}`);
          });
        }
      this.setState({ isSubmitButtonDisabled: false})     
    })
  }

  displayCreateEditShowModal = () => {
    const {
      createVisible,
      editVisible,
      showVisible,
      passwordVisible,
      isSubmitButtonDisabled,
      employee,
    } = this.state;
    const { onlyAreaNames } = this.context
    return (
      <Fragment>
        {createVisible && (
          <CreateEmployee
            areas={onlyAreaNames}
            createVisible={createVisible}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            onCancel={this.onCancel}
            onCreate={this.onCreate}
          />
        )}
        {editVisible && (
          <EditEmployee
            areas={onlyAreaNames}
            editVisible={editVisible}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            onCancel={this.onCancel}
            onEdit={this.onEdit}
            employee={employee}
          />
        )}
        {showVisible && (
          <ShowEmployee
            employee={employee}
            showVisible={showVisible}
            onCancel={this.onCancel}
          />
        )}
        {passwordVisible && (
          <PasswordChangeEmployee 
            employee={employee}
            passwordVisible={passwordVisible}
            onSubmit={this.onPasswordChange}
            onCancel={this.onCancel}
          />
        )}
      </Fragment>
    );
  };

  filterEmployees=(e)=>{
    const searchValue = e.target.value;
    let filteredData = this.state.employees.filter(employee =>
      employee.name.toUpperCase()?.includes(searchValue.toUpperCase()) ||employee.email.toUpperCase()?.includes(searchValue.toUpperCase())
    );
    this.setState({filteredEmployees: filteredData})
  }


  render() {
    return (
      <Layout sidebarSelectedKey={this.props.location.search.replace('?','')}>
        {isAdmin() ? (
          <Fragment>
            <Row style={{ padding: "1em 0 1em 0" }}>
              <Col offset={3} span={15}>
                <h5>Employee Information</h5>
              </Col>             

              <Col span={3}>
                <Button
                  type="primary"
                  onClick={() => this.setCreateModalVisible()}
                  className="btn-right"
                >
                  Create
                </Button>
              </Col>
            </Row>

            <Row>
              <Col span={5} offset={16}>
                <label>
                  <Input placeholder= "Search by name or email." onChange= {this.filterEmployees} />
                </label> 
              </Col>  
            </Row>

            <Row>
              <Col offset={3} span={18}>
                {this.showTable()}
              </Col>
            </Row>
            {this.displayCreateEditShowModal()}
          </Fragment>
        ) : (
          <Result status="warning" title="Page not available." />
        )}
      </Layout>
    );
  }
}
