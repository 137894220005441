import React, { Component, Fragment } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import GeoJSON from "ol/format/GeoJSON";
import Feature from "ol/Feature";
import Geolocation from "ol/Geolocation";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import Point from "ol/geom/Point";
import Polyline from "ol/format/Polyline";
import { Modal } from "antd";
import { reservationActions } from "../../actions/ReservationActions";
import { error } from "../../helpers/Notification";

export class DirectionMap extends Component {
  constructor(props) {
    super(props);
    this.state = { geolocation: null };
  }

  componentDidMount() {
    setTimeout(() => this.setMap(), 100);
  }

  onCancel = () => {
    const {geolocation} = this.state
    if(geolocation){
      geolocation.setTracking(false);
    }
    this.props.onCancel();
  };

  setMap = () => {
    const { reservation } = this.props;
    var map = new Map({
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      target: "map",
      view: new View({
        projection: "EPSG:4326",
        center: [0, 0],
        zoom: 2
      })
    });

    var geojsonObject = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "EPSG:4326"
        }
      },
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: reservation.spot_coordinates
          }
        }
      ]
    };

    var source = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject)
    });

    var spotLayer = new VectorLayer({
      source: source,
      style: new Style({
        stroke: new Stroke({
          color: "green",
          width: 1
        }),
        fill: new Fill({
          color: "rgba(56, 168, 50, 0.8)"
        })
      })
    });

    map.addLayer(spotLayer);

    var geojsonObjectSpots = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "EPSG:4326"
        }
      },
      features: []
    };

    reservation.area_spots.forEach(spot => {
      if (reservation.spot_id !== spot.spot_id) {
        geojsonObjectSpots["features"].push({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: spot.spot_coordinates
          }
        });
      }
    });

    var spotsSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObjectSpots)
    });

    var spotsStyle = new Style({
      stroke: new Stroke({
        color: "rgba(43, 24, 51)",
        width: 1
      }),
      fill: new Fill({
        color: "rgba(247, 255, 248, 0)"
      })
    });

    var vectorLayerSpots = new VectorLayer({
      source: spotsSource,
      zIndex: 9999,
      style: spotsStyle
    });

    map.addLayer(vectorLayerSpots);

    var geojsonObjectArea = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "EPSG:4326"
        }
      },
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: reservation.area_coordinates
          }
        }
      ]
    };

    var areaSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObjectArea)
    });

    var areaLayer = new VectorLayer({
      source: areaSource,
      style: new Style({
        stroke: new Stroke({
          color: "blue",
          width: 1
        }),
        fill: new Fill({
          color: "rgba(247, 255, 248, 0)"
        })
      })
    });

    map.addLayer(areaLayer);

    var geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: "EPSG:4326"
    });

    geolocation.on("error", function(error) {
      console.log(error.message);
    });

    var accuracyFeature = new Feature();
    geolocation.on("change:accuracyGeometry", function() {
      accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
    });

    var positionFeature = new Feature();
    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: "#3399CC"
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2
          })
        })
      })
    );
    var GPSLayer = new VectorLayer({
      source: new VectorSource({
        features: [positionFeature]
      })
    });
    map.addLayer(GPSLayer);
    this.setState({
      geolocation: geolocation
    });

    var initialLoad = true;
    var changePosition = () => {
      var currentCoordinates = geolocation.getPosition();
      var { spot_centroid } = this.props.reservation;
      if (initialLoad) {
        initialLoad = false;
        reservationActions
          .getDirection({
            origin: `${currentCoordinates[1]},${currentCoordinates[0]}`,
            destination: `${spot_centroid[1]},${spot_centroid[0]}`
          })
          .then(response => {
            if (response.status === 200 && response.data.status === "OK") {
              var route = new Polyline({
                factor: 1e5
              }).readGeometry(
                response.data.routes[0].overview_polyline.points,
                {
                  dataProjection: "EPSG:4326",
                  featureProjection: "EPSG:4326"
                }
              );
              var routeFeature = new Feature({
                type: "route",
                geometry: route
              });
              var routeLayer = new VectorLayer({
                source: new VectorSource({
                  features: [routeFeature]
                }),
                style: new Style({
                  stroke: new Stroke({
                    width: 5,
                    color: "rgba(19, 158, 158,0.8)"
                  })
                })
              });
              map.addLayer(routeLayer);
              map.getView().fit(routeFeature.getGeometry().getExtent());
              map.getView().setZoom(map.getView().getZoom() - 1);
            } else {
              error("Unable to find direction, Please try again");
            }
          });
      }
      positionFeature.setGeometry(
        currentCoordinates ? new Point(currentCoordinates) : new Point([0, 0])
      );
    };
    geolocation.on("change", changePosition);
  };

  render() {
    const { area_name, spot_name } = this.props.reservation;
    return (
      <Fragment>
        <Modal
          title={`Direction to ${spot_name} of ${area_name}`}
          centered
          visible={this.props.visible}
          onCancel={this.onCancel}
          footer={null}
          width={this.props.width}
        >
          <div id="map" style={{ height: "45em" }}></div>
        </Modal>
      </Fragment>
    );
  }
}

export default DirectionMap;
