import React, { Component, Fragment } from "react";
import { Table, Divider, Icon, Tooltip, Tag } from "antd";

export default class TableEmployee extends Component {
  tableColumns = () => {
    const { setEditModalVisible, setShowModalVisible, setPasswordModalVisible } = this.props;
    return [
      {
        title: "Employee name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Role",
        dataIndex: "role",
      },
      {
        title: "Areas",
        key: "area",
        render: (record) => {
          return (
            <Fragment key={record.id}>
              {record.assigned_areas.map((assigned_area) => {
                return assigned_area.area_street ? (
                  <Tag key={assigned_area.area_street} color="grey" style={{marginTop: 3}}>
                    {" "}
                    {assigned_area.area_street}
                  </Tag>
                ) : null;
              })}
            </Fragment>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        render: (record) => {
          return (
            <div style={{ display:"flex"}}>
              <Tooltip title="View Employee">
                <Icon
                  type="eye"
                  style={{ color: "#2A7CF8" }}
                  onClick={() => setShowModalVisible(record)}
                />
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Edit Employee Details">
                <Icon
                  type="edit"
                  title="edit"
                  style={{ color: "#2A7CF8" }}
                  onClick={() => setEditModalVisible(record)}
                />
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Change Password">
                <Icon
                  type="lock"
                  style={{ color: "#2A7CF8" }}
                  onClick={() => setPasswordModalVisible(record)}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ];
  };
  render() {
    const { employees, isLoading } = this.props;
    return (
      <Table
        columns={this.tableColumns()}
        dataSource={employees}
        rowKey={(column) => column.id}
        loading={isLoading}
        bordered
      />
    );
  }
}
