import customHttp from "../helpers/CustomHttp";
import { authHeader } from "../helpers/AuthHeader";
import moment from "moment";

export const parkedVehicleActions = {
  directPark,
  fetchParkedVehicles,
  leaveParking,
  deleteParkedVehicle
}

function directPark(values){
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    url: '/parked_vehicles',
    data: values
  };
  return customHttp(requestOptions).then(response =>{
    return response;
  });
}

function fetchParkedVehicles(values){
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    params: getParkedVehicleFilterParams(values),
    url: '/parked_vehicles'
  };
  return customHttp(requestOptions).then(response =>{
    return response;
  });
}

function leaveParking(parkId){
  const requestOptions ={
    method: "PUT",
    headers: authHeader(),
    url: `/parked_vehicles/${parkId}/leave_vehicle`
  }
  return customHttp(requestOptions).then(response =>{
    return response;
  });
}

function deleteParkedVehicle(parkId) {
  const requestOptions ={
    method: "PUT",
    headers: authHeader(),
    url: `/parked_vehicles/${parkId}/soft_delete`
  }
  return customHttp(requestOptions).then(response =>{
    return response;
  });
}

function getParkedVehicleFilterParams(values) {
  var params = { q: {} };
  if (values["vehicle_cat"]) {
    params["q"]["vehicle_cat_eq"] = values["vehicle_cat"];
  }
  if (values["user_id"]) {
    params["q"]["user_id_eq"] = values["user_id"];
  }
  if (values["area_id"]) {
    params["q"]["area_id_eq"] = values["area_id"];
  }
  if (values["vehicle_no"]) {
    params["q"]["vehicle_no_cont"] = values["vehicle_no"];
  }
  if (values["end_date"]) {
    params["q"]["date_lteq"] = new Date(values["end_date"]);
  }
  if (values["start_date"]) {
    params["q"]["date_gteq"] = new Date(values["start_date"]);
  }
  params["page"] = parseInt(values["page"]);

  return params;
}
