import React, { Component } from "react";
import Layout from "../Layout/Custom_Layout/Layout";
import ParkedVehicleTable from "./ParkedVehicleTable";
import { Modal, Col, Button, Row, Icon} from "antd";
import { success } from "../../helpers/Notification";
import { getErrorMessages } from "../../helpers/getErrors";
import { parkedVehicleActions } from "../../actions/ParkedVehicleActions";
import { invoiceActions } from "../../actions/InvoiceActions";
import { isAdmin, isEmployee, isAccountant } from "../../helpers/userPolicy";
import Invoice from "../Invoice/Invoice";
import { ReservationContext } from "../../contexts/ReservationContext";
import FilterForm from "./FilterForm";
import { userActions } from "../../actions/UserActions";
import queryString from "query-string";
import {
  endTime,
  startTime,
} from "../../constants/InvoiceFilterConstants";
import moment from "moment";
import QrImage from "../Reservation/qrImage";

const { confirm } = Modal;

class ParkedVehicleIndex extends Component {
  static contextType = ReservationContext;

  constructor() {
    super();
    this.state = {
      parkedVehicles: [],
      areas: [],
      vehicleTypes: [],
      users: [],
      showInvoice: false,
      invoiceData: [],
      showSpinner: true,
      currentPage: 1,
      totalParkedVehicles: 1,
    };
  }

  componentDidMount() {
    if(isAdmin() || isEmployee() || isAccountant()){
      if (Object.keys(this.props.location.search).length === 0) {
        this.props.history.replace(
          `/parked-vehicles?start_date=${startTime}&end_date=${endTime}&page=1`
        );
      } else {
        this.fetchParkedVehiclesUsingQuery();
      }
      this.fetchUsers();
    }else{
      this.props.history.push("/unauthorized")
    }
    this.setFilterFormUsingQuery();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location?.search !== prevProps.location?.search) {
      this.fetchParkedVehiclesUsingQuery();
    }
    this.setFilterFormUsingQuery();
  }

  setFilterFormUsingQuery = () => {
    const parsedJson = queryString.parse(this.props.location?.search);
    var endDateString = new Date(parsedJson.end_date).toString();
    endDateString = endDateString.split(' ').slice(0, 5).join(' ');
    var startDateString = new Date(parsedJson.start_date).toString();
    startDateString = startDateString.split(' ').slice(0, 5).join(' ');

    this.formRef.setFieldsValue({
      area_id: parsedJson.area_id,
      user_id: parsedJson.user_id,
      vehicle_cat: parsedJson.vehicle_cat,
      vehicle_no: parsedJson.vehicle_no,
      date_range:
        parsedJson["start_date"] && parsedJson["end_date"]
          ? [moment(startDateString), moment(endDateString)]
          : [],
    });
  };

  fetchParkedVehiclesUsingQuery = () => {
    var filterValues = queryString.parse(this.props.location.search);
    this.setState({
      showSpinner: true,
      currentPage: parseInt(filterValues["page"]),

    });
    parkedVehicleActions.fetchParkedVehicles(filterValues).then((response) => {
      this.setState({
        parkedVehicles: response.data.parked_vehicles,
        showSpinner: false,
        totalParkedVehicles:
          response.data.meta.pagination.parked_vehicles_length,
      });
    });
  };

  fetchUsers = () => {
    if (isAdmin() || isEmployee() || isAccountant()) {
      userActions.fetchUsers().then((response) => {
        this.setState({
          users: response.data,
        });
      });
    }
  };

  handleCancel = () => {
    this.setState({ showInvoice: false });
    let newPage = this.state.currentPage;
    if (this.state.parkedVehicles.length === 1) {
      var filterValues = queryString.parse(this.props.location.search);
      newPage = newPage > 1 ? newPage - 1 : 1;
      this.setState({ currentPage: newPage });
      filterValues["page"] = newPage;
      const query = queryString.stringify(filterValues, { encode: false });
      this.props.history.replace(`/parked-vehicles?${query}`);
    } else {
      this.fetchParkedVehiclesUsingQuery();
    }
  };

  showDeleteConfirm = (parkedVehicleId) => {
    confirm({
      title: "Do you want to delete this parked vehicle?",
      icon: <Icon type="delete" style={{ color: "red" }} />,
      onOk: () => this.removeParkedVehicle(parkedVehicleId),
      onCancel() {},
    });
  }

  removeParkedVehicle = (parkedVehicleId) => {
    parkedVehicleActions.deleteParkedVehicle(parkedVehicleId).then((response) => {
      if (response.status === 204) {
        this.fetchParkedVehiclesUsingQuery();
      }
    });
  };

  showConfirm = (parkId) => {
    confirm({
      title: "Do you want to leave?",
      onOk: () => this.leaveParking(parkId),
      onCancel() {},
    });
  };

  leaveParking = (parkId) => {
    parkedVehicleActions.leaveParking(parkId).then((response) => {
      if (response.status === 204) {
        invoiceActions.createInvoice(parkId).then((response) => {
          this.context.setSpots(
            this.context.areaId,
            this.context.vehicleTypeId
          );
          this.setState({
            invoiceData: response.data,
            showInvoice: true,
          });
          success("Parking  has been left.");
        });
      } else {
        getErrorMessages(response.data.errors);
      }
    });
  };

  onPaginationChange = (page) => {
    let parsedJson = queryString.parse(this.props.location?.search);
    parsedJson["page"] = page;
    this.props.history.push(
      `/parked-vehicles?${queryString.stringify(parsedJson, { encode: false })}`
    );
  };

  removeEmptyFromJson = (values) => {
    Object.keys(values).forEach((k) => {
      if (!values[k] || values[k].length === 0) {
        delete values[k];
      }
    });
    return values;
  };

  handleFilter = (values) => {
    values = this.removeEmptyFromJson(values);
    const date_range = values["date_range"];
    if (date_range) {
      console.log(values.date_range[0].format("M/D/YYYY HH:mm"))
      values["start_date"] = values["date_range"][0].format("M/D/YYYY HH:mm");
      values["end_date"] = values["date_range"][1].format("M/D/YYYY HH:mm");
      delete values["date_range"];
    }
    const query = queryString.stringify(values, { encode: false });
    this.props.history.push(
      `/parked-vehicles?${query.length > 0 ? query + "&" : ""}page=1`
    );
  };

  handleClearFilter = () => {
    this.props.history.push(`/parked-vehicles?start_date=${startTime}&end_date=${endTime}&page=1`);
  };

  onQRCancel = () => {
    this.context.setDirectParked(false);
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    const {
      users,
      showSpinner,
      parkedVehicles,
      showInvoice,
      invoiceData,
      totalParkedVehicles,
      currentPage,
    } = this.state;
    const {
      directParked,
      directParkedData,
      vehicleTypes,
      onlyAreaNames,
    } = this.context;
    return (
      <Layout sidebarSelectedKey="parkedVehicle">
        {isAdmin() || isEmployee() || isAccountant() ? (
          <>
            <Row>
              <Col>
                <span style={{fontSize: "1.25rem"}}>
                  Parked Vehicles &nbsp;
                  <Icon type="sync" className="reload-button" spin = {this.state.showSpinner} title= "Reload Parked Vehicles"  onClick={() => this.fetchParkedVehiclesUsingQuery()}/>
                </span>
                {isAdmin() || isEmployee() ? (
                  <Button
                    type="primary"
                    onClick={() => this.props.history.push("reservation/create")}
                    className="btn-right"
                  >
                    Create
                  </Button>
                ): null}
              </Col>
            </Row>
            <br/>
            <FilterForm
              vehicleTypes={vehicleTypes}
              users={users}
              areas={onlyAreaNames}
              showSpinner={showSpinner}
              handleFilter={this.handleFilter}
              handleClear={this.handleClearFilter}
              wrappedComponentRef={this.saveFormRef}
            />
            <ParkedVehicleTable
              parkedVehicles={parkedVehicles}
              showConfirm={this.showConfirm}
              showSpinner={showSpinner}
              totalParkedVehicles={totalParkedVehicles}
              currentPage={currentPage}
              onPaginationChange={this.onPaginationChange}
              showDeleteConfirm={this.showDeleteConfirm}
            />
            {showInvoice && (
              <Invoice
                visible={showInvoice}
                onCancel={this.handleCancel}
                invoiceData={invoiceData}
              />
            )}
            {directParked && (
              <QrImage
                qrCodeData={directParkedData}
                onCancel={this.onQRCancel}
              />
            )}
          </>
        ) : null}
      </Layout>
    );
  }
}
export default ParkedVehicleIndex;
