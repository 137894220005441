import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Modal,
  Form,
  Input,
  Tooltip,
  Icon
} from "antd";

class ConfirmPhoneNumber extends Component {
  
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title=""
        centered
        visible={this.props.visible}
        onOk ={this.props.submitOtpForm}
        onCancel={this.props.handleCancel}
        okText ={this.props.status}
        okButtonProps = {{disabled: this.props.otpButton}}
      >
        <div>
          <br />
          <Form>
            <Form.Item
              label={
                <span>
                  Confirmation Code to {this.props.status}&nbsp;
                  <Tooltip title="Enter 6 digit code sent to your mobile">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("otp_code", {
                rules: [
                  {
                    required: true,
                    message: `Enter 6 digit code to ${this.props.status}`,
                    whitespace: true
                  }
                ]
              })(<Input
                  name="otp_code"
                  placeholder= {`Enter 6 digit code to ${this.props.status}`}
                  />
                )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("phone_no", {
                initialValue: this.props.phone_no
              })(<Input
                  name="phone_no"
                  type = "hidden"  
              />)}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default withRouter(Form.create({})(ConfirmPhoneNumber));
