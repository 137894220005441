import React, {Component} from 'react';
import Layout from '../Layout/Custom_Layout/Layout';
import './home.scss';
import DashboardSales from './DashboardSales';
import ParkingRateDisplay from './ParkingRateDisplay';
import {dashboardActions} from '../../actions/DashboardActions';

export default class Welcome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      salesDashboardData: [],
      freeDashboardData: [],
      salesLoading: false,
      freeLoading: false
    }
  }
  componentDidMount() {
    this.fetchDashboardData()
  }

  fetchDashboardData = (searchParameter, department) => {
    if(department?.toLowerCase() === 'sales') {
      this.setState({salesLoading: true})
    }else if (department?.toLowerCase() === 'free') {
      this.setState({freeLoading: true})
    }else {
      this.setState({freeLoading: true, salesLoading: true})
    }
    dashboardActions.fetchDashboardData(searchParameter).then(res => {
      if(department?.toLowerCase() === 'sales') {
        this.setState({salesDashboardData: res.data})
      }else if(department?.toLowerCase() === 'free') {
        this.setState({
          freeDashboardData: res.data,
        })
      }else {
        this.setState({
          freeDashboardData: res.data,
          salesDashboardData: res.data
        })
      }
    this.setState({freeLoading: false, salesLoading: false})
    })
  }

  render () {

    return (
      <div className="home">
        <Layout sidebarSelectedKey="home">
          <div className='dashboardWrapper'>
            <DashboardSales
              title="Sales"
              backgroundColor="#6BD098"
              dashboardData={this.state.salesDashboardData.filter(e => e.sales_spot_count !== 0 )}
              fetchDashboardData={this.fetchDashboardData}
              loading={this.state.salesLoading}
            />
            <DashboardSales
              title="Free"
              backgroundColor="#FAC658"
              dashboardData={this.state.freeDashboardData.filter(e => e.free_spot_count !== 0 )}
              fetchDashboardData={this.fetchDashboardData}
              loading={this.state.freeLoading}
            />
          </div>
          <ParkingRateDisplay />
        </Layout>
      </div>
    );
  }
}
