import React, {Component} from 'react';
import {Tabs} from 'antd';
import LoadingSpinner from "../loadSpinner/loadSpinner";

const { TabPane } = Tabs;
export default class DashboardSales extends Component {
  render () {
    const {backgroundColor, title, dashboardData, fetchDashboardData, loading} = this.props
    const tabPanes = [{title: 'Today' }, {title: 'This Week' }, {title: 'This Month' }]

    const fetchDataOnChange = (key) => {
      fetchDashboardData(key, title)
    }

    return (
      <div className='dashboardSalesWrapper'>
        <div className='dashboardSalesTitle' style={{background: backgroundColor}}>
          {title}
        </div>
        <div className="card-containter">
          <Tabs type="card" onChange={(key) => fetchDataOnChange(key) }>
            {
              tabPanes.map(tabPane => {
                return(
                  <TabPane tab={tabPane.title} key={tabPane.title} >
                    <div className='tabPaneWrapper' style={{minHeight: 50}}>
                    {
                      !loading ?
                          dashboardData.length > 0 ?
                            <>
                              {
                                dashboardData.map((data, index) => {
                                  return(
                                    <div key={index} className='tabPaneContent' style={{width: `${100/dashboardData.length}%`}}>
                                      <div>{data.vehicle_cat}</div>
                                      <div style={{color: 'black'}}>{title.toLowerCase() === 'sales' ? data.sales_spot_count : data.free_spot_count}</div>
                                    </div>
                                  )
                                })
                              }
                              <div className='total-amount' style={{width: `${100/dashboardData.length}%`}}>
                                <div>Total</div>
                                <div style={{color: 'black'}}>
                                  Rs. {eval(dashboardData.map(e => e.total_rate).join('+'))}
                                </div>
                              </div>
                            </>
                          :
                            <div className='no-data-content'>No Data</div>
                      :
                      <LoadingSpinner height='none' />
                    }
                    </div>
                  </TabPane>
                )
              })
            }
          </Tabs>
        </div>
      </div>
    );
  }
}
