import React, { Component } from "react";
import { vehicleTypeActions } from "../../actions/VehicleTypeActions";
import { Table, Divider, Modal, Icon, Tooltip } from "antd";
import Show from "./ShowVehicle";
import Edit from "../VehicleType/EditVehicle";
import { Link } from "react-router-dom";
import { success, error } from "../../helpers/Notification";

const { confirm } = Modal;

class TableVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle_types: props.table_data,
      editVisible: false,
      showVisible: false,
      editButton: false,
      vehicleType: {}
    };

    this.setShowModalVisible = this.setShowModalVisible.bind(this);
    this.setEditModalVisible = this.setEditModalVisible.bind(this);
    this.deleteVehicleType = this.deleteVehicleType.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  setShowModalVisible(vehicleType) {
    this.setState({ showVisible: true });
    this.setState({ vehicleType });
  }

  setEditModalVisible(vehicleType) {
    this.setState({ editVisible: true });
    this.setState({ vehicleType });
  }

  handleCancel = () => {
    this.setState({ editVisible: false, showVisible: false });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  deleteVehicleType(vehicleTypeId) {
    vehicleTypeActions.deleteVehicleType(vehicleTypeId).then(response => {
      if (response.status === 204) {
        vehicleTypeActions.fetchVehicleTypes().then(response => {
          this.setState({ vehicle_types: response.data });
          success("Vehicle Type has been sucessfully deleted.");
        });
      } else {
        error(response.data.error || "Something went wrong. Please try again.");
      }
    });
  }

  showConfirm(vehicleType) {
    confirm({
      title: "Do you Want to delete this vehicle type ?",
      content: `Vehicle category =>  ${vehicleType.vehicle_cat}`,
      onOk: () => this.deleteVehicleType(vehicleType.id),
      onCancel() {
        console.log("Cancel");
      }
    });
  }
  
  formatValues = (values) => {
    Object.entries(values).forEach(([key,value]) => {
      if(!value){
          delete values[key]
      }
    });
    return values;
  }

  handleEdit = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ editButton: true });
        vehicleTypeActions
          .updateVehicleType(this.formatValues(values), this.state.vehicleType.id)
          .then(response => {
            if (response.status === 200) {
              vehicleTypeActions.fetchVehicleTypes().then(response => {
                this.setState({
                  vehicle_types: response.data,
                  editVisible: false
                });
              });
              success("Vehicle type has been sucessfully updated.");
            } else {
              error(
                response.data.error || "Something went wrong. Please try again."
              );
            }
            this.setState({ editButton: false });
          });
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.table_data !== prevProps.table_data) {
      this.setState({ vehicle_types: this.props.table_data });
    }
  }

  render() {
    const columns = [
      {
        title: "Vehicle Type",
        dataIndex: "vehicle_cat"
      },
      {
        title: "Sort Order",
        dataIndex: "sort_order"
      },
      {
        title: "Rate",
        dataIndex: "hourly_rate"
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => {
          return (
            <span>
              <Tooltip title="`View Vehicle Type">
                <Link onClick={() => this.setShowModalVisible(record)}>
                  <Icon type="eye" />
                </Link>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Edit Vehicle Type">
                <Link onClick={() => this.setEditModalVisible(record)}>
                  <Icon type="edit" title="edit" />
                </Link>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Delete Vehicle Type">
                <Link onClick={() => this.showConfirm(record)}>
                  <Icon type="delete" />
                </Link>
              </Tooltip>
            </span>
          );
        }
      }
    ];
    const { vehicle_types } = this.state;
    return (
      <div>
        <Table
          columns={columns}
          dataSource={vehicle_types}
          rowKey={column => column.id}
          bordered
        />

        {this.state.editVisible && (
          <Edit
            wrappedComponentRef={this.saveFormRef}
            editVisible={this.state.editVisible}
            onCancel={this.handleCancel}
            onEdit={this.handleEdit}
            vehicleType={this.state.vehicleType}
            editButton={this.state.editButton}
          />
        )}

        {this.state.showVisible && (
          <Show
            showVisible={this.state.showVisible}
            onCancel={this.handleCancel}
            vehicleType={this.state.vehicleType}
          />
        )}
      </div>
    );
  }
}

export default TableVehicle;
