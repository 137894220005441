import React from "react";
import "./login.scss";
import Logo from "../../images/logo2.png";
import TempleImg from "../../images/temples_graphic.png";

import Media from "react-media";

const Index = ({ children }) => {
  return (
    <Media
      queries={{
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 991px)",
        large: "(min-width: 1200px)",
      }}
    >
      {(matches) => (
        <div className="main">
          <div className="content">
            {matches.small || matches.medium ? null : (
              <div className="description">
                <div className="coverImg">
                  <img src={TempleImg} alt="Logo" width="70%" />
                </div>
                <div className="coverText">
                  <h1 className="coverTextTitle">SPOT</h1>
                  <h1 className="coverTextTitle coverTextTitle2"> N </h1>
                  <h1 className="coverTextTitle">PARK</h1>
                </div>
              </div>
            )}

            <div className="form">
              <div className="logoContainer">
                <img src={Logo} alt="Logo" width="60%" />
              </div>

              {children}
            </div>
          </div>
        </div>
      )}
    </Media>
  );
};

export default Index;
