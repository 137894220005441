import React, { Component, Fragment } from "react";
import { Row, Col, List, Tag, Tooltip, Empty } from "antd";
import { ReservationContext } from "../../contexts/ReservationContext";
import { checkStatus } from "../../helpers/SpotCheckStatus"

class SpotList extends Component {
  static contextType = ReservationContext;
  
  render() {
    const {
      spots,
      onSpotClicked,
      highlightSpot,
      resetSpotHighlight
    } = this.context;
    return (
      <List itemLayout="vertical" size="small">
        {spots.map(spot => {
          var distance = spot.distance ? " ( " + spot.distance + "m far )" : "";
          let { title, symbol, style, color } = checkStatus(spot);
          return (
            <Tooltip key={spot.id} title={title}>
              <div key={spot.id} className={style}>
                <List.Item
                  key={spot.id}
                  onClick={
                    spot.availability && !spot.reservation && !spot.parked_vehicle
                      ? () => onSpotClicked(spot)
                      : null
                  }
                  onMouseOver={() => highlightSpot(spot.id)}
                  onMouseLeave={() => resetSpotHighlight()}
                >
                  <div style={{ paddingLeft: "1em" }}>
                    <List.Item.Meta
                      title={
                        <Row>
                          <Col span={16}>
                            <span>
                              {spot.name}
                              {distance}
                            </span>
                          </Col>
                          <Col span={8}>
                            <Tag color="geekblue" className="btn-right">
                              Rs. {spot.spot_rate}
                              /{spot.charging_period} min
                            </Tag>
                          </Col>
                        </Row>
                      }
                      description={
                        <Fragment>
                          <Row>
                            <span>Spot for </span>
                            <Tag color="purple">
                              {spot.spot_vehicle_type}
                            </Tag>
                          </Row>
                          <Row>
                            <span>Spot Status : </span>
                            <Tag color={color}>{symbol}</Tag>
                          </Row>
                        </Fragment>
                      }
                    />
                  </div>
                </List.Item>
              </div>
              <br />
            </Tooltip>
          );
        })}
        {spots.length === 0 ? <Empty /> : null}
      </List>
    );
  }
}

export default SpotList;
