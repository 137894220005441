import React, {Component} from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Select,
DatePicker } from "antd";
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const {Option} = Select;

class NotificationForm extends Component{
  constructor(props){
    super(props);
    this.state = {value: ''};
  }
  
  render(){
    const todayDate = new Date();
    const defaultPublishedDate =  new Date();
    const defaultExpiredDate = new Date(todayDate.setMonth(todayDate.getMonth()+1 ))
    const { visible, onCancel, onSubmit, areaId, title, notification, changeMessageType, hidden} = this.props
    const { getFieldDecorator } = this.props.form
    return(
      <div>
        <Modal
          title= <h5>{title} </h5>
          visible={visible}
          onCancel={onCancel}
          onOk = {onSubmit}
          okText={title}
        >
          <Form layout={"vertical"}>
            <Row> 
              <Col>
                <Form.Item>
                  {getFieldDecorator("message_type", {
                    rules: [
                      { required: true, message: "Please Enter Message Type" }
                    ],
                    initialValue: notification && notification.message_type
                  })(
                    <Select
                    value={this.state.value} onChange= {changeMessageType}
                      showSearch
                      placeholder="Select Message Type"
                    >
                          <Option value="greetings">Greetings</Option>
                          <Option value="updates">Updates</Option>
                          <Option value = "offers">Offers</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>   


              <Col>
                <Form.Item>
                  {
                    getFieldDecorator("publish_message", {
                      rules: [
                        { required: true, message: "Please enter the offer message" }
                      ],
                    initialValue: notification && notification.publish_message
                  })(
                    <Input
                     placeholder="Enter Message To Publish"
                    />
                  )}
                </Form.Item>
              </Col>
              
              <Col>
                <Form.Item label={<span><b>Day To Broadcast:</b></span>}>
                  {
                    getFieldDecorator("publish_date", {
                      rules: [
                        { required: true, message: "Please enter the starting time." }
                      ],
                      initialValue: (notification && moment.utc(notification.publish_date, dateFormat)) || moment.utc(defaultPublishedDate.toLocaleDateString())
                  })(
                    <DatePicker/>
                  )}
                </Form.Item>
              </Col>
              <div>
              { !hidden? 
                <div>
                  <Col>
                    <Form.Item>
                      {
                        getFieldDecorator("expired_message", {
                          
                          initialValue: notification && notification.expired_message
                      })(
                        <Input
                        placeholder="Enter Expire Message"

                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item  label={<span><b>Expire Date:</b></span>}>
                      {
                        getFieldDecorator("expired_date", {
                          initialValue: (notification?.expired_date && moment.utc(notification.expired_date, dateFormat)) || moment.utc(defaultExpiredDate)
                      })(
                        <DatePicker/>
                      )}
                    </Form.Item>
                  </Col>
                </div>
              : ""}
            </div>
                            
              <Col span = {0}>
                <Form.Item>
                  {
                    getFieldDecorator("area_id" , {
                      initialValue: areaId
                    })(
                      <Input
                        name="notification"
                        readOnly
                        type= "hidden"
                      />
                    )}
                </Form.Item>  
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}
export default Form.create({})(NotificationForm);