import React, { Component, Fragment } from "react";
import Media from "react-media";
import { Result, Button, Modal } from "antd";
import { ReservationContext } from "../../contexts/ReservationContext";

class ReservationSuccess extends Component {
  static contextType = ReservationContext;
  render() {
    const { onReversed } = this.context;
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)"
        }}
      >
        {matches => (
          <Fragment>
            <Modal
              centered
              visible={true}
              footer={null}
              width={matches.medium ? "54em" : "75em"}
              closable={false}
            >
              <Result
                status="success"
                title="Successfully Reserved your Spot."
                subTitle="See you in 1 hour. Please be on Time."
                extra={[
                  <Button type="primary" key="console" onClick={onReversed}>
                    See your Reservation
                  </Button>
                ]}
              />
            </Modal>
          </Fragment>
        )}
      </Media>
    );
  }
}

export default ReservationSuccess;
