import React, { Component } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Draw, Modify, Snap } from "ol/interaction";
import GeoJSON from "ol/format/GeoJSON";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Form, Input, Button, Row, Col } from "antd";
import { Fill, Stroke, Style, Text } from "ol/style";
import { areaActions } from "../../actions/AreaActions";
import LoadSpinner from '../loadSpinner/loadSpinner';

let spotsLayer = null;
let newSpotsLayer = null;
let newSpotsDraw = null;
let currentAreaLayer = null;
let currentAreaDraw = null;
let currentAreaModify = null;
let currentAreaSnap = null;
let otherAreasLayer = null;
let map = null;
const otherAreasStyle = new Style({
  stroke: new Stroke({
    color: "green",
    width: 1,
  }),
  fill: new Fill({
    color: "rgba(247, 255, 248, 0)",
  }),
});
const currentAreaStyle = new Style({
  stroke: new Stroke({
    color: "#3f9ebf",
    width: 1,
  }),
  fill: new Fill({
    color: "rgba(247, 255, 248, 0)",
  }),
});
let spotsCount = 0;
export class AreaMap extends Component {
  constructor() {
    super();
    this.state = {
      loadMap: false
    }
  }

  componentDidMount() {
    this.setMap();
  }

  componentWillUnmount() {
    spotsLayer = null;
    newSpotsLayer = null;
    newSpotsDraw = null;
    currentAreaLayer = null;
    currentAreaDraw = null;
    currentAreaModify = null;
    currentAreaSnap = null;
    otherAreasLayer = null;
    map = null;
    spotsCount = 0;
  }

  setMap = () => {
    map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: "areaMap",
      view: new View({
        projection: "EPSG:4326",
        center: this.props.area?.centroid || [
          85.30095688580344,
          27.699336484946066,
        ],
        zoom: 14,
      }),
    });

    if (this.props.fromShow || this.props.fromEdit) {
      this.addCurrentAreaAndSpotsLayer();
    } else if (this.props.fromCreate) {
      this.addNewAreaDraw();
    }
    if (this.props.fromCreate || this.props.fromEdit) {
      this.addOtherAreasLayer();
    }
  };

  addCurrentAreaAndSpotsLayer = async () => {
    var geojsonObjectSpots = this.getInitialGeoJson();
    this.setState({loadMap: true})
    await areaActions.fetchAreaSpots(this.props.area.id).then(response => {
      response.data.forEach((spot, index) => {
        geojsonObjectSpots["features"].push({
          type: "Feature",
          id: `spot-${index}`,
          geometry: {
            type: "Polygon",
            coordinates: spot.coordinates,
          },
        });
      });
      this.setState({loadMap: false})
    });
    spotsLayer = this.getLayerForGeoJson(
      geojsonObjectSpots,
      "existingSpotsLayer"
    );
    if (this.props.fromEdit) {
      spotsCount = this.props.area.spots.length;
    }
    spotsLayer
      .getSource()
      .getFeatures()
      .forEach((feature, index) => {
        feature.setStyle(
          new Style({
            stroke: new Stroke({
              color: "rgba(43, 24, 51)",
              width: 1,
            }),
            fill: new Fill({
              color: "rgba(247, 255, 248, 0)",
            }),
            text: new Text({
              text: (index + 1).toString(),
              font: "12px Calibri,sans-serif",
              fill: new Fill({
                color: "black",
              }),
              stroke: new Stroke({
                color: "#fff",
                width: 3,
              }),
            }),
          })
        );
      });

    let geoJsonObjectCurrentArea = this.getInitialGeoJson();
    geoJsonObjectCurrentArea["features"].push({
      type: "Feature",
      id: "currentAreaFeature",
      geometry: {
        type: "Polygon",
        coordinates: this.props.area.coordinates,
      },
    });
    currentAreaLayer = this.getLayerForGeoJson(
      geoJsonObjectCurrentArea,
      "currentAreaLayer",
      currentAreaStyle
    );
    map && map.addLayer(currentAreaLayer);
    map && map.addLayer(spotsLayer);

    if (!this.props.fromShow) {
      currentAreaModify = new Modify({
        source: currentAreaLayer.getSource(),
      });
      currentAreaModify.on("modifyend", (e) => this.areaModifyEnd(e));
      map && map.addInteraction(currentAreaModify);
      currentAreaSnap = new Snap({ source: currentAreaLayer.getSource() });
      map && map.addInteraction(currentAreaSnap);
      this.setNewSpotDrawLayer(map, currentAreaLayer);
    }

    let extent = currentAreaLayer.getSource().getExtent();
    map && map.getView().fit(extent);
  };

  getLayerForGeoJson = (geoJsonObject, layerId, style = null) => {
    let source = new VectorSource({
      features: new GeoJSON().readFeatures(geoJsonObject),
    });

    return new VectorLayer({
      id: layerId,
      style,
      source: source,
      zIndex: 9999,
    });
  };

  getInitialGeoJson = () => {
    return {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "EPSG:4326",
        },
      },
      features: [],
    };
  };

  addNewAreaDraw = (redraw = false) => {
    this.props.form.setFieldsValue({
      coordinates: "",
    });
    map && map.removeLayer(currentAreaLayer);
    map && map.removeInteraction(currentAreaModify);
    map && map.removeInteraction(currentAreaDraw);
    map && map.removeInteraction(currentAreaSnap);
    map && map.removeInteraction(newSpotsDraw);
    map && map.removeLayer(newSpotsLayer);

    if (redraw) {
      this.props.deleteDrawnSpots();
    }

    let source = new VectorSource();
    currentAreaLayer = new VectorLayer({
      id: "currentAreaLayer",
      source: source,
      zIndex: 9999,
    });
    currentAreaModify = new Modify({ source: source });
    currentAreaDraw = new Draw({
      source: source,
      type: "Polygon",
      condition: function (e) {
        let features = map && map.getFeaturesAtPixel(e.pixel, {
          layerFilter: function (layer) {
            return layer === otherAreasLayer;
          },
        });

        if (features != null && features.length === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
    currentAreaSnap = new Snap({ source: source });

    currentAreaDraw.on("drawend", (e) => this.areaDrawEnd(e));
    currentAreaModify.on("modifyend", (e) => this.areaModifyEnd(e));

    map && map.addLayer(currentAreaLayer);
    map && map.addInteraction(currentAreaModify);
    map && map.addInteraction(currentAreaDraw);
    map && map.addInteraction(currentAreaSnap);
  };

  addOtherAreasLayer = () => {
    var geojsonObjectOtherAreas = this.getInitialGeoJson();
    this.props.areas.forEach((area) => {
      if (area.id !== this.props.area?.id || this.props.fromCreate) {
        geojsonObjectOtherAreas["features"].push({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: area.coordinates,
          },
        });
      }
    });

    otherAreasLayer = this.getLayerForGeoJson(
      geojsonObjectOtherAreas,
      "otherAreasLayer",
      otherAreasStyle
    );
    map && map.addLayer(otherAreasLayer);
  };

  areaDrawEnd = (e) => {
    this.props.form.setFieldsValue({
      coordinates: e.feature.values_["geometry"]["flatCoordinates"].toString(),
    });
    map && map.removeInteraction(currentAreaDraw);
    this.setNewSpotDrawLayer();
  };

  areaModifyEnd = (e) => {
    this.props.form.setFieldsValue({
      coordinates: e.features
        .getArray()[0]
        .values_["geometry"]["flatCoordinates"].toString(),
    });
  };

  setNewSpotDrawLayer = () => {
    let source = new VectorSource();
    newSpotsLayer = new VectorLayer({
      id: "newSpotsLayer",
      source: source,
      zIndex: 9999,
    });
    newSpotsDraw = new Draw({
      source: source,
      type: "Polygon",
      condition: function (e) {
        let features = map && map.getFeaturesAtPixel(e.pixel, {
          layerFilter: function (layer) {
            return layer === currentAreaLayer;
          },
        });

        if (features != null && features.length > 0) {
          return true;
        } else {
          return false;
        }
      },
    });
    newSpotsDraw.on("drawend", (e) => this.spotDrawEnd(e));
    map && map.addLayer(newSpotsLayer);
    map && map.addInteraction(newSpotsDraw);
  };

  spotDrawEnd = (e) => {
    var spotStyles = new Style({
      stroke: new Stroke({
        color: "rgb(72, 181, 240)",
        width: 1,
      }),
      text: new Text({
        text: (spotsCount + 1).toString(),
        font: "12px Calibri,sans-serif",
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 3,
        }),
      }),
    });
    e.feature.setId(`spot-${spotsCount}`);
    e.feature.setStyle(spotStyles);
    this.props.addSpot(e.feature.values_["geometry"]["flatCoordinates"]);
    spotsCount += 1;
  };

  removeSpotDraw = (featureId) => {
    let feature = null;
    if ((feature = newSpotsLayer.getSource().getFeatureById(featureId))) {
      newSpotsLayer.getSource().removeFeature(feature);
    } else if ((feature = spotsLayer.getSource().getFeatureById(featureId))) {
      spotsLayer.getSource().removeFeature(feature);
    }
  };

  render() {
    var getFieldDecorator = this.props.form?.getFieldDecorator;
    return (
      <div>
        <Row>
          <Col offset={0} span={24} style={{position: 'relative'}}>
            {
              this.state.loadMap &&
                <div style={{position: 'absolute', top: '20%', right: '45%', zIndex: 1000}}>
                  <LoadSpinner tipTitle='Loading Map...' />
                </div>
            }
            <div id="areaMap" className="map"></div>
            <br />
            {!this.props.fromShow ? (
              <Button
                onClick={() => this.addNewAreaDraw(true)}
                className="btn-right"
              >
                Re-draw
              </Button>
            ) : null}
          </Col>
        </Row>
        {!this.props.fromShow ? (
          <Row>
            <Col offset={3} span={10}>
              <Form>
                <Form.Item>
                  {getFieldDecorator("coordinates", {
                    rules: [
                      {
                        required: true,
                        message: "Please draw Area on map!",
                        whitespace: true,
                      },
                    ],
                    initialValue: this.props.area
                      ? this.props.area.coordinates.toString()
                      : "",
                  })(
                    <Input type="hidden" name="coordinates" id="coordinates" />
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default Form.create({})(AreaMap);
