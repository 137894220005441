import customHttp from "../helpers/CustomHttp";
import { authHeader } from "../helpers/AuthHeader";

export const areaActions = {
  fetchAreas,
  createArea,
  showArea,
  updateArea,
  deleteArea,
  fetchAreaVehicleTypes,
  fetchAreasStreets,
  fetchAreaSpots,
  fetchAreasDetail
};

function fetchAreaSpots(areaId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    url: `/areas/${areaId}/spots`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function fetchAreas(searchArea) {
  const searchParam = searchArea ? searchArea : "";
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    url: `/areas${searchParam}`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function createArea(values) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    url: `/areas`,
    data: values
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function showArea(id) {
  const requestOptions = {
    method: "get",
    headers: authHeader(),
    url: `/areas/${id}`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function updateArea(value, id) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    url: `/areas/${id}`,
    data: value
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function deleteArea(id) {
  const requestOptions = {
    method: "put",
    headers: authHeader(),
    url: `/areas/${id}/deactivate_area`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function fetchAreaVehicleTypes(areaId){
  const requestOptions = {
    method: "get",
    headers: authHeader(),
    url: `/areas/${areaId}/vehicle_types`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function fetchAreasStreets() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    url: '/areas/areas_streets'
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function fetchAreasDetail() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    url: 'areas/areas_detail_for_dashboard'
  };
  return customHttp(requestOptions).then(response =>{
    return response;
  });
}