import React from "react";
import { SpotMap } from "../Map/SpotMap";
import { vehicleTypeActions } from "../../actions/VehicleTypeActions";
import {
  Form,
  Select,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Alert,
  Modal
} from "antd";

const { Option } = Select;

class EditSpot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle_types: []
    };
  }

  componentDidMount() {
    vehicleTypeActions.fetchVehicleTypes().then(response => {
      this.setState({ vehicle_types: response.data, isLoading: false });
    });
  }

  render() {
    const { editVisible, onCancel, onEdit, spotEditButton, spot } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title=""
        centered
        visible={editVisible}
        onCancel={onCancel}
        footer={null}
      >
        <div>
          <br />
          <Row>
            <Col
              offset={2}
              span={20}
              style={{ background: "rgb(217, 217, 217)", height: 35 }}
            >
              <b>
                <Alert message="Edit Spot" type="default" />
              </b>
            </Col>
          </Row>
          <Divider></Divider>
          <Form layout={"vertical"} onSubmit={onEdit}>
            <SpotMap
              form={this.props.form}
              id="editSpotMap"
              value={spot.coordinates}
              area={this.props.area}
              spotId={spot.id}
            />
            <Row>
              <Col offset={3} span={18}>
                <Form.Item>
                  {getFieldDecorator(`name`, {
                    rules: [
                      {
                        required: true,
                        message: "Please enter spot name!"
                      }
                    ],
                    initialValue: spot.name
                  })(<Input placeholder="Spot name" name="name" />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(`vehicle_type_id`, {
                    rules: [
                      {
                        required: true,
                        message: "Please select vehicle type!",
                        showSearch: true
                      }
                    ],
                    initialValue: spot.spot_vehicle_type_id
                  })(
                    <Select
                      showSearch
                      placeholder="Select vehicle type"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.vehicle_types.map(vehicle_type => {
                        return (
                          <Option value={vehicle_type.id} key={vehicle_type.id}>
                            {vehicle_type.vehicle_cat}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Divider></Divider>
                <Form.Item wrapperCol={{ span: 6, offset: 18 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-right"
                    disabled={spotEditButton}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default Form.create({})(EditSpot);
