import customHttp from "../helpers/CustomHttp";
import { authHeader } from "../helpers/AuthHeader";

export const spotActions = {
  deleteSpot,
  showSpot,
  updateSpot
};

function deleteSpot(id) {
  const requestOptions = {
    method: "delete",
    headers: authHeader(),
    url: `/spots/${id}`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function showSpot(id) {
  const requestOptions = {
    method: "get",
    headers: authHeader(),
    url: `/spots/${id}`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function updateSpot(value, id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    url : `/spots/${id}`,
    data: value
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}
