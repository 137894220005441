import { Fill, Stroke, Style, Text } from "ol/style";

export const mapPolygonStyles = {
  getAvailableSpotStyle,
  getParkedSpotStyle,
  getBookedSpotStyle,
  getUnavailableSpotStyle,
  getHighlightSpotStyle
};

function getAvailableSpotStyle(vehicle_type) {
  return new Style({
    stroke: new Stroke({
      color: "rgb(93, 166, 15)",
      width: 1
    }),
    text: new Text({
      text: vehicle_type,
      font: "12px Calibri,sans-serif",
      fill: new Fill({
        color: "black"
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 3
      })
    }),
    fill: new Fill({
      color: "rgba(93, 166, 15,0.4)"
    })
  });
}

function getParkedSpotStyle(vehicle_type) {
  return new Style({
    stroke: new Stroke({
      color: "rgb(181, 118, 2)",
      width: 1
    }),
    text: new Text({
      text: vehicle_type,
      font: "12px Calibri,sans-serif",
      fill: new Fill({
        color: "black"
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 3
      })
    }),
    fill: new Fill({
      color: "rgba(181, 118, 2, 0.4)"
    })
  });
}

function getBookedSpotStyle(vehicle_type) {
  return new Style({
    stroke: new Stroke({
      color: "rgb(0, 0, 255)",
      width: 1
    }),
    text: new Text({
      text: vehicle_type,
      font: "12px Calibri,sans-serif",
      fill: new Fill({
        color: "black"
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 3
      })
    }),
    fill: new Fill({
      color: "rgba(0, 0, 255, 0.4)"
    })
  });
}

function getUnavailableSpotStyle(vehicle_type) {
  return new Style({
    stroke: new Stroke({
      color: "rgb(148, 1, 1)",
      width: 1
    }),
    text: new Text({
      text: vehicle_type,
      font: "12px Calibri,sans-serif",
      fill: new Fill({
        color: "black"
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 3
      })
    }),
    fill: new Fill({
      color: "rgba(148, 1, 1,0.4)"
    })
  });
}

function getHighlightSpotStyle(vehicle_type) {
  return new Style({
    stroke: new Stroke({
      color: "rgb(108, 109, 122)",
      width: 1
    }),
    text: new Text({
      text: vehicle_type,
      font: "12px Calibri,sans-serif",
      fill: new Fill({
        color: "black"
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 3
      })
    }),
    fill: new Fill({
      color: "rgba(108, 109, 122,0.6)"
    })
  });
}
