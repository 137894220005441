import React, { Component } from "react";
import { areaActions } from "../../actions/AreaActions";
import { Button, Row, Col, Form, Input} from "antd";
import Table from "./TableArea";
import Layout from "../Layout/Custom_Layout/Layout";
import LoadingSpinner from "../loadSpinner/loadSpinner";
import Create from "./CreateArea";
import { success } from "../../helpers/Notification";
import { isAdmin } from "../../helpers/userPolicy";
import { areaHelper } from "./AreaHelper";
import { getErrorMessages } from "../../helpers/getErrors";
import queryString from "query-string";
import { withRouter } from "react-router";
import { ReservationContext } from "../../contexts/ReservationContext";

const { Search } = Input;

class IndexArea extends Component {
  static contextType = ReservationContext;

  constructor() {
    super();
    this.state = {
      visible: false,
      createButton: false,
      isListItemLoaded: false,
      showSpinner: true,
      areas: [],
      totalAreas: 0
    };
    this.setCreateModalVisible = this.setCreateModalVisible.bind(this);
  }

  componentDidMount() {
    if(!isAdmin()){
      this.props.history.push("unauthorized")
    }
    this.fetchAreas(this.props.location.search);
    this.setFieldValue();
  }

  fetchAreas = (searchArea) => {
    areaActions.fetchAreas(searchArea, this.props.location.search.replace('?','')).then(response => {
      this.setState({ areas: response.data.areas, totalAreas: response.data.meta?.pagination.areas_length, isListItemLoaded: true, showSpinner: false });
    });
  }

  componentDidUpdate(nextProps){
     if (nextProps.location.search !== this.props.location.search){
      this.setState({showSpinner: true})
      this.fetchAreas(this.props.location.search);
      this.setFieldValue();
     }
  }

  setCreateModalVisible() {
    this.setState({ visible: true });
  }

  handleCreate = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ createButton: true });
        values["area_shape"] = areaHelper.formatValues(values["coordinates"]);
        delete values["coordinates"];
        if (values.spots_attributes) {
          values = areaHelper.prepareSpotShape(values);
        }
        areaActions.createArea(values).then(response => {
          if (response.status === 201) {
            this.fetchAreas(this.props.location.search)
            this.setState({ visible: false})
            success("Area has been sucessfully created.");
          } else {
            getErrorMessages(response.data.errors);
          }
          this.setState({ createButton: false });
        });
      }
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  showTable() {
    const { areas, showSpinner,totalAreas} = this.state;
    if (!showSpinner) {
      return <Table areas={areas} 
                    totalAreas={totalAreas}
                    fetchAreas={this.fetchAreas}
              />;
    } else {
      return <LoadingSpinner />;
    }
  }

  searchField = (form, fieldValue) => {
    const placeholder = fieldValue.replace("_", " ")
    const label = placeholder.charAt(0).toUpperCase() + placeholder.slice(1)
    return(
      <Col span = {4} offset = {3}>
        <Form.Item label={label}>
          {form.getFieldDecorator(fieldValue, {
            })(<Search
                placeholder={placeholder}
                onSearch={(value) => this.searchParams(value, fieldValue)}
                />
              )
          }
        </Form.Item>
      </Col>
    )
  }

  searchParams = (value, fieldValue) =>{
    let search = queryString.parse(this.props.location?.search);
    search['page'] = 1;
    if (value !== ""){
      search[fieldValue] = value;
    }
    else{
      delete search[fieldValue]
    }
    this.props.history.push(`/areas?${queryString.stringify(search)}`)
  }

  setFieldValue=()=>{
    var urlFieldValue = queryString.parse(this.props.location?.search);
    this.props.form.setFieldsValue({"area_name": urlFieldValue.area_name});
  }

  render() {
    const { form } = this.props;
    return (
      <>
      <Layout sidebarSelectedKey="area">
        {isAdmin() ? (
          <div>
            <Row style={{ padding: "1em 0 1em 0" }}>
              <Col offset={3} span={15}>
                <h5>Area Information</h5>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  onClick={() => this.setCreateModalVisible()}
                  className="btn-right"
                >
                  Create
                </Button>
              </Col>
            </Row>

            <Row>
              {this.searchField(form, "area_name")}
            </Row> 

            <Row>
              <Col offset={3} span={18}>
                {this.showTable()}
              </Col>
            </Row>
            {this.state.visible && (
              <Create
                wrappedComponentRef={this.saveFormRef}
                visible={this.state.visible}
                onCancel={this.handleCancel}
                onCreate={this.handleCreate}
                createButton={this.state.createButton}
                areas={this.context.areas}
              />
            )}
          </div>
        ) : null}
      </Layout>
      </>
    );
  }
}

export default withRouter(Form.create()(IndexArea));

