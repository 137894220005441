import Cookie from "js.cookie";
import customHttp from "../helpers/CustomHttp.js";
import authHeader from "../helpers/AuthHeader";
import { reservationActions } from "./ReservationActions";
import { isAdmin, isAccountant } from "../helpers/userPolicy";
import {endDate, startDate} from '../constants/InvoiceFilterConstants';

export const userActions = {
  register,
  login,
  logout,
  confirmations,
  fetchEmployees,
  deleteEmployee,
  updateEmployee,
  fetchUsers,
  createEmployee,
  fetchEmployeeAcToArea
};

function register(values, historyPush) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: values
  };
  return customHttp('/auth', requestOptions).then(response => {
    if (response?.data?.data) {
      setCookie(response, historyPush);
    }
    return response;
  });
}

function confirmations(values) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    url: 'overrides/confirmations',
    data: values
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function login(values, historyPush) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: values
  };

  return customHttp(`/auth/sign_in`, requestOptions).then(response => {
    setCookie(response, historyPush)
    return response;
  });
}

function logout() {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    url: `/auth/sign_out`
  };
  Cookie.remove("accesstoken", { path: "/" });
  Cookie.remove("client", { path: "/" });
  Cookie.remove("expiry", { path: "/" });
  Cookie.remove("role", { path: "/" });
  Cookie.remove("name", { path: "/" });
  return customHttp(requestOptions).then(response => {
    if (response.status === 200) {
      window.location.href = "/login";
    }
  });
}

function fetchEmployees(userRole) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    url : `/users/employees?user_role=${userRole}`
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function fetchEmployeeAcToArea() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    url : `users/employees_ac_to_area`
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function deleteEmployee(id) {
  const requestOptions = {
    method: 'delete',
    headers: authHeader(),
    url : `/users/${id}`,
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function updateEmployee(values, id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    url : `/users/${id}`,
    data: values
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function fetchUsers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    url : `/users`
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function createEmployee(values, historyPush) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    url : `/users`,
    data: values
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function setCookie(response, historyPush) {
  if (response.status === 200) {
    Cookie.set("accesstoken", response.headers["access-token"], {
      expires: 7,
      path: "/"
    });
    Cookie.set("client", response.headers["client"], {
      expires: 7,
      path: "/"
    });
    Cookie.set("expiry", response.headers["expiry"], {
      expires: 7,
      path: "/"
    });
    Cookie.set("uid", response.data.data["uid"], { expires: 7, path: "/" });
    Cookie.set("role", response.data.data["role"], { expires: 7, path: "/" });
    Cookie.set("name", response.data.data["name"], { expires: 7, path: "/" });
    authHeader(response);

    if (isAdmin()) {
      historyPush.push("/");
    }else if(isAccountant()) {
      historyPush.push(`/invoice?date_gteq=${startDate}&date_lteq=${endDate}&page=1`)
    }else {
      reservationActions.usersReservationCount().then(response => {
        if (response.status === 200 && response.data !== null) {
          historyPush.push("/reservations/user");
        } else {
          historyPush.push("/reservation/create");
        }
      });
    }
  }
}
