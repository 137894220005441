import Cookie from "js.cookie";

export const isUser = () => {
  return ["user"].includes(Cookie.get("role"));
};

export const isAdmin = () => {
  return ["admin"].includes(Cookie.get("role"));
};

export const isEmployee = () => {
  return ["employee"].includes(Cookie.get("role"));
};

export const isAccountant = () => {
  return ["accountant"].includes(Cookie.get("role"));
};

