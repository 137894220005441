import React, { Component, Fragment } from "react";
import { Table, Button, Modal, Divider, Icon } from "antd";
import { reservationActions } from "../../actions/ReservationActions";
import { success } from "../../helpers/Notification";
import { isAdmin, isEmployee } from "../../helpers/userPolicy";
import getDate from "../../helpers/getDate";
import getTime from "../../helpers/getTime";
import { getErrorMessages } from "../../helpers/getErrors"
import { ReservationContext } from "../../contexts/ReservationContext";
import QrImage from "./qrImage";
import Spinner from "../loadSpinner/loadSpinner";

const { confirm } = Modal;

class TableReservation extends Component {
  static contextType = ReservationContext;

  constructor(props) {
    super(props);
    this.state = {
      reserved: false,
      qrCodeData: {},
      parked: false
    };
  }

  showConfirm(reservation_id) {
    confirm({
      title: "Do you want to park this vehicle?",
      onOk: () => this.parkVehicle(reservation_id),
      onCancel() {}
    });
  }

  showCancelConfirm(reservationId) {
    confirm({
      title: "Do you want to cancel this reservation?",
      icon: <Icon type="delete" style={{ color: "red" }} />,
      onOk: () => this.cancelReservation(reservationId),
      onCancel() {},
    });
  }

  cancelReservation = (reservationId) => {
    reservationActions.cancelReservation(reservationId).then((response) => {
      if (response.status === 204) {
        this.props.fetchReservationData();
        this.context.setSpots(this.context.areaId, this.context.vehicleTypeId);
        success("Reservation canceled successfully.");
      } else {
        getErrorMessages(response.data.errors);
      }
    });
  };

  parkVehicle(id) {
    reservationActions.parkVehicle(id).then(response => {
      if (response.status === 201) {
        this.setState({ qrCodeData: response.data, parked: true})
        this.context.setSpots(this.context.areaId, this.context.vehicleTypeId);
        this.props.fetchReservationData();
        reservationActions.usersReservationCount()
        .then(response => {
            if (response.status === 200 && response.data !== null) {
              this.setState({ reserved: true });
            }
          });
        success("Parking status ON.");
      } else {
       getErrorMessages(response.data.errors);
      }
    });
  }

  handleQrcodeCancel = () => {
    this.setState({ parked: false})
  };

  render() {
    const { reservations, showSpinner } =  this.props;
    if (showSpinner){
      return (<Spinner/>)
    }else{
      const columns = [
        {
          title: "User Name",
          dataIndex: "user_name"
        },
        {
          title: "User Role",
          dataIndex: "user_role"
        },
        {
          title: "Area",
          dataIndex: "area_name"
        },
        {
          title: "Spot",
          dataIndex: "spot_name"
        },
        {
          title: "Vehicle Type",
          dataIndex: "vehicle_type.vehicle_cat"
        },
        {
          title: "Vehicle Number",
          dataIndex: "vehicle_no"
        },
        {
          title: "Rate",
          render: record => {
            return `Rs. ${record.hourly_rate}`
          }
        },
        {
          title: "Booked Time",
          render: record => {
            return record.book_timestamp
              ? getDate(record.book_timestamp) +
                  "  " +
                  getTime(record.book_timestamp)
              : "Directly Parked.";
          }
        },
        {
          title: "Action",
          key: "action",
          width: "300px",
          render: record => {
            return (
              <span>
                <Button
                  type="primary"
                  ghost
                  onClick={() =>
                    this.showConfirm(record.id)
                  }
                >
                Park
                </Button>
                <Divider type="vertical" />
                <Button
                  type="danger"
                  ghost
                  onClick={() =>
                    this.showCancelConfirm(record.id)
                  }
                >
                Cancel
                </Button>
              </span>
            );
          }
        }
      ];

      return (
        <Fragment>
          {isAdmin() || isEmployee() ? (
            <div>
              <Table
                columns={columns}
                dataSource={reservations}
                rowKey={column => column.id}
                bordered
              />
            </div>
          ) : null}
          {this.state.parked ? < QrImage qrCodeData = {this.state.qrCodeData} onCancel = {this.handleQrcodeCancel} /> : null }
        </Fragment>
      );
    } 
  }
}
export default TableReservation;
