import React, { Component } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Select,
  Input
} from "antd";
const {Option} = Select;

class EditInvoice extends Component {
  render() {
    const paymentMethod = ['unpaid' , 'cash', 'fonepay', 'bank']
    const { getFieldDecorator } = this.props.form
    const { visible, onCancel, invoiceId, onUpdate, payMethod, amount } = this.props
    return (
      <Modal
        title="Update Invoice"
        visible={visible}
        onOk={onUpdate}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
            <Row>
              <Col>
                <Form.Item>
                  {getFieldDecorator("payment_method", {
                    initialValue: payMethod,
                    rules: [
                      { required: true, message: "Please Enter Payment Method" }
                    ],
                  })(
                    <Select
                      showSearch
                      placeholder="Select Payment Method"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {paymentMethod.map(payMethod => {
                        return (
                          <Option value={payMethod} key={payMethod}>
                             {payMethod.charAt(0).toUpperCase() + payMethod.slice(1)}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  {
                    getFieldDecorator("total_payment" , {
                      initialValue: amount,
                      rules: [
                        { required: true, message: "Please Enter Total Amount" }
                      ],
                    })(
                      <Input
                        name="total_payment"
                        type="number"
                        min='0'
                      />
                    )}
                </Form.Item>
              </Col>

              <Col span = {0}>
                <Form.Item>
                  {
                    getFieldDecorator("id" , {
                      initialValue: invoiceId
                    })(
                      <Input
                        name="id"
                        readOnly
                        type= "hidden"
                      />
                    )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
      </Modal>
    );
  }
}

export default Form.create({})(EditInvoice);
