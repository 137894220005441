import customHttp from '../helpers/CustomHttp'
import { authHeader } from "../helpers/AuthHeader";

export const locationActions = {
  fetchLocation,
  createLocation, 
  showLocation,
  updateLocation,
  deleteLocation,
  fetchLocationAreas, 
}

function fetchLocation() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    url : `/locations`
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function createLocation(values) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    url : `/locations`,
    data: values
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function showLocation(id) {
  const requestOptions = {
    method: 'get',
    headers: authHeader(),
    url : `/locations/${id}`,
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function updateLocation(value, id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    url : `/locations/${id}`,
    data: value
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function deleteLocation(id) {
  const requestOptions = {
    method: 'put',
    headers: authHeader(),
    url : `/locations/${id}/deactivate_location`,
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function fetchLocationAreas(id) {
  const requestOptions = {
    method: 'get',
    headers: authHeader(),
    url : `/locations/${id}/areas/`,
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}
