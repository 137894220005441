import customHttp from "../helpers/CustomHttp";
import { authHeader } from "../helpers/AuthHeader";

export const reservationActions = {
  fetchReservation,
  createReservation,
  showReservation,
  updateReservation,
  cancelReservation,
  usersReservations,
  usersReservationCount,
  parkVehicle,
  getDirection
};

function fetchReservation() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    url: `/reservations`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function createReservation(values) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    url: `/reservations`,
    data: values
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function showReservation(id) {
  const requestOptions = {
    method: "get",
    headers: authHeader(),
    url: `/reservations/${id}`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function updateReservation(value, id) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    url: `/reservations/${id}`,
    data: value
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function cancelReservation(id) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    url: `/reservations/${id}/cancel_reservation`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function usersReservations() {
  const requestOptions = {
    method: "get",
    headers: authHeader(),
    url: `user_reservations`
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function usersReservationCount() {
  const requestOptions = {
    method: "get",
    headers: authHeader(),
    url: "user/parking-status"
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}

function parkVehicle(reserveId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    url: `/reservations/${reserveId}/park_vehicle`
  } 
  return customHttp(requestOptions).then(response => {
    return response;
  })
}

function getDirection(positionData) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
    url: `reservation_direction`,
    data: positionData
  };
  return customHttp(requestOptions).then(response => {
    return response;
  });
}
