import React, {Component, Fragment} from "react";
import { withRouter } from "react-router";
import Layout from "../Layout/Custom_Layout/Layout";
import { Row, Col } from "antd";
import { ReservationContext } from "../../contexts/ReservationContext";
import DisplayArea from "./DisplayArea";

class AreaList extends Component{
  static contextType = ReservationContext;

  render(){
    const { onlyAreaNames } = this.context
    return (
      <Layout sidebarSelectedKey="slip_record">
        <Fragment>
          <Row>
            <Col>
              <DisplayArea
                areaList = {onlyAreaNames}
              />
            </Col>
          </Row>
        </Fragment>
      </Layout>
    )
  }
}
export default withRouter(AreaList);