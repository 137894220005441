import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Media from "react-media";
import { reservationActions } from "../../actions/ReservationActions";
import Cookie from "js.cookie";
import { getErrorMessages } from "../../helpers/getErrors";
import Layout from "../Layout/Custom_Layout/Layout";
import {
  Card,
  Col,
  Row,
  Divider,
  Descriptions,
  Badge,
  Icon,
  Button,
  Modal
} from "antd";
import Message from "./ReservationSuccess";
import { ReservationContext } from "../../contexts/ReservationContext";
import { success } from "../../helpers/Notification";
import { isAdmin, isEmployee, isAccountant } from "../../helpers/userPolicy";
import DirectionMap from "../Map/DirectionMap";
import getDate from "../../helpers/getDate";
import getTime from "../../helpers/getTime";

const ButtonGroup = Button.Group;
const { confirm } = Modal;

class UserView extends Component {
  static contextType = ReservationContext;

  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      reservations: [],
      parkedVehicles: [],
      reserved: true,
      created: false,
      isDirectionModalVisisble: false,
      reservation: null
    };
    this.fetchReservationData = this.fetchReservationData.bind(this);
    this.fetchCurrentUserReservation = this.fetchCurrentUserReservation.bind(
      this
    );
  }

  componentDidMount() {
    if(!isAccountant()) {
      this.fetchReservationData();
    }else{
      this.props.history.push("/unauthorized")
    }
  }

  showConfirm(reservationId) {
    confirm({
      title: "Are you sure you want to cancel reservation?",
      onOk: () => this.cancelReservation(reservationId),
      onCancel() {}
    });
  }

  cancelReservation(reservationId) {
    reservationActions
      .cancelReservation(reservationId)
      .then(response => {
        if (response.status === 204) {
          success("Reservation Cancelled");
          this.context.setSpots(this.context.areaId, this.context.vehicleTypeId);
          this.setState({ reserved: false });
        } else {
          getErrorMessages(response.data.errors)
        }
      })
      .then(() => {
        this.fetchCurrentUserReservation();
      });
  }

  fetchReservationData() {
    reservationActions.usersReservationCount().then( async (response) => {
      if (
        response?.data[0]?.total_reservation === 0 &&
        response?.data[0]?.total_parked_vehicle === 0
      ) {
        this.setState({ reserved: false });
      } else {
        this.fetchCurrentUserReservation()
      }
    });
  }

  fetchCurrentUserReservation = () => {
    reservationActions.usersReservations().then(response => {
      this.setState({
        reservations: response.data.reservations,
        parkedVehicles: response.data.parked_vehicles
      });
    });
  };

  showDirection = reservation => {
    this.setState({
      isDirectionModalVisisble: true,
      reservation
    });
  };

  onCancel = () => {
    this.setState({
      isDirectionModalVisisble: false
    });
  };

  showParkingDetails = (matches, parkingData, status) => {
    return (
      <Col span={matches.large ? 12 : 24} key={parkingData.id}>
        <Card
          bordered={false}
          actions={[
            <div>
              <Button
                disabled={status}
                onClick={() => this.showConfirm(parkingData.id)}
              >
                <Icon type="setting" />
                Cancel Reservation
              </Button>
              <Button
                disabled={status}
                onClick={() => this.showDirection(parkingData)}
                style={
                  matches.small ? { marginTop: "1em" } : { marginLeft: "1em" }
                }
              >
                <Icon type="compass" />
                Get Direction
              </Button>
            </div>,
          ]}
        >
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Area" >
              {parkingData.area_name}
            </Descriptions.Item>
            <Descriptions.Item label="Spot" >
              {parkingData.spot_name}
            </Descriptions.Item>

            <Descriptions.Item label="Vehicle Type">
              {parkingData?.vehicle_type?.vehicle_cat ||
                parkingData.vehicle_cat}
            </Descriptions.Item>
            <Descriptions.Item label="Vehicle Number">
              {parkingData.vehicle_no}
            </Descriptions.Item>

            <Descriptions.Item label="Booked Time">
              {parkingData.book_timestamp &&
                getDate(parkingData.book_timestamp) +
                  " " +
                  getTime(parkingData.book_timestamp)}
            </Descriptions.Item>
            <Descriptions.Item label="Entry Time">
              {parkingData.entry_timestamp &&
                getDate(parkingData.entry_timestamp) +
                  " " +
                  getTime(parkingData.entry_timestamp)}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {!status ? (
                <Badge
                  status="processing"
                  text={"Booked by " + Cookie.get("name")}
                />
              ) : (
                <Badge
                  status="success"
                  text={"Parked by " + Cookie.get("name")}
                />
              )}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Divider />
      </Col>
    );
  }

  render() {
    const { reserved } = this.context;
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(matches) => (
          <Fragment>
            <Layout sidebarSelectedKey="reservation">
              <ButtonGroup className="btn-right">
                {isAdmin() || isEmployee() ? (
                  <Button>
                    <Link to="/reservations" style={{ textDecoration: "none" }}>
                      <span>Admin View</span>
                    </Link>
                  </Button>
                ) : null}
                {!this.state.reserved ? (
                  <Button type="primary">
                    <Link
                      to="/reservation/create"
                      style={{ textDecoration: "none" }}
                    >
                      <span>Create New</span>
                    </Link>
                  </Button>
                ) : null}
              </ButtonGroup>
              <div style={{ background: "#ECECEC", padding: "2em" }}>
                <span>
                  <h4>Your Reservations</h4>
                </span>
                <Row
                  gutter={16}
                  type="flex"
                  justify="space-around"
                  align="middle"
                >
                  {this.state.reservations.map((reservation) => {
                    return this.showParkingDetails(matches, reservation, false);
                  })}
                  {this.state.parkedVehicles.map((parkedVehcile) => {
                    return this.showParkingDetails(
                      matches,
                      parkedVehcile,
                      true
                    );
                  })}
                </Row>
              </div>

              {reserved && <Message />}
            </Layout>
            {this.state.isDirectionModalVisisble && (
              <DirectionMap
                className="map"
                reservation={this.state.reservation}
                visible={this.state.isDirectionModalVisisble}
                onCancel={this.onCancel}
                width={matches.small ? "100%" : "70%"}
              />
            )}
          </Fragment>
        )}
      </Media>
    );
  }
}

export default UserView;
