import React, { Component } from "react";
import { Modal, Descriptions, Tag } from "antd";

class ShowEmployee extends Component {
  render() {
    const { showVisible, onCancel, employee } = this.props;
    return (
      <Modal
        title=""
        centered
        visible={showVisible}
        onCancel={onCancel}
        footer={null}
      >
        <Descriptions title="Employee Information" bordered layout="vertical">
          <Descriptions.Item label="Name" span={1}>
            {employee.name}
          </Descriptions.Item>
          <Descriptions.Item label="Areas" span={2}>
            {employee.assigned_areas.map((assigned_area) => {
                return (
                  <Tag key={assigned_area.area_street} color="grey" style={{marginTop: 5}}>
                    {assigned_area.area_street}
                  </Tag>
                );
              })}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{employee.email}</Descriptions.Item>
        </Descriptions>
      </Modal>
    );
  }
}

export default ShowEmployee;
