import React, { Component } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import GeoJSON from "ol/format/GeoJSON";
import Feature from "ol/Feature";
import Geolocation from "ol/Geolocation";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import Point from "ol/geom/Point";
import Select from "ol/interaction/Select";
import { ReservationContext } from "../../contexts/ReservationContext";
import Overlay from "ol/Overlay";
import { getCenter } from "ol/extent";
import { mapPolygonStyles } from "../../helpers/MapPolygonStyles";

export class ReservationMap extends Component {
  static contextType = ReservationContext;

  constructor(props) {
    super(props);
    this.state = {
      areas: [],
      geolocation: null,
      spots: [],
      callback: true
    };
  }

  componentDidUpdate() {
    const { areas } = this.context
    if(this.state.callback && areas.length > 0) {
      this.setState({ callback: false });
      setTimeout(() => this.setMap(), 100);
    }
  }

  componentWillUnmount() {
    const {geolocation} = this.state
    if(geolocation){
      geolocation.setTracking(false);
    }
  }

  setMap = () => {
    var view = new View({
      center: [0, 0],
      zoom: 1,
      projection: "EPSG:4326"
    });

    var map = new Map({
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      target: "map",
      view: view
    });

    var geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        enableHighAccuracy: true
        // maximumAge: 10000
        // timeout: 5000,
      },
      projection: "EPSG:4326"
    });

    geolocation.on("error", function(error) {
      console.log(error.message);
    });

    var accuracyFeature = new Feature();
    geolocation.on("change:accuracyGeometry", function() {
      accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
    });

    var positionFeature = new Feature();
    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: "#3399CC"
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2
          })
        })
      })
    );
    var GPSLayer = new VectorLayer({
      source: new VectorSource({
        features: [positionFeature]
      })
    });
    map.addLayer(GPSLayer);

    var initialLoad = true;
    var changePosition = () => {
      var currentCoordinates = geolocation.getPosition();
      if (currentCoordinates !== this.context.currentPosition) {
        this.context.setCurrentPosition(currentCoordinates);
        var areas = this.context.setDistanceUsingCentroid(this.context.areas);
        this.context.sortAreaUsingDistance(areas);
        if (initialLoad && this.context.areaId === "") {
          map.getView().setCenter(currentCoordinates);
          map.getView().setZoom(14);
          initialLoad = false;
        }
        positionFeature.setGeometry(
          currentCoordinates ? new Point(currentCoordinates) : new Point([0, 0])
        );
      }
    };
    geolocation.on("change:position", changePosition);
    var getGeojsonConfig = () => {
      return {
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: {
            name: "EPSG:4326"
          }
        },
        features: []
      };
    };

    var geojsonObjectAreas = getGeojsonConfig();
    var geojsonObjectAvailableSpots = getGeojsonConfig();
    var geojsonObjectBookedSpots = getGeojsonConfig();
    var geojsonObjectParkedSpots = getGeojsonConfig();
    var geojsonObjectUnavailableSpots = getGeojsonConfig();

    var setFeature = (features, coordinates, id, spot_properties = {}) => {
      features.push({
        id,
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates
        },
        properties: spot_properties
      });
    };

    this.setState({
      geolocation: geolocation
    });
    this.context.areas.forEach(area => {
      setFeature(geojsonObjectAreas["features"], area.coordinates, area.id);
    });
    this.state.spots.forEach(spot => {
      var geojson = null;
      var antd_class = null;
      var spot_status = null;
      if (!spot.availability) {
        geojson = geojsonObjectUnavailableSpots;
        spot_status = "Unavailable";
        antd_class = "ant-tag ant-tag-red";
      } else if (spot.reservation?.status) {
        geojson = geojsonObjectBookedSpots;
        spot_status = "Booked";
        antd_class = "ant-tag ant-tag-blue";
      } else if (spot.parked_vehicle?.status) {
        geojson = geojsonObjectParkedSpots;
        spot_status = "Parked";
        antd_class = "ant-tag ant-tag-orange";
      } else {
        geojson = geojsonObjectAvailableSpots;
        spot_status = "Available";
        antd_class = "ant-tag ant-tag-green";
      }
      if (geojson) {
        setFeature(geojson["features"], spot.spot_coordinates, spot.id, {
          spot_id: spot.id,
          spot_name: spot.spot_name,
          spot_vehicle_type: spot.spot_vehicle_type,
          spot_rate: spot.spot_rate,
          area_id: spot.area_id,
          spot_status,
          antd_class,
          area_street: spot.area_street,
        });
      }
    });
    var areaSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObjectAreas)
    });
    var availableSpotSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObjectAvailableSpots)
    });
    availableSpotSource.getFeatures().forEach(feature => {
      feature.setStyle(
        mapPolygonStyles.getAvailableSpotStyle(
          feature.getProperties()["spot_vehicle_type"]
        )
      );
    });
    var bookedSpotSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObjectBookedSpots)
    });
    bookedSpotSource.getFeatures().forEach(feature => {
      feature.setStyle(
        mapPolygonStyles.getBookedSpotStyle(
          feature.getProperties()["spot_vehicle_type"]
        )
      );
    });
    var parkedSpotSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObjectParkedSpots)
    });
    parkedSpotSource.getFeatures().forEach(feature => {
      feature.setStyle(
        mapPolygonStyles.getParkedSpotStyle(
          feature.getProperties()["spot_vehicle_type"]
        )
      );
    });
    var unavailableSpotSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObjectUnavailableSpots)
    });
    unavailableSpotSource.getFeatures().forEach(feature => {
      feature.setStyle(
        mapPolygonStyles.getUnavailableSpotStyle(
          feature.getProperties()["spot_vehicle_type"]
        )
      );
    });

    var areaVector = new VectorLayer({
      source: areaSource,
      style: this.context.areaStyle,
      id: "areaLayer"
    });
    var availableSpotVector = new VectorLayer({
      source: availableSpotSource,
      id: "availableSpotLayer"
    });

    var bookedSpotVector = new VectorLayer({
      source: bookedSpotSource,
      id: "bookedSpotLayer"
    });

    var parkedSpotVector = new VectorLayer({
      source: parkedSpotSource,
      id: "parkedSpotLayer"
    });

    var unavailableSpotVector = new VectorLayer({
      source: unavailableSpotSource,
      id: "unavailableSpotLayer"
    });
    map.addLayer(areaVector);
    map.addLayer(availableSpotVector);
    map.addLayer(bookedSpotVector);
    map.addLayer(parkedSpotVector);
    map.addLayer(unavailableSpotVector);

    var selectSingleClick = new Select({});
    var popup = new Overlay({
      element: document.getElementById("popup")
    });
    map.addOverlay(popup);
    popup.setPosition(undefined);
    this.context.setPopup(popup);
    map.addInteraction(selectSingleClick);
    var onMapClick = e => {
      popup = this.context.popup;
      this.context.popup.setPosition(undefined);
      var feature = e.selected[0];
      selectSingleClick.getFeatures().remove(feature);
      if (feature.getProperties().spot_name) {
        var element = popup.getElement();
        var status =
          "<span style='font-size:16px;' class='" +
          feature.getProperties().antd_class +
          "'>" +
          feature.getProperties().spot_status +
          "</span>";
        var button = "";
        if (feature.getProperties().spot_status === "Available") {
          button =
            "<Button id='btnContinue' class='ant-btn ant-btn-primary ant-btn-background-ghost ant-btn-block' >Continue</Button></div>";
        }
        element.innerHTML =
          "<div><p><b>Name: </b>" +
          feature.getProperties().spot_name +
          "</p><p><b>Vehicle Type: </b> <span class='ant-tag ant-tag-blue'>" +
          feature.getProperties().spot_vehicle_type +
          "</span></p>" +
          "<p><b> Rate: </b> <span class='ant-tag ant-tag-geekblue'>Rs. " +
          feature.getProperties().spot_rate +
          "/hour</span></p>" +
          "<p><b> Status: </b>" +
          status +
          "</p>" +
          button;
        popup.setPosition(getCenter(feature.getGeometry().getExtent()));
        if (button !== "") {
          var buttonElement = document.getElementById("btnContinue");
          buttonElement.onclick = () => {
            this.context.onSpotClicked(feature.getProperties(), {
              area_id: feature.getProperties().area_id,
              area_street: feature.getProperties().area_street,
            });
          };
        }
      } else if (this.context.popup) {
        this.context.popup.setPosition(undefined);
      }
    };
    selectSingleClick.on("select", e => onMapClick(e));
    this.context.updateMap(map);
    if (this.context.areaId) {
      this.context.fitMap(this.context.areaId);
    }
  };

  render() {

    return (
      <div id="map" className="reservationMap">
        <div id="popup" className="ol-popup"></div>
      </div>
    );
  }
}

export default ReservationMap;
