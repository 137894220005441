import React, { Component } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Draw, Modify, Snap } from "ol/interaction";
import GeoJSON from "ol/format/GeoJSON";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Form, Input, Button, Row, Col } from "antd";
import { Fill, Stroke, Style } from "ol/style";

export class SpotMap extends Component {
  componentDidUpdate() {
    if (this.props.value) {
      setTimeout(() => this.setMap(), 50);
    }
  }

  setMap = (redraw = false) => {
    if (
      document.getElementById(this.props.id) !== null &&
      document.getElementById(this.props.id).innerHTML === ""
    ) {
      var coordinates = this.props.value;
      if (redraw) {
        this.setState({
          redraw: false
        });
        coordinates = undefined;
      }

      if (coordinates !== undefined) {
        this.props.form.setFieldsValue({
          coordinates: coordinates.toString()
        });
      }

      var area = this.props.area;

      var setCoordinate = newCoordinates => {
        this.props.form.setFieldsValue({
          coordinates: newCoordinates.toString()
        });
      };

      var raster = new TileLayer({
        source: new OSM()
      });

      var source = new VectorSource({ wrapX: false });
      if (coordinates !== undefined) {
        var geojsonObject = {
          type: "FeatureCollection",
          crs: {
            type: "name",
            properties: {
              name: "EPSG:4326"
            }
          },
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: coordinates
              }
            }
          ]
        };

        source = new VectorSource({
          features: new GeoJSON().readFeatures(geojsonObject)
        });
      }
      var vector = new VectorLayer({
        source: source
      });

      var geojsonObjectArea = {
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: {
            name: "EPSG:4326"
          }
        },
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: area.coordinates
            }
          }
        ]
      };
      var styleRestriction = new Style({
        stroke: new Stroke({
          color: "green",
          width: 1
        }),
        fill: new Fill({
          color: "rgba(247, 255, 248, 0)"
        })
      });

      var sourceArea = new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObjectArea)
      });

      var vectorLayerArea = new VectorLayer({
        source: sourceArea,
        style: styleRestriction
      });

      var geojsonObjectSpots = {
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: {
            name: "EPSG:4326"
          }
        },
        features: []
      };

      area.spots.forEach(spot => {
        if (this.props.spotId !== spot.id) {
          geojsonObjectSpots["features"].push({
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: spot.coordinates
            }
          });
        }
      });

      var spotsSource = new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObjectSpots)
      });

      var spotsStyle = new Style({
        stroke: new Stroke({
          color: "rgba(43, 24, 51)",
          width: 1
        }),
        fill: new Fill({
          color: "rgba(247, 255, 248, 0)"
        })
      });

      var vectorLayerSpots = new VectorLayer({
        source: spotsSource,
        zIndex: 9999,
        style: spotsStyle
      });

      var draw;
      var modify = new Modify({ source: source });

      var map = new Map({
        layers: [raster, vector, vectorLayerArea, vectorLayerSpots],
        target: this.props.id,
        view: new View({
          projection: "EPSG:4326",
          center: this.props.value[0][0],
          zoom: 16
        })
      });

      var extent = vectorLayerArea.getSource().getExtent();
      map.getView().fit(extent, { size: map.getSize() });

      map.addInteraction(modify);

      function addInteraction() {
        draw = new Draw({
          source: source,
          type: "Polygon",
          condition: function(e) {
            let features = map.getFeaturesAtPixel(e.pixel, {
              layerFilter: function(layer) {
                return layer === vectorLayerArea;
              }
            });

            let featuresArea = map.getFeaturesAtPixel(e.pixel, {
              layerFilter: function(layer) {
                return layer === vectorLayerSpots;
              }
            });

            if (
              features != null &&
              features.length > 0 &&
              featuresArea != null &&
              featuresArea.length <= 0
            ) {
              return true;
            } else {
              return false;
            }
          }
        });

        draw.on("drawend", function(e) {
          setCoordinate(e.feature.values_["geometry"]["flatCoordinates"]);
          map.removeInteraction(draw);
        });

        modify.on("modifyend", function(e) {
          setCoordinate(
            e.features.getArray()[0].values_["geometry"]["flatCoordinates"]
          );
        });

        map.addInteraction(draw);
        var snap = new Snap({ source: source });
        map.addInteraction(snap);
      }

      addInteraction();

      //disable new draw for edit
      if (coordinates !== undefined) {
        map.removeInteraction(draw);
      }
    }
  };

  redrawMap = () => {
    this.props.form.setFieldsValue({ coordinates: "" });
    document.getElementById(this.props.id).innerHTML = "";
    this.setMap(true);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Row>
          <Col offset={0} span={24}>
            <div id={this.props.id} className="map"></div>
          </Col>
        </Row>
        <Row>
          <Col offset={3} span={18}>
            <Form>
              <Form.Item>
                {getFieldDecorator("coordinates", {
                  rules: [
                    {
                      required: true,
                      message: "Please select Location on map!",
                      whitespace: true
                    }
                  ],
                  initialValue: this.props.value
                })(<Input id="coordinates" type="hidden" />)}
              </Form.Item>
            </Form>
          </Col>
          <Col>
            <Button onClick={() => this.redrawMap()} className="btn-right">
              Re-draw
            </Button>
          </Col>
          <br />
        </Row>
      </div>
    );
  }
}

export default Form.create({})(SpotMap);
