import React from "react";
import { Row, Col, Divider, Alert, Modal } from "antd";
import { AreaMap } from "../Map/AreaMap";

class ShowArea extends React.Component {
  render() {
    const { showVisible, onCancel, area } = this.props;
    return (
      <Modal
        title=""
        centered
        visible={showVisible}
        onCancel={onCancel}
        footer={null}
        width="67rem"
      >
        <div>
          <br />
          <Row>
            <Col
              offset={2}
              span={20}
              style={{ background: "rgb(217, 217, 217)", height: 35 }}
            >
              <b>
                <Alert message="Area Information" type="default" />
              </b>
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col offset={3} span={18}>
              <Alert
                message="Location"
                description={area.location}
                type="info"
                showIcon
              />
              <Divider></Divider>
              <Alert
                message="Area Name"
                description={area.street}
                type="info"
                showIcon
              />
              <Divider></Divider>
              <Row>
                <div>Total number of spots created: {this.props.area.total_spots}</div>
                <div style={{color: '#37C6FF'}}>Note: You can edit each spots by expanding the area table. If you wish to create a new spot just go to edit option in area table and draw a spot inside the area map you will get spot form below.</div>
              </Row>
            </Col>
          </Row>

          <Divider></Divider>
          <Row>
            <Col offset={1} span={22}>
              <AreaMap id="showAreaMap" area={area} fromShow={true} />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default ShowArea;
