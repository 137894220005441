import React, { Component } from "react";
import { Row, Col, Divider, Alert, Modal } from "antd";

class ShowVehicle extends Component {
  render() {
    const { showVisible, onCancel, vehicleType } = this.props;
    return (
      <Modal
        title=""
        centered
        visible={showVisible}
        onCancel={onCancel}
        footer={null}
      >
        <div>
          <br />
          <Row>
            <Col
              offset={2}
              span={20}
              style={{ background: "rgb(217, 217, 217)", height: 35 }}
            >
              <b>
                <Alert message="Vehicle Type Information" type="default" />
              </b>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col offset={3} span={18}>
              <Alert
                message="Vehicle Type"
                description={vehicleType.vehicle_cat}
                type="info"
                showIcon
              />
              <Divider />
              <Alert
                message="Rate"
                description={vehicleType.hourly_rate}
                type="info"
                showIcon
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col offset={1} span={10}>
            <Alert
                message="Left Icon"
                description={<img src={vehicleType.left_icon_url} alt="left icon" />}
                type="info"
                showIcon
              /> 
            </Col>
            <Col offset={1} span={10}>
            <Alert
                message="Right Icon"
                description={<img src={vehicleType.right_icon_url} alt="right icon" />}
                type="info"
                showIcon
              />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default ShowVehicle;
