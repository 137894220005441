import React, { Component } from "react";
import { Form, Icon, Input, Button } from "antd";
import Wrapper from "../Index";

import { userActions } from "../../../actions/UserActions";

let deferredPrompt;
// window.addEventListener("beforeinstallprompt", e => {
//   document.getElementById("home-button").style.visibility = "visible";
//   e.preventDefault();
//   deferredPrompt = e;
// });

class AdminLogin extends Component {
  constructor() {
    super();
    this.addToHomeScreen = this.addToHomeScreen.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        userActions.login(values, this.props.history);
      }
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  addToHomeScreen = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          document.getElementById("home-button").style.visibility = "hidden";
        } else {
          document.getElementById("home-button").style.visibility = "visible";
        }
        deferredPrompt = null;
      });
    }
  };

  addToHomeScreenButton = () => {
    return (
      <Button
        style={{ visibility: "hidden" }}
        id="home-button"
        onClick={this.addToHomeScreen}
      >
        Download
      </Button>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Wrapper>
        <div className="formWrapper">
          {this.addToHomeScreenButton()}

          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                  name="email"
                  onChange={this.handleChange}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={this.handleChange}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator("remember", {
                valuePropName: "checked",
                initialValue: true,
              })}
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  background: "black",
                  padding: "20px",
                  color: "yellow",
                  fontSize: "20px",
                  fontWeight: "bold",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "20px",
                }}
                block
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Wrapper>
    );
  }
}
export default Form.create({})(AdminLogin);
