import React, {Component} from 'react';
import {Modal, Button} from 'antd';
import {getTime} from '../../helpers/getTime';
import {getDate} from '../../helpers/getDate';
import htmlToImage from 'html-to-image';
import QRCode from 'qrcode.react';

class QrImage extends Component {
    downloadQR = () => {
        htmlToImage.toCanvas(document.getElementById('downloadPng')).then((canvas) => {  
          // const pngUrl = response
          //   .toDataURL("image/png")
          // var pdf = new jsPDF();
          // pdf.addImage(pngUrl, 'PNG', 1, 1);
          // pdf.save("download.pdf");
          const pngUrl = canvas.toDataURL("image/png")
                               .replace("image/png", "image/octet-stream");
          let downloadLink = document.createElement("a");
          downloadLink.href = pngUrl;
          downloadLink.download = `${this.props.qrCodeData.id}.png`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);  
        })      
    };
    render() {
      const {qrCodeData} = this.props
      return(
        <div>
          <Modal
              visible = {true}
              onCancel = {this.props.onCancel}
              footer = {null}
              width = {253}
              centered = {true}
          >
            <div id = "downloadPng" style = {{background: "#FFFFFF"}}>
              <br />
              <div style= {{color: 'black'}}><h5><center> {qrCodeData.parking_slip_detail?.slip_title || "Codyssey Nepal Parking"} </center></h5>
                <center>PAN No: {qrCodeData.parking_slip_detail?.pan_no}</center><br />
                <center><QRCode value = {qrCodeData.id} /></center>
                Entry Date: {getDate(qrCodeData.entry_timestamp)}<br />
                Entry Time: {getTime(qrCodeData.entry_timestamp)}<br />
                Rate: {qrCodeData.hourly_rate}<br />
                spot: {qrCodeData.spot_name}<br />
                Area: {qrCodeData.area_name}<br />
                Address: {qrCodeData.parking_slip_detail?.slip_full_address}<br />
                Vehicle Number: {qrCodeData.vehicle_no}<br />
                Vehicle Type: {qrCodeData.vehicle_cat}<br />
                <center >{qrCodeData.parking_slip_detail?.slip_footer_title || "Park at your own risk!"}</center>
              </div>
            </div>
            <Button style = {{display: 'block'}} onClick={this.downloadQR.bind(this)}>Download</Button>
          </Modal>

        </div>
      )
    }
}
export default QrImage
