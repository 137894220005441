import React, {Component} from "react";
import { Form, Input, Button} from "antd";
import Spinner from "../loadSpinner/loadSpinner";

class SlipDetailForm extends Component{
  render(){
    const { getFieldDecorator } = this.props.form
    const { areaId, buttonTitle, areaSlipDetail, handleSubmit, areaName, isLoading, handleChange, disableButton} = this.props
    return (
      <>
        { isLoading ? <Spinner /> :
          <div style={{width: '75%', float: 'right'}}>
              <h5>Slip Detail Form Of { areaName  }!</h5>
              <Form onSubmit= {handleSubmit} onChange={(values)=>handleChange(values)}>
                <Form.Item label="PAN No.">
                  {
                    getFieldDecorator("pan_no", {
                      initialValue: areaSlipDetail?.pan_no || "",
                  })(
                    <Input
                      name="pan_no"
                    />
                  )}
                </Form.Item>

                <Form.Item label="Slip Title">
                  {
                    getFieldDecorator("slip_title", {
                      initialValue: areaSlipDetail?.slip_title || "",
                  })(
                    <Input
                      name="slip_title"
                    />
                  )}
                </Form.Item>

                <Form.Item label="Footer Message">
                  {
                    getFieldDecorator("slip_footer_title", {
                      initialValue: areaSlipDetail?.slip_footer_title || ""
                  })(
                    <Input
                      name="slip_footer_title"
                    />
                  )}
                </Form.Item>

                <Form.Item label="Invoice Title">
                  {
                    getFieldDecorator("invoice_title", {
                      initialValue: areaSlipDetail?.invoice_title || "",
                  })(
                    <Input
                      name="invoice_title"
                    />
                  )}
                </Form.Item>

                <Form.Item label="Invoice Footer">
                  {
                    getFieldDecorator("invoice_footer", {
                      initialValue: areaSlipDetail?.invoice_footer || "",
                  })(
                    <Input
                      name="invoice_footer"
                    />
                  )}
                </Form.Item>

                <Form.Item label="Full Address">
                  {
                    getFieldDecorator("slip_full_address", {
                      initialValue: areaSlipDetail?.slip_full_address || ""
                  })(
                    <Input
                      name="slip_full_address"
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  {
                    getFieldDecorator("area_id" , {
                      initialValue: areaId
                    })(
                      <Input
                        name="rate"
                        readOnly
                        type="hidden"
                      />
                    )}
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={disableButton}>
                    {buttonTitle}
                  </Button>
                </Form.Item>
              </Form>
          </div>
        }
      </>
    )
  }
}
export default Form.create({})(SlipDetailForm);