import React, { Component, Fragment } from "react";
import Media from "react-media";
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  Icon,
  Tooltip,
  Row,
  Col,
  Divider,
  Alert,
  Switch,
  Badge
} from "antd";
import { vehicleTypeActions } from "../../actions/VehicleTypeActions";
import { AreaMap } from "../Map/AreaMap";
const { Option } = Select;

class CreateArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle_types: [],
      spotCoordinates: [],
      spotCount: [],
      spotsForm: [],
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    vehicleTypeActions.fetchVehicleTypes().then(response => {
      this.setState({ vehicle_types: response.data });
    });
  }

  addSpot = (spotCoordinates) => {
    const spotsForm = this.state.spotsForm;
    let spotCount = this.state.spotCount;
    const newCount = spotsForm.length > 0 ?( spotsForm[spotsForm.length-1] + 1) : 0;
    spotCount.push(newCount);
    spotsForm.push(newCount);
    this.setState({spotCount, spotsForm, spotCoordinates: [...this.state.spotCoordinates, spotCoordinates.toString()]});
  };

  deleteSpot = k => {
    const spotCount = this.state.spotCount.filter(count => count !== k)
     this.setState({
      spotCount
    });
    this.child.removeSpotDraw(`spot-${k}`);
  };

  deleteDrawnSpots = () => {
    this.setState({
      spotCount : []
    });
  };

  render() {
    const { visible, onCancel, onCreate, createButton } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)"
        }}
      >
        {matches => (
          <Fragment>
            <Modal
              title=""
              centered
              visible={visible}
              onCancel={onCancel}
              footer={null}
              width={matches.medium ? "54em" : "75em"}
            >
              <div>
                <br />
                <Row>
                  <Col
                    offset={2}
                    span={20}
                    style={{
                      background: "rgb(217, 217, 217)",
                      height: "2.5em"
                    }}
                  >
                    <b>
                      <Alert message="Add New Area" type="default" />
                    </b>
                  </Col>
                </Row>
                <Divider></Divider>
                <Form layout={"vertical"} onSubmit={onCreate}>
                  <AreaMap
                    form={this.props.form}
                    id="createAreaMap"
                    fromCreate={true}
                    addSpot={this.addSpot}
                    deleteDrawnSpots={this.deleteDrawnSpots}
                    ref={instance => {
                      this.child = instance;
                    }}
                    areas={this.props.areas}
                  />
                  <Row>
                    <Col offset={3} span={18}>
                      <Form.Item
                        label={
                          <span>
                            Area Name&nbsp;
                            <Tooltip title="Name of the Area according to Location">
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                        }
                      >
                        {getFieldDecorator("street", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter your Area Name!",
                              whitespace: true
                            }
                          ]
                        })(
                          <Input
                            placeholder={"Enter your Area Name"}
                            name="street"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={3} span={18}>
                      <Form.Item
                        label={
                          <span>
                            Clean up?&nbsp;
                            <Tooltip title="Do you want to delete parked vehicles which is not departed?">
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                        }
                      >
                        {getFieldDecorator("cleanup_period", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter cleanup period!"
                            },
                          ],
                          initialValue: 0
                        })(
                          <Input
                            placeholder={"Enter cleanup period!"}
                            name="cleanup_period"
                            type='number'
                            min="0"
                            addonAfter= {"Days"}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={3} span={18}>
                      <Row>
                        {this.state.spotCount.map(k => {
                          return (
                            <Col
                              offset={1}
                              span={matches.small ? 22 : 5}
                              key={k}
                            >
                              <div
                                style={{
                                  border: "1px solid grey",
                                  padding: "0.3em",
                                  marginTop: "0.5em"
                                }}
                              >
                                <Row>
                                  <Col span={1}>
                                    <p>
                                      <Badge
                                        count={k + 1}
                                        style={{
                                          backgroundColor: "#fff",
                                          color: "#7d7d7d",
                                          boxShadow: "0 0 0 1px #7d7d7d inset",
                                          margin: "0 auto"
                                        }}
                                      />
                                    </p>
                                  </Col>
                                  <Col span="0">
                                    <Form.Item>
                                      {getFieldDecorator(
                                        `spots_attributes[${k}]spotCoordinates`,
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message: "Please draw spot on map"
                                            }
                                          ],
                                          initialValue: this.state
                                            .spotCoordinates[k]
                                        }
                                      )(
                                        <Input
                                          type="hidden"
                                          name="spotCoordinates"
                                        />
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <Button
                                      type="danger"
                                      size="small"
                                      ghost
                                      onClick={() => this.deleteSpot(k)}
                                      style={{ float: "right" }}
                                    >
                                      <Icon type="minus" />
                                    </Button>
                                  </Col>
                                </Row>

                                <Form.Item>
                                  {getFieldDecorator(
                                    `spots_attributes[${k}]name`,
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: "Please enter spot name!"
                                        }
                                      ]
                                    }
                                  )(
                                    <Input
                                      placeholder="Spot name"
                                      name="name"
                                    />
                                  )}
                                </Form.Item>
                                <Form.Item>
                                  {getFieldDecorator(
                                    `spots_attributes[${k}]vehicle_type_id`,
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message:
                                            "Please select vehicle type!",
                                          showSearch: true
                                        }
                                      ]
                                    }
                                  )(
                                    <Select
                                      showSearch
                                      placeholder="Select vehicle type"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.vehicle_types.map(
                                        vehicle_type => {
                                          return (
                                            <Option
                                              value={vehicle_type.id}
                                              key={vehicle_type.id}
                                            >
                                              {vehicle_type.vehicle_cat}
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select>
                                  )}
                                </Form.Item>

                                <Form.Item label= "Reservable:" style={{left: "0.5rem"}}>
                                  {getFieldDecorator(
                                    `spots_attributes[${k}]is_reservable`,
                                  )(
                                    <Switch size="small" style={{top: "-2.5rem", left: "5rem"}}
                                      defaultChecked  
                                      checkedChildren={<Icon type="check" />}
                                      unCheckedChildren={<Icon type="close" />}
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Divider></Divider>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-right"
                          disabled={createButton}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
          </Fragment>
        )}
      </Media>
    );
  }
}
export default Form.create({})(CreateArea);
