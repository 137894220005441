import React, { Component } from "react";
import { Table, Divider, Tooltip, Icon, Modal, Form } from "antd";
import { Link } from "react-router-dom";
import ShowBoard from "../Board/ShowBoard";
import EditBoard from "./EditBoard";
import { boardActions } from "../../actions/BoardActions";
import { getErrorMessages } from "../../helpers/getErrors";

const { confirm } = Modal;

class TableBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editBoardVisible: false,
      showBoardVisible: false,
      board: {},
      spotCounts: {},
      isLoading: false
    };
  }

  showConfirm(board) {
    confirm({
      title: "Do you want to delete this board?",
      content: "Board Name: " + board.name,
      onOk: () => this.props.onDelete(board),
      onCancel() {},
    });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  setShowModalVisible = (board) => {
    this.setState({ 
      showBoardVisible: true,
      isLoading: true
    });
    boardActions.showBoard(board.id).then((response)=> {
      if (response.status === 200){
        this.setState({ 
          board: response.data.board.board,
          spotCounts: response.data.spot_counts[0],
          isLoading: false
        })
      }
    })
  };

  setEditModalVisible = (board) => {
    boardActions.showBoard(board.id).then((response)=> {
      if (response.status === 200){
        this.setState({ 
          editBoardVisible: true,
          board: response.data.board.board
        })
      }
    })
  };

  handleCancelShow = () => {
    this.setState({ showBoardVisible: false });
  };

  handleCancelEdit = () => {
    this.setState({ editBoardVisible: false });
  };
  
  handleboardEdit = () => {
    boardActions.showBoard(this.state.board.id).then((response)=> {
      if (response.status === 200) {
        this.setState({ 
          editBoardVisible: false,
        })
        this.props.updateBoards(response.data.board.board);
      }else{
        getErrorMessages(response.data.errors)
        this.setState({ editBoardVisible: false })
      }
    })
   };

  tableColumns = () => {
    return [
      {
        title: "Board Name",
        dataIndex: "name",
        width: "40%",
      },
      {
        title: "Installed Phone No.",
        dataIndex: "phone_number",
        width: "25%" ,
      },

      {
        title: "Action",
        key: `boardAction`,
        width: "40%",
        render: (record) => {
          return (
            <div>
              <span>
                <Tooltip title="View Board Details">
                  <Link onClick={() => this.setShowModalVisible(record)}>
                    <Icon type="eye" />
                  </Link>
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Edit Board Details">
                  <Link onClick={() => this.setEditModalVisible(record)}>
                    <Icon type="edit" />
                  </Link>
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Delete Board">
                  <Link onClick={() => this.showConfirm(record)}>
                    <Icon type="delete" />
                  </Link>
                </Tooltip>
              </span>
            </div>
          );
        },
      },
    ];
  };

  render() {
    const { boards } = this.props;
    return (
      <div>
        <Table
          columns={this.tableColumns}
          bordered
          dataSource={boards}
          columns={this.tableColumns()}
          rowKey={boards.id}
        />
        {this.state.showBoardVisible && (
          <ShowBoard
            showBoardVisible={this.state.showBoardVisible}
            onCancel={this.handleCancelShow}
            board={this.state.board}
            spotCounts={this.state.spotCounts}
            isLoading={this.state.isLoading}
          />
        )}
        {this.state.editBoardVisible && (
          <EditBoard
            wrappedComponentRef={this.saveFormRef}
            editBoardVisible={this.state.editBoardVisible}
            onEdit={this.handleboardEdit}
            onCancel={this.handleCancelEdit}
            board={this.state.board}
          />
        )}
      </div>
    );
  }
}
export default Form.create({})(TableBoard);
