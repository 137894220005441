import customHttp from "../helpers/CustomHttp";
import { authHeader } from "../helpers/AuthHeader";

export const notificationActions = {
  showNotification,
  createNotification,
  pushNotification,
  updateNotification
}

function showNotification(areaId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    url: `/notifications?area_id=${areaId}`
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function createNotification(values){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    url: '/notifications',
    data: values
  }
  return customHttp(requestOptions).then(response =>{
    return response
  })
}

function pushNotification(notificationId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    url: `/notifications/${notificationId}/push_notification`
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function updateNotification(notificationId, values) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    url: `/notifications/${notificationId}`,
    data: values
  }
  return customHttp(requestOptions).then(response =>{
    return response
  })
}
