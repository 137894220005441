import React, {Component} from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Button,
  Icon,
  Select} from "antd";
import { ReservationContext } from "../../contexts/ReservationContext";

const {Option} = Select;
class EditRate extends Component{
  static contextType = ReservationContext;
  render(){
    const { visible, onSubmit, onCancel, title, areaId, vechicleTypeDisabled, countForm, handleVariableRates, removeRateForm } = this.props
    const vehicleTypeRate = this.props.vehicleTypeRate || ""
    const {variable_rates} = vehicleTypeRate
    const { getFieldDecorator } = this.props.form
    const { vehicleTypes } = this.context
    return(
      <div>
        <Modal
          title= <h5>{title} </h5>
          visible={visible}
          onCancel={onCancel}
          onOk={onSubmit}
          okText={title}
        >
        <Form layout={"vertical"}>
          <Row>
            <Col>
              <Form.Item>
                {getFieldDecorator(`vehicle_type_id`, {
                  rules: [
                    { required: true, message: "Please Enter Vehicle Type" }
                  ],
                  initialValue: vehicleTypeRate.vehicle_type_id
                })(
                  <Select
                    showSearch
                    placeholder="Select Vehicle Type"
                    optionFilterProp="children"
                    disabled = {vechicleTypeDisabled}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {vehicleTypes.map(vehicleType => {
                      return (
                        <Option value={vehicleType.id} key={vehicleType.id}>
                          {vehicleType.vehicle_cat}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={12} style={{paddingRight: "1%"}}>
              <Form.Item
                label={
                  <span>Charging Period:</span>
                }
              >
                {
                  getFieldDecorator("charging_period", {
                  initialValue: vehicleTypeRate.charging_period || 60
                })(
                  <Input
                    placeholder="Enter charging period minutes."
                    type="number"
                    name="charging_period"
                    addonBefore={"Time"}
                    addonAfter= {"in minute"}
                    min='1'
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={12} style={{paddingLeft: "1%"}}>
              <Form.Item
                label={
                  <span>Charging Period Rate:</span>
                }
              >
                {
                  getFieldDecorator("hourly_rate", {
                  initialValue: vehicleTypeRate.hourly_rate || 0
                })(
                  <Input
                    placeholder="Enter charging period rate."
                    type="number"
                    name="hourly_rate"
                    addonBefore={"NRP"}
                    min='0'
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={12} style={{paddingRight: '1%'}}>
              <Form.Item
                label={
                  <span>Grace Period:</span>
                }
              >
                {
                  getFieldDecorator("grace_period", {
                  initialValue: vehicleTypeRate.grace_period || 0
                })(
                  <Input
                    placeholder="Enter grace period."
                    type="number"
                    name="grace_period"
                    addonBefore={"Time"}
                    addonAfter= {"in minute"}
                    min='1'
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={12} style={{paddingLeft: '1%'}}>
              <Form.Item
                label={
                  <span>Fixed Surcharge:</span>
                }
              >
                {
                  getFieldDecorator("fixed_surcharge", {
                  initialValue: vehicleTypeRate.fixed_surcharge || 0
                })(
                  <Input
                    placeholder="Enter fixed surcharge."
                    type="number"
                    name="fixed_surcharge"
                    addonBefore={"NRP"}
                    min='0'
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          {
            countForm.map((k) => {
              return(
                  <Row className="rate-form">
                    <Col>
                      <Button
                        type="danger"
                        size="small"
                        ghost
                        onClick={() => removeRateForm(k)}
                        style={{ float: "right", zIndex: 8}}
                      >
                        <Icon type="minus" />
                      </Button>
                    </Col>

                    <Col>
                      <Form.Item
                        label={
                          <span>
                            Limit In Seconds:
                          </span>
                        }
                      >
                        {
                          getFieldDecorator(`variable_rates[${k}]limit_in_seconds`, {
                            rules: [
                              { required: true, message: "Please Enter Limit In Seconds." }
                            ],
                          initialValue: variable_rates ? variable_rates[k]?.limit_in_seconds : ''
                        })(
                          <Input
                            placeholder="Enter limit in seconds."
                            type="number"
                            name="limit_in_seconds"
                            addonBefore={"Time"}
                            addonAfter= {"in seconds"}
                            min='1'
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={12} style={{paddingRight: "1%"}}>
                      <Form.Item
                        label={
                          <span>Charging Period Minutes:</span>
                        }
                      >
                        {
                          getFieldDecorator(`variable_rates[${k}]charging_period_minutes`, {
                            rules: [
                              { required: true, message: "Please Enter Charging Period Minutes." }
                            ],
                          initialValue: variable_rates ? variable_rates[k]?.charging_period_minutes : '' || 60
                        })(
                          <Input
                            placeholder="Enter charging period."
                            type="number"
                            name="charging_period"
                            addonBefore={"Time"}
                            addonAfter= {"in minute"}
                            min='1'
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={12} style={{paddingLeft: "1%"}}>
                      <Form.Item
                        label={
                          <span>Charging Period Rate:</span>
                        }
                      >
                        {
                          getFieldDecorator(`variable_rates[${k}]charging_period_rate`, {
                            rules: [
                              { required: true, message: "Please Enter Charging Period Rate." }
                            ],
                          initialValue: variable_rates ? variable_rates[k]?.charging_period_rate : ''
                        })(
                          <Input
                            placeholder="Enter Charging Period Rate."
                            name="rate"
                            addonBefore={"NPR"}
                            type="number"
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={12} style={{paddingRight: "1%"}}>
                      <Form.Item
                        label={
                          <span>Fixed Surcharge:</span>
                        }
                      >
                        {
                          getFieldDecorator(`variable_rates[${k}]fixed_surcharge`, {
                          initialValue: variable_rates ? variable_rates[k]?.fixed_surcharge : '' || 0
                        })(
                          <Input
                            placeholder="Enter Fixed Surcharge."
                            name="rate"
                            type="number"
                            addonBefore={"NPR"}
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={12} style={{paddingLeft: "1%"}}>
                      <Form.Item
                        label={
                          <span>Grace Period:</span>
                        }
                      >
                        {
                          getFieldDecorator(`variable_rates[${k}]grace_period`, {
                          initialValue: variable_rates ? variable_rates[k]?.grace_period : '' || 0
                        })(
                          <Input
                            placeholder="Enter Grace Period."
                            type="number"
                            min="0"
                            name="grace_period"
                            addonBefore={"Time"}
                            addonAfter= {"in minute"}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
              )
            })
          }
          <Button
            type="primary"
            onClick={handleVariableRates}
          > Add Variable Rate
          </Button>

            <Col span = {0}>
              <Form.Item>
                {
                  getFieldDecorator("area_id" , {
                    initialValue: areaId
                  })(
                    <Input
                      name="rate"
                      readOnly
                      type= "hidden"
                    />
                  )}
              </Form.Item>
            </Col>
        </Form>
        </Modal>
      </div>
    )
  }
}
export default Form.create({})(EditRate);