import React, { Component, createContext } from "react";
import { locationActions } from "../actions/LocationActions";
import { areaActions } from "../actions/AreaActions";
import { vehicleTypeActions } from "../actions/VehicleTypeActions";
import { Fill, Stroke, Style } from "ol/style";
import { spotActions } from "../actions/SpotActions";
import { getDistance } from "ol/sphere";
import { mapPolygonStyles } from "../helpers/MapPolygonStyles";

export const ReservationContext = new createContext();

class ReservationContextProvider extends Component {
  constructor() {
    super();
    this.state = {
      locations: [],
      vehicleTypes: [],
      areas: [],
      spots: [],
      locationId: "",
      areaId: "",
      vehicleTypeId: "all",
      userId: "",
      onlyAreaNames: [],
      showSpinner: true,
      allReady: false,
      visible: false,
      reserved: false,
      totalAreas: 0,
      map: null,
      spot: null,
      popup: null,
      locationStyle: new Style({
        fill: new Fill({
          color: "rgba(43, 132, 135,0.1)"
        }),
        stroke: new Stroke({
          color: "#2b8487",
          width: 1
        })
      }),
      areaStyle: new Style({
        fill: new Fill({
          color: "rgba(120, 66, 130,0.1)"
        }),
        stroke: new Stroke({
          color: "rgb(120, 66, 130)",
          width: 1
        })
      }),
      highlightStyle: new Style({
        stroke: new Stroke({
          color: "rgb(108, 109, 122)",
          width: 1
        }),
        fill: new Fill({
          color: "rgba(108, 109, 122,0.6)"
        })
      }),
      currentPosition: null,
      locationName: "",
      areaStreet: "",
      filterForm: null,
      isListItemLoaded: false,
      directParked: false,
      directParkedData: {}
    };
  }

  componentDidMount() {
    this.fetchAreas()

    vehicleTypeActions.fetchVehicleTypes().then(response => {
      this.setState({ vehicleTypes: response.data });
    });

    areaActions.fetchAreasStreets().then(response =>{
      this.setState({
        onlyAreaNames: response.data,
        showSpinner: false
      })
    })
  }

  fetchAreas = () => {
    areaActions.fetchAreas().then(response => {
      this.setState({ areas: response.data, isListItemLoaded: true, showSpinner: false });
    });
  }

  onAreaChange = (areaId, e, areaStreet) => {
    this.setState({
      isListItemLoaded: false
    });
    this.state.popup.setPosition(undefined);
    this.fitMap(areaId);
    this.setState({
      areaId,
      allReady: true,
      areaStreet: areaStreet || e.props.children
    });
    this.setSpots(areaId, this.state.vehicleTypeId);
    this.resetAreaHighlight();
  };

  onTypeChange = vehicleTypeId => {
    this.setState({ vehicleTypeId: vehicleTypeId });
    if (this.state.areaId) {
      this.setState({ allReady: true, isListItemLoaded: false });
      this.setSpots(this.state.areaId, vehicleTypeId);
    }
  };

  setLocationId = (locationId, locationName) => {
    this.setState({
      isListItemLoaded: false
    });
    this.fitMap(locationId);
    if (this.state.popup) {
      this.state.popup.setPosition(undefined);
    }
    this.setState({ areaId: "", allReady: false });
    locationActions.fetchLocationAreas(locationId).then(response => {
      var areas = this.setDistanceUsingCentroid(response.data);
      this.setState({
        areas,
        locationId,
        locationName,
        allReady: false,
        isListItemLoaded: true
      });
      this.resetLocationHighlight();
    });
  };

  setDistanceUsingCentroid = items => {
    if (this.state.currentPosition) {
      items.forEach((item, index) => {
        items[index]["distance"] = Math.round(
          getDistance(this.state.currentPosition, item.centroid, 6378137)
        );
      });
    }
    return items;
  };

  setSpots = (areaId, vehicleTypeId) => {
    areaActions.fetchAreaSpots(areaId).then(response => {
      var spots = this.setDistanceUsingCentroid(response.data);
      this.resetSpotsOfArea(areaId, spots, vehicleTypeId)
    });
  };

  resetSpotsOfArea = (areaId, spots, vehicleTypeId) => {
    let newAreas = this.state.areas.map((area) => {
      if (area.id === areaId) {
        area.spots = spots;
      }
      return area;
    });
    if (vehicleTypeId !== "all") {
      spots = spots.filter(
        (spot) => spot.spot_vehicle_type_id === vehicleTypeId
      );
    }
    this.setState({ areas: newAreas, spots, isListItemLoaded: true });
  };

  onSpotClicked = (spot, spotFromMap = null) => {
    if (spotFromMap) {
      if (this.state.areaId !== spotFromMap.area_id) {
        this.setState({
          isListItemLoaded: false,
          areaId: spotFromMap.area_id,
          areaStreet: spotFromMap.area_street
        });
        this.setSpots(spotFromMap.area_id, this.state.vehicleTypeId);
      }
    }
    this.setState({ spot: spot, allReady: true, visible: true });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  onReversed = () => {
    this.setState({
      reserved: !this.state.reserved
    });
    this.setSpots(this.state.areaId, this.state.vehicleTypeId);
  };

  updateMapSize = () => {
    if (this.state.map) {
      var map = this.state.map;
      setTimeout(function() {
        map.updateSize();
      }, 200);
    }
  };

  fitMap = featureId => {
    var allLayers = this.state.map.getLayers().getArray();
    for (var i = 1; i < allLayers.length; i++) {
      var feature = allLayers[i].getSource().getFeatureById(featureId);
      if (feature !== null) {
        var extent = feature.getGeometry().getExtent();
        var map = this.state.map;
        map.getView().fit(extent);
        this.setState({
          map
        });
      }
    }
  };

  resetSpotHighlight = () => {
    var allLayers = this.state.map.getLayers().getArray();

    for (var i = 1; i < allLayers.length; i++) {
      if (allLayers[i].values_["id"] === "availableSpotLayer") {
        this.setStyleToAllSpotFeaturesOfLayer(
          allLayers[i],
          mapPolygonStyles.getAvailableSpotStyle
        );
      } else if (allLayers[i].values_["id"] === "parkedSpotLayer") {
        this.setStyleToAllSpotFeaturesOfLayer(
          allLayers[i],
          mapPolygonStyles.getParkedSpotStyle
        );
      } else if (allLayers[i].values_["id"] === "bookedSpotLayer") {
        this.setStyleToAllSpotFeaturesOfLayer(
          allLayers[i],
          mapPolygonStyles.getBookedSpotStyle
        );
      } else if (allLayers[i].values_["id"] === "unavailableSpotLayer") {
        this.setStyleToAllSpotFeaturesOfLayer(
          allLayers[i],
          mapPolygonStyles.getUnavailableSpotStyle
        );
      }
    }
  };

  highlightSpot = spotId => {
    var allLayers = this.state.map.getLayers().getArray();
    var hoveredFeature = null;
    for (var i = 1; i < allLayers.length; i++) {
      if (
        allLayers[i].values_["id"] === "availableSpotLayer" ||
        allLayers[i].values_["id"] === "bookedSpotLayer" ||
        allLayers[i].values_["id"] === "parkedSpotLayer" ||
        allLayers[i].values_["id"] === "unavailableSpotLayer"
      ) {
        hoveredFeature = allLayers[i].getSource().getFeatureById(spotId);
        if (hoveredFeature) {
          hoveredFeature.setStyle(
            mapPolygonStyles.getHighlightSpotStyle(
              hoveredFeature.getProperties()["spot_vehicle_type"]
            )
          );
          break;
        }
      }
    }
  };

  resetLocationHighlight = () => {
    var allLayers = this.state.map.getLayers().getArray();
    for (var i = 1; i < allLayers.length; i++) {
      if (allLayers[i].values_["id"] === "locationLayer") {
        this.setStyleToAllFeaturesOfLayer(
          allLayers[i],
          this.state.locationStyle
        );
        break;
      }
    }
  };

  highlightLocation = locationId => {
    if (this.state.map) {
      var allLayers = this.state.map.getLayers().getArray();
      var hoveredFeature = null;
      for (var i = 1; i < allLayers.length; i++) {
        if (allLayers[i].values_["id"] === "locationLayer") {
          hoveredFeature = allLayers[i].getSource().getFeatureById(locationId);
          if (hoveredFeature) {
            hoveredFeature.setStyle(this.state.highlightStyle);
            break;
          }
        }
      }
    }
  };

  resetAreaHighlight = () => {
    var allLayers = this.state.map.getLayers().getArray();
    for (var i = 1; i < allLayers.length; i++) {
      if (allLayers[i].values_["id"] === "areaLayer") {
        this.setStyleToAllFeaturesOfLayer(allLayers[i], this.state.areaStyle);
        break;
      }
    }
  };

  highlightArea = areaId => {
    if (this.state.map) {
      var allLayers = this.state.map.getLayers().getArray();
      var hoveredFeature = null;
      for (var i = 1; i < allLayers.length; i++) {
        if (allLayers[i].values_["id"] === "areaLayer") {
          hoveredFeature = allLayers[i].getSource().getFeatureById(areaId);
          if (hoveredFeature) {
            hoveredFeature.setStyle(this.state.highlightStyle);
            break;
          }
        }
      }
    }
  };

  setStyleToAllSpotFeaturesOfLayer = (layer, getStyle) => {
    layer
      .getSource()
      .getFeatures()
      .forEach(feature => {
        feature.setStyle(
          getStyle(feature.getProperties()["spot_vehicle_type"])
        );
      });
  };

  setStyleToAllFeaturesOfLayer = (layer, style) => {
    layer
      .getSource()
      .getFeatures()
      .forEach(feature => {
        feature.setStyle(style);
      });
  };

  updateMap = map => {
    this.setState({
      map
    });
  };

  fetchSpot = spotId => {
    spotActions
      .showSpot(spotId)
      .then(response => {
        this.setState({
          spot: response.data
        });
      })
      .then(() => {
        return;
      });
  };

  setPopup = popup => {
    this.setState({
      popup
    });
  };

  setCurrentPosition = currentPosition => {
    this.setState({
      currentPosition
    });
  };

  setLocations = locations => {
    this.setState({
      locations
    });
  };

  setAreas = areas => {
    this.setState({
      areas
    });
  };

  resetLocation = () => {
    if (this.state.locationId) {
      this.setState({
        areas: [],
        spots: [],
        locationId: "",
        areaId: "",
        allReady: false,
        locationName: "",
        areaStreet: ""
      });
      if (this.state.currentPosition) {
        this.state.map.getView().setCenter(this.state.currentPosition);
        this.state.map.getView().setZoom(14);
      } else {
        this.state.map.getView().setCenter([0, 0]);
        this.state.map.getView().setZoom(1);
      }
    }
    if (this.state.filterForm) {
      this.state.filterForm.resetFields("location_id");
      this.state.filterForm.resetFields("area_id");
    }
  };

  resetArea = () => {
    if (this.state.areaId) {
      this.setState({
        spots: [],
        areaId: "",
        areaStreet: "",
        allReady: false
      });
      if (this.state.currentPosition) {
        this.state.map.getView().setCenter(this.state.currentPosition);
        this.state.map.getView().setZoom(14);
      } else {
        this.state.map.getView().setCenter([0, 0]);
        this.state.map.getView().setZoom(1);
      }
      if (this.state.filterForm) {
        this.state.filterForm.resetFields("area_id");
      }
    }
  };

  setFilterForm = filterForm => {
    this.setState({ filterForm });
  };

  sortAreaUsingDistance = areas => {
    var sortedAreas = areas.sort((area1, area2) => {
      return area1.distance - area2.distance;
    });
    this.setState({
      areas: sortedAreas
    });
  };

  setDirectParked = (directParked, directParkedData = {}) => {
    this.setState({ directParked, directParkedData });
  };

  render() {
    return (
      <ReservationContext.Provider
        value={{
          ...this.state,
          setLocationId: this.setLocationId,
          onAreaChange: this.onAreaChange,
          onSpotClicked: this.onSpotClicked,
          onClose: this.onClose,
          onTypeChange: this.onTypeChange,
          onReversed: this.onReversed,
          updateMapSize: this.updateMapSize,
          fitMap: this.fitMap,
          resetSpotHighlight: this.resetSpotHighlight,
          highlightSpot: this.highlightSpot,
          updateMap: this.updateMap,
          fetchSpot: this.fetchSpot,
          setPopup: this.setPopup,
          setLocations: this.setLocations,
          setAreas: this.setAreas,
          setCurrentPosition: this.setCurrentPosition,
          setDistanceUsingCentroid: this.setDistanceUsingCentroid,
          highlightLocation: this.highlightLocation,
          resetLocationHighlight: this.resetLocationHighlight,
          highlightArea: this.highlightArea,
          resetAreaHighlight: this.resetAreaHighlight,
          setSpots: this.setSpots,
          resetLocation: this.resetLocation,
          resetArea: this.resetArea,
          setFilterForm: this.setFilterForm,
          sortAreaUsingDistance: this.sortAreaUsingDistance,
          setDirectParked: this.setDirectParked,
          fetchAreas: this.fetchAreas
        }}
      >
        {this.props.children}
      </ReservationContext.Provider>
    );
  }
}

export default ReservationContextProvider;
