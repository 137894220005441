import React, {Component} from 'react';
import { ReservationContext } from "../../contexts/ReservationContext";

export default class ParkingRateDisplay extends Component {
  static contextType = ReservationContext;
  render(){
    const { vehicleTypes } = this.context
    const parkTiming = ['Per Hour', 'Per Half Hour', 'Per 15 mins']

    if (vehicleTypes.length === 0) return  null

    return(
      <div className='dashboard-rate-wrapper'>
        <div className='rate-title'>
          Parking Rates
        </div>
        {
          vehicleTypes.map(vehicleType => {
            return(
              <div key={vehicleType.vehicle_cat} className='dashboard-rate'>
                <div className='vehicle-type-title'>
                  {vehicleType.vehicle_cat.toUpperCase()}:
                </div>
                <div className='rate-content-wrapper'>
                  {
                    parkTiming.map((time, index) => {
                      return(
                        <div key={time} className='rate-content'>
                          <div style={{ background: '#D3D3D3'}}>
                            <div style={{ padding: '10px 10px 0px 10px'}}>
                              {time}
                            </div>
                          </div>
                          <div style={{padding: 10}}>
                            Rs. {Math.round(vehicleType.hourly_rate/(index !== 0 ? index * 2 : 1))}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}
