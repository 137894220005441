import React, { Component, Fragment } from "react";
import Media from "react-media";
import { Modal, Row, Col, Divider, Alert } from "antd";
import FormEmployee from "./FormEmployee";

class EditEmployee extends Component {
  render() {
    const {
      editVisible,
      onCancel,
      onEdit,
      isSubmitButtonDisabled,
      employee,
      areas,
    } = this.props;
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(matches) => (
          <Fragment>
            <Modal
              title=""
              centered
              visible={editVisible}
              onCancel={onCancel}
              footer={null}
              width={matches.medium ? "40em" : "60em"}
            >
              <Fragment>
                <br />
                <Row>
                  <Col
                    offset={2}
                    span={20}
                    style={{ background: "rgb(217, 217, 217)", height: 35 }}
                  >
                    <b>
                      <Alert message="Edit Employee" type="default" />
                    </b>
                  </Col>
                </Row>
                <Divider />
                <FormEmployee
                  areas={areas}
                  onSubmit={onEdit}
                  isSubmitButtonDisabled={isSubmitButtonDisabled}
                  employee={employee}
                />
              </Fragment>
            </Modal>
          </Fragment>
        )}
      </Media>
    );
  }
}

export default EditEmployee;
