import React, { Component } from "react";
import { Table,Form, Tag, Pagination, Button, Spin, Divider } from "antd";
import getDate from "../../helpers/getDate";
import getTime from "../../helpers/getTime";
import FilterForm from "./FilterForm";
import { invoiceActions } from "../../actions/InvoiceActions";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { isAdmin } from "../../helpers/userPolicy";
import {
  endDate,
  pageSize,
  startDate,
} from "../../constants/InvoiceFilterConstants";
import { ExportToCsv } from "export-to-csv";
import { ReservationContext } from "../../contexts/ReservationContext";
import EditInvoice from './EditInvoice';
import {success} from "../../helpers/Notification";
import {getErrorMessages} from "../../helpers/getErrors"

class InvoiceTable extends Component {
  static contextType = ReservationContext;
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      updateModal: false,
      modalVisible: false,
      invoiceId: null,
      payment_method: null,
      amount: null
    };
    this.showTable = this.showTable.bind(this);
  }

  handleClearingInvoices = () => {
    this.props.history.push(
      `/invoice?date_gteq=${startDate}&date_lteq=${endDate}&page=1`
    );
  };

  showUpdateModal =(invoice)=>{
    this.setState({
      updateModal: true,
      modalVisible: true,
      invoiceId: invoice.id,
      payment_method: invoice.payment_method,
      amount: invoice.total_payment
    });
  }

  handleCancel = () => {
    const { form } = this.formRef.props;
    this.setState({ modalVisible: false });
    form.resetFields();
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  onUpdate = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values)=>{
      if (!err){
        invoiceActions
          .updateInvoice(values)
          .then(response => {
            if (response.status === 204){
              success("Updated Sucessfully!");
              this.props.fetchInvoiceUsingQuery();
              form.resetFields();
              this.setState({ modalVisible: false });
            }else{
              getErrorMessages(response.data.errors)
            }
        });
      }
    });
  }

  onPaginationChange = (page) => {
    let parsedJson = queryString.parse(this.props.location?.search);
    parsedJson["page"] = page;
    this.props.history.push(
      `/invoice?${queryString.stringify(parsedJson, { encode: false })}`
    );
  };

  getAreaNameAsFiltered = (areaId) => {
    const { onlyAreaNames } = this.context;

    var index = onlyAreaNames.findIndex((item) => item.id === areaId);

    return onlyAreaNames[index].street;
  };

  getAttendantNameAsFiltered = (attendantId) => {
    const { users } = this.props;

    var index = users.findIndex((item) => item.id === attendantId);

    return users[index].name;
  };

  getVehicleTypeAsFiltered = (vehicleTypeId) => {
    const { vehicleTypes } = this.context;

    var index = vehicleTypes.findIndex((item) => item.id === vehicleTypeId);

    return vehicleTypes[index].vehicle_cat;
  };

  formatDataForCsv = (datas) => {
    var formattedData = [];
    datas.map((data) => {
      formattedData.push({
        Parked_By: data.parked_by,
        Collected_By: data.leaved_by,
        Created_Date: getDate(data.created_at) + " " + getTime(data.created_at),
        Area_Name: data.area_name,
        Spot_Name: data.spot_name,
        Vehicle_Number: data.vehicle_no,
        Vehicle_Category: data.vehicle_cat,
        Duration: data.total_time,
        Total_Payment: `Rs. ${data.total_payment}/-`,
        Payment_Method: data.payment_method,
      });
    });

    formattedData.push({
      Parked_By: "",
      Collected_By: "",
      Created_Date: "",
      Area_Name: "",
      Spot_Name: "",
      Vehicle_Number: "",
      Vehicle_Category: "",
      Duration: "Grand Total : ",
      Total_Payment: `Rs. ${this.props.grandTotalPayment}/-`,
      Payment_Method: "",
    });
    return formattedData;
  };

  downloadCsv = () => {
    this.setState({showSpinner: true})
    var filterValues = queryString.parse(this.props.location.search);

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: `\t\t\t\tSpot N Park\n\t\t\t\tInvoice Report\n 
              Vehicle Category : \t${
                filterValues.vehicle_type_id
                  ? this.getVehicleTypeAsFiltered(filterValues.vehicle_type_id)
                  : "All Vehicle Types"
              } 
              Area Name : \t${
                filterValues.area_id
                  ? this.getAreaNameAsFiltered(filterValues.area_id)
                  : "All Areas"
              }
              Collected By: \t${
                filterValues.park_attendant_id
                  ? this.getAttendantNameAsFiltered(
                      filterValues.park_attendant_id
                    )
                  : "All Attendant"
              } ${
        filterValues.date_gteq ? `\nFrom: ${filterValues.date_gteq}` : ""
      }${filterValues.date_lteq ? `\tTo : ${filterValues.date_lteq}` : ""}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    invoiceActions.fetchInvoiceForCsv(filterValues).then((response) => {
      if (response.status === 200) {
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(this.formatDataForCsv(response.data));
      }
      this.setState({showSpinner: false})
    });
  };

  filterInvoiceData(columns){
    let tableData = []
    if (isAdmin()) {
      tableData = columns
    } else {
      tableData = columns.filter(ele => ele.title !== 'Action')
    }
    return tableData;
  }

  showTable() {
    const { onlyAreaNames, vehicleTypes } = this.context;
    const { invoices, users, isInvoiceLoading, initialValue, grandTotalPayment, currentPage, total_invoices } = this.props;
    const columns = [
      {
        title: "Parked By",
        dataIndex: "parked_by",
      },
      {
        title: "Collected By",
        dataIndex: "leaved_by",
      },
      {
        title: "Created Date",
        dataIndex: "created_at",
        render: (text, record) => {
          return getDate(record.created_at) + " " + getTime(record.created_at);
        },
      },
      {
        title: "Area Name",
        dataIndex: "area_name",
      },
      {
        title: "Vehicle No.",
        dataIndex: "vehicle_no",
      },
      {
        title: "Vehicle Category",
        dataIndex: "vehicle_cat",
      },
      {
        title: "Duration",
        dataIndex: "total_time",
      },
      {
        title: "Total Payment",
        render: (text, record) => {
          return `Rs. ${record.total_payment}/-`;
        },
      },
      {
        title: "Payment Method",
        dataIndex: "payment_method",
      },
      {
        title: "Action",
        key: "action",
        width: '200px',
        render: (record) => {
          return (
            <span>
              <Button
                type="danger"
                ghost
                onClick={() =>this.props.showDeleteConfirm(record.id)}
              >
              Delete
              </Button>
              <Divider type="vertical" />
              <Button
                type="primary"
                ghost
                onClick={() =>this.showUpdateModal(record)}
              >
              Edit
              </Button>
            </span>
          );
        },
      },
    ];
    return (
      <div>
        <FilterForm
          form={this.props.form}
          users={users}
          areas={onlyAreaNames}
          handleFilter={this.props.handleFilterInvoices}
          handleClear={this.handleClearingInvoices}
          initialDateRange={initialValue.date_range}
          isInvoiceLoading={isInvoiceLoading}
          vehicleTypes={vehicleTypes}
        />

        <Table
          columns={this.filterInvoiceData(columns)}
          title={() => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginRight: "1rem",
                  gap: "1em",
                }}
              >
                <div>
                  {invoices.length > 0 && (
                    <Button
                      type="primary"
                      shape="round"
                      icon="download"
                      size="default"
                      disabled={this.state.showSpinner}
                      onClick={() => {
                        this.downloadCsv();
                      }}
                    >
                      <Spin size="small" className="download-button-spinner" spinning={this.state.showSpinner} />
                      Download in Csv
                    </Button>
                  )}
                </div>
                <div>
                  <b>Grand Total: </b>
                  <Tag color={"green"}>
                    Rs. {grandTotalPayment} /-
                  </Tag>
                </div>
              </div>
            );
          }}
          dataSource={invoices}
          rowKey={(column) => column.id}
          loading={isInvoiceLoading}
          pagination={false}
        />
        <div
          style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
        >
          <Pagination
            total={total_invoices}
            onChange={this.onPaginationChange}
            current={currentPage}
            disabled={isInvoiceLoading}
            hideOnSinglePage={true}
            defaultPageSize={pageSize}
          />
        </div>
      </div>
    );
  }

  render() {
    return(
      <div>
        {this.showTable()}

        {this.state.updateModal && ( <EditInvoice
          wrappedComponentRef={this.saveFormRef}
          visible = {this.state.modalVisible}
          onCancel = {this.handleCancel}
          invoiceId={this.state.invoiceId}
          onUpdate={this.onUpdate}
          payMethod={this.state.payment_method}
          amount={this.state.amount}
        />)}
      </div>
    );
  }
}
export default withRouter(Form.create({})(InvoiceTable));
