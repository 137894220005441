import React, { Component } from "react";
import { withRouter } from "react-router";
import { Form, Input, Button, Icon, Select } from "antd";
import { userActions } from "../../../actions/UserActions";

import { success, error } from "../../../helpers/Notification";
import ConfirmPhoneNumber from "../../Confirmation/confirmationOtp";
import Wrapper from "../Index";

const Option = { Select };

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      confirmDirty: false,
      phone_no: "",
      signupButton: false,
      otpButton: false,
      loginStatus: false,
    };
    this.submitOtpForm = this.submitOtpForm.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ signupButton: true });
        userActions
          .confirmations(values, this.props.history)
          .then((response) => {
            if (response.status === 200) {
              success(response.data.success);
              this.setState({
                loginStatus: true,
                visible: true,
                phone_no: values.phone_no,
              });
            } else {
              error(response.data.error);
            }
            this.setState({ signupButton: false });
          });
      }
    });
  };

  submitOtpForm = (e) => {
    e.preventDefault();
    this.formRef.validateFields((err, values) => {
      if (!err) {
        this.setState({ otpButton: true });
        userActions.register(values, this.props.history).then((response) => {
          if (response.status === 200) {
            success("Welcome here! To Park");
            this.props.history.push("/login");
          } else {
            this.setState({ signupButton: false, otpButton: false });
            error(
              response.data.error || "Something went wrong ! Please try again"
            );
          }
          this.setState({ otpButton: false });
        });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;

    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value) {
      if (value.length < 6) {
        callback("Password must be minimum length of 6 !");
      } else if (this.state.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      } else {
        callback();
      }
    }
    callback();
  };

  handleCancel() {
    this.props.history.push("/login");
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "+977",
    })(
      <Select style={{ width: 80 }}>
        <Option value="977">+977</Option>
      </Select>
    );
    return (
      <Wrapper>
        <div className="formWrapper">
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="mail" />}
                  placeholder="Email"
                  name="email"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("phone_no", {
                rules: [
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ],
              })(
                <Input
                  addonBefore={prefixSelector}
                  onChange={this.handleChange}
                  name="phone"
                  placeholder="Mobile Number"
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Please input your name!",
                    whitespace: true,
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="user" />}
                  name="name"
                  onChange={this.handleChange}
                  placeholder="Name"
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.state.signupButton}
                style={{
                  background: "black",
                  padding: "20px",
                  color: "yellow",
                  fontSize: "20px",
                  fontWeight: "bold",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "20px",
                }}
                block
              >
                Register
              </Button>
            </Form.Item>
            <Form.Item>
              <div style={{ textAlign: "center" }}>
                <a href="/login">Already have an account?</a>
              </div>
            </Form.Item>
          </Form>

          {this.state.loginStatus ? (
            <ConfirmPhoneNumber
              wrappedComponentRef={this.saveFormRef}
              visible={this.state.visible}
              handleCancel={this.handleCancel.bind(this)}
              phone_no={this.state.phone_no}
              status="Register"
              otpButton={this.state.otpButton}
              submitOtpForm={this.submitOtpForm}
            />
          ) : null}
        </div>
        {/* </Modal> */}
      </Wrapper>
    );
  }
}

export default withRouter(Form.create({})(Register));
