import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from "../Layout/Custom_Layout/Layout";
import { Button, Row, Col, Divider, Icon } from "antd";
import { isAdmin, isEmployee, isAccountant, isUser } from "../../helpers/userPolicy";
import TableReservation from "./TableReservation";
import { reservationActions } from "../../actions/ReservationActions";

const ButtonGroup = Button.Group;

class IndexReservation extends Component {
  constructor(){
    super();
    this.state= ({
      reservations: [],
      showSpinner: true
    })
  }

  componentDidMount(){
    if(isAccountant() || isUser()){
      this.props.history.push("unauthorized")
    }else{
      this.fetchReservationData();
    }
  }
  
  fetchReservationData=()=> {
    this.setState({showSpinner:true})
    reservationActions.fetchReservation().then(response => {
      if (response.status === 200) {
        this.setState({ reservations: response.data, showSpinner: false });
      }
    });
  }

  render() {
    return (
      <div>
        <Layout sidebarSelectedKey="reservation">
          <Row>
            <Col>
              <span style={{fontSize: "1.25rem"}}>Reservation Information &nbsp;
                <Icon type="sync" className="reload-button" spin = {this.state.showSpinner} onClick={() => this.fetchReservationData()} title="Reload Reservations" />
              </span>  
              {isAdmin() || isEmployee() ? (
                <ButtonGroup className="btn-right">
                  <Button>
                    <Link
                      to="/reservations/user"
                      style={{ textDecoration: "none" }}
                    >
                      <span>User View</span>
                    </Link>
                  </Button>
                  <Button type="primary">
                    <Link
                      to="/reservation/create"
                      style={{ textDecoration: "none" }}
                    >
                      <span>Create New</span>
                    </Link>
                  </Button>
                </ButtonGroup>            
              ) : null}
            </Col>
          </Row>
          <Divider />
          {(isAdmin() || isEmployee()) && 
            <TableReservation 
              reservations={this.state.reservations}
              fetchReservationData={this.fetchReservationData}
              showSpinner={this.state.showSpinner}
            />
          }
        </Layout>
      </div>
    );
  }
}

export default IndexReservation;
