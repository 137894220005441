import customHttp from "../helpers/CustomHttp";
import {authHeader} from "../helpers/AuthHeader";
import moment from "moment";
import { isAdmin } from "../helpers/userPolicy";

export const invoiceActions = {
  createInvoice,
  showInvoice,
  fetchInvoice,
  deleteInvoice,
  fetchInvoiceForCsv,
  updateInvoice
};

function createInvoice(park_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    url: `/invoices`,
    data: {park_id: park_id},
  };
  return customHttp(requestOptions).then((response) => {
    return response;
  });
}

function updateInvoice(values) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    url: `/invoices/${values.id}`,
    data: values
  };
  return customHttp(requestOptions).then((response) => {
    return response;
  });
}

function fetchInvoice(values) {
  const requestOptions = {
    method: "GET",
    url: "/invoices",
    headers: authHeader(),
    params: getInvoiceFilterParams(values),
  };
  return customHttp(requestOptions).then((response) => {
    return response;
  });
}

function deleteInvoice(invoice_id) {
  const requestOptions = {
    method: "PUT",
    url: `/invoices/${invoice_id}/soft_delete`,
    headers: authHeader()
  };
  return customHttp(requestOptions).then((response) => {
    return response
  });
}

function fetchInvoiceForCsv(values) {
  const filterParams = getInvoiceFilterParams(values);
  const csvParams = {csv: true};
  const params = {...filterParams, ...csvParams};

  const requestOptions = {
    method: "GET",
    url: "/invoices",
    headers: authHeader(),
    params: params,
  };
  return customHttp(requestOptions).then((response) => {
    return response;
  });
}

function showInvoice(id) {
  const requestOptions = {
    method: "get",
    headers: authHeader(),
    url: `/invoice/${id}`,
  };
  return customHttp(requestOptions).then((response) => {
    return response;
  });
}

function getInvoiceFilterParams(values) {
  var params = { q: {} };
  if (values["vehicle_type_id"]) {
    params["q"]["vehicle_type_id_eq"] = values["vehicle_type_id"];
  }
  if (values["park_attendant_id"]) {
    params["q"]["park_attendant_id_eq"] = values["park_attendant_id"];
  }
  if (values["area_id"]) {
    params["q"]["area_id_eq"] = values["area_id"];
  }
  if (values["vehicle_no"]){
    params["q"]["vehicle_no_i_cont"] = values["vehicle_no"]
  }
  if (values["date_lteq"]) {
    params["q"]["date_lteq"] = moment(values["date_lteq"], "MM/DD/YYYY").format(
      "DD/MM/YYYY"
    );
  }
  if (values["date_gteq"]) {
    params["q"]["date_gteq"] = moment(values["date_gteq"], "MM/DD/YYYY").format(
      "DD/MM/YYYY"
    );
  }
  if(!values['payment_status'] && isAdmin()){
    params['q']['payment_status_eq'] = true
  }
  params["page"] = parseInt(values["page"]);
  return params;
}
