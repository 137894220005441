import customHttp from '../helpers/CustomHttp'
import { authHeader } from "../helpers/AuthHeader";

export const vehicleTypeActions = {
  fetchVehicleTypes,
  createVehicleType, 
  showVehicleType,
  updateVehicleType,
  deleteVehicleType
}

function fetchVehicleTypes() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    url : `/vehicle_types`
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function createVehicleType(values) {
  const data = formatFormData(values);
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    url : `/vehicle_types`,
    data
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function showVehicleType(id) {
  const requestOptions = {
    method: 'get',
    headers: authHeader(),
    url : `/vehicle_types/${id}`,
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function updateVehicleType(values, id) {
  const data = formatFormData(values);
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    url : `/vehicle_types/${id}`,
    data
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function deleteVehicleType(id) {
  const requestOptions = {
    method: 'delete',
    headers: authHeader(),
    url : `/vehicle_types/${id}`,
  }
  return customHttp(requestOptions).then(response => {
    return response
  })
}

function formatFormData(values) {
  const data = new FormData();
  Object.keys(values).forEach(key => {
    data.append(key, values[key]);
  })
  return data;
}
