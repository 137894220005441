import React, { Component } from "react";
import { Table, Button, Pagination, Divider } from "antd";
import getDate from "../../helpers/getDate";
import getTime from "../../helpers/getTime";
import { pageSize } from "../../constants/ParkedVehicleFilterConstants";
import { isAccountant } from "../../helpers/userPolicy";

class ParkedVehicleTable extends Component {
  tableColumns = () => {
    return [
      {
        title: "User Name",
        dataIndex: "user_name",
      },
      {
        title: "Parked By",
        dataIndex: "parked_by",
      },
      {
        title: "Vehicle No.",
        dataIndex: "vehicle_no",
      },
      {
        title: "Vehicle Type",
        dataIndex: "vehicle_cat",
      },
      {
        title: "Area",
        dataIndex: "area_name",
      },
      {
        title: "Spot",
        dataIndex: "spot_name",
      },
      {
        title: "Parked At",
        render: (record) => {
          if (record.entry_timestamp) {
            return (
              getDate(record.entry_timestamp) +
              "  " +
              getTime(record.entry_timestamp)
            );
          }
        },
      },
      {
        title: "Rate",
        render: (record) => {
          return `Rs. ${record.hourly_rate}`;
        },
      },
      {
        title: "Action",
        key: "action",
        width: "250px",
        render: (record) => {
          return (
            <span>
              <Button
                type="primary"
                ghost
                onClick={() => this.props.showConfirm(record.id)}
              >
                Leave
              </Button>
              <Divider type="vertical" />
              <Button
                type="danger"
                ghost
                onClick={() =>this.props.showDeleteConfirm(record.id)}
              >
              Delete
              </Button>
            </span>
          );
        },
      },
    ];
  };

  render() {
    let tableData = []
    const {
      parkedVehicles,
      showSpinner,
      totalParkedVehicles,
      currentPage,
      onPaginationChange,
    } = this.props;
    if (isAccountant()) {
      tableData = this.tableColumns().filter(ele => ele.title !== 'Action')
    } else {
      tableData = this.tableColumns()
    }
    return (
      <>
        <Table
          columns={tableData}
          dataSource={parkedVehicles}
          rowKey={(column) => column.id}
          loading={showSpinner}
          pagination={false}
        />
        <Pagination
          style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          total={totalParkedVehicles}
          onChange={onPaginationChange}
          current={currentPage}
          disabled={showSpinner}
          // hideOnSinglePage={true}
          defaultPageSize={pageSize}
        />
      </>
    );
  }
}
export default ParkedVehicleTable;
