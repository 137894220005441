import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Alert,
  Divider,
  Tooltip,
  Icon,
  Input,
  Button,
  List,
  Card,
  Form,
  TreeSelect
} from "antd";
import Media from "react-media";
import { Link } from "react-router-dom";
import { areaActions } from "../../actions/AreaActions";
import { boardActions } from "../../actions/BoardActions";
import { success, error } from "../../helpers/Notification";
import { getErrorMessages } from "../../helpers/getErrors";


class EditBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardName: this.props.board.name,
      boardNumber: this.props.board.board_number,
      phoneNumber: this.props.board.phone_number || "",
      areas: [],
      boardSpots:[],
      boardSpotIds: [],
      treeData: [],
      submitButtonDisable: false
    };
    this.child = React.createRef();
  }

  componentDidMount = () => {
    areaActions.fetchAreasStreets().then((response) => {
      const boardSpotIds = this.props.board.spots.map(
        (boardSpot) => boardSpot.spot_id
      );
      const boardSpots = response.data
        .map((area) => 
        area.spots.filter((spot) => boardSpotIds.includes(spot.id))
        )
        .flat(Infinity);
        const treeData = response.data.map(area => {
          return {
            title: area.street,
            value: area.id,
            key: area.id,
            children: area.spots.map(spot => {
              return {
                title: spot.name,
                value: spot.id,
                key: spot.id,
                isLeaf: true
              };
            }),
          };
        });
      this.setState({
        areas: response.data,
        boardSpots,
        boardSpotIds,
        treeData
      })
    })
  }
  
  onChangeSpots = (spotIds) => {
    const areaSpots = this.state.areas.map((area) => area.spots).flat(Infinity) 
    const boardSpots = areaSpots.filter((spot) => spotIds.includes(spot.id))
    this.setState({
      boardSpots
    })
  }

  handleBoardEdit = (e) => {
    e.preventDefault();
    const { boardSpots, boardSpotIds } = this.state;
    const spotsToUpdate = boardSpots.filter(spot => !boardSpotIds.includes(spot.id))
                                    .map(spot => {return {spot_id: spot.id}});  
    const newBoardSpotIds = boardSpots.map(spot => spot.id)
    const ids = boardSpotIds.filter((spotId) => !newBoardSpotIds.includes(spotId))
    const deleteValues = {board_spots: { ids }};
    const { form } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        const boardValues = {
          board: {
            name: values.boardName,
            board_number: values.boardNumber,
            board_spots_attributes: spotsToUpdate,
            phone_number: values.phoneNumber
          }
        }
        this.setState({ submitButtonDisable: true })
        boardActions.updateBoard(boardValues, this.props.board.id).then((response) => {
          if (response.status === 204) {
            boardActions.deleteBoardSpot(deleteValues).then((response) => {
              if (response.status !== 204) {
                error(response.data.error)
              }
              success("Board Sucessfully Updated!")
              this.props.onEdit();
            })
          }else{
            getErrorMessages(response.data.errors);
          }
        this.setState({ submitButtonDisable: false})
        })
      }
    })
  }

  handleRemoveSpot = (spotId) => {
    const newBoardSpots = this.state.boardSpots.filter(
      (boardSpot) => boardSpot.id !== spotId
    )
    this.props.form.setFieldsValue({
      spotIds: newBoardSpots.map(spot => spot.id)
    })
    this.setState({
      boardSpots: newBoardSpots
    });
  };

  render() {
    const { editBoardVisible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const {  boardSpots } = this.state
    var defaultValue = boardSpots.map((boardSpot) => boardSpot.id)
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)"
        }}
      >
      {(matches) => (
        <Modal
          centered
          visible={editBoardVisible}
          onCancel={onCancel}
          footer={null}
          width={matches.medium ? "54em" : "75em"}
        >
          <div>
            <Row>
              <Col
                offset={1}
                span={22}
                gutter={1}
                style={{
                  background: "rgb(217,217,217)",
                  height: "2.5em"
                }}
              >
                <b>
                  <Alert message="Edit Board Details" type="default"/>
                </b>
              </Col>
            </Row>
            <Divider/>
            <Row>
              <Col offset={2} span={20}>
                <Alert message={this.state.boardName} type="info" showIcon/>
              </Col> 
            </Row>
            <Row>
              <Col offset={2} span={20}>
                <Divider />
              </Col>
            </Row>
            <Form onSubmit={(e) => this.handleBoardEdit(e)}>
              <Row>
                <Col offset={2} span={20} gutter={2}>
                  <Form.Item
                    label={
                      <span>
                        Board Number &nbsp;
                        <Tooltip title="Board Number">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("boardNumber",{
                      rules:[
                        {
                          required: true,
                          message: "Please enter Board Number!",
                          whitespace: true
                        }
                      ],
                      initialValue: this.state.boardNumber.toString()
                    })(
                      <Input
                        placeholder={"Enter Board Number"}
                        name="boardNumber"
                      />
                    )}
                  </Form.Item>
                  
                  <Form.Item
                    label={
                      <span>
                        Board Name &nbsp;
                        <Tooltip title="Name of Board">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("boardName",{
                      rules:[
                        {
                          required: true,
                          message: "Please enter Board Name!",
                          whitespace: true
                        }
                      ],
                      initialValue: this.state.boardName
                    })(
                      <Input
                        placeholder={"Enter New Board Name"}
                        name="boardName"
                      />
                    )}
                  </Form.Item>

                  <Form.Item
                    label={
                      <span>
                        Phone Number &nbsp; 
                        <Tooltip title="Phone Number">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("phoneNumber")(
                      <Input
                        placeholder={"Enter your Phone Number"}
                        name="phoneNumber"
                      />
                    )}
                  </Form.Item>

                  <Form.Item 
                    label={
                      <span>
                        Select Spot &nbsp;
                        <Tooltip title="Choose Spots for the Board">
                          <Icon type="question-circle-o"/>
                        </Tooltip>
                      </span>
                    }
                    
                  >
                    {getFieldDecorator("spotIds", {
                      rules:[
                        {
                          required: true,
                          message: "Select at least one Spot!",
                        }
                      ],
                      initialValue: defaultValue,
                    })(
                      <TreeSelect
                        showSearch
                        allowClear
                        treeData={ this.state.treeData }
                        onChange={this.onChangeSpots}
                        value={defaultValue}
                        treeNodeFilterProp="title"
                        treeCheckable={true}
                        style={{width: "100%"}}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto"}}
                        searchPlaceholder={"Select Area or Spots!"}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ 
                  overflow: "auto",
                  maxHeight: `${matches.medium ? "330px" : "500px"}`
              }}>
                <Col offset={2} span={20} gutter={2}>
                  <Divider />
                  <List 
                    grid={{
                      gutter: 3,
                      xs: 4
                    }}
                    dataSource={boardSpots}
                    renderItem={(spot) => (
                      <List.Item>
                        <Card size="small">
                          {this.state.removeButtonDisable ? (
                            <Link style={{ fontSize: 16, color: "#b7bdb9" }}>
                              <Icon 
                                type="close-circle"
                                className="btn-right"
                              />
                            </Link>
                          ) : (
                            <span
                              onClick={() => this.handleRemoveSpot(spot.id)}
                            >
                              <Icon 
                                type="close-circle"
                                className="btn-right"
                              />
                            </span>
                          )}
                          <h3>{spot.name}</h3>
                          <p> Area: {
                            this.state.areas.find(element => element.id === spot.area_id).street
                          } </p>
                        </Card>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col offset={2} span={20} gutter={2}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-right"
                      disabled={this.state.submitButtonDisable}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      )}
    </Media>
    )
  }
}

export default Form.create({})(EditBoard);
