import React, { Component } from "react";
import {
  Row,
  Col,
  Divider,
  Descriptions,
  Table,
  Tag,
  Modal,
  Button
} from "antd";
import company from "../Invoice/image/company.jpg";
import MyDocument from "../Invoice/pdfGenerator";
import { PDFDownloadLink } from "@react-pdf/renderer";
import getTime from "../../helpers/getTime";
import getDate from "../../helpers/getDate";

class Invoice extends Component {
  render() {
    const { visible, onCancel, invoiceData } = this.props;
    const invoice = invoiceData[0];
    const columns = [
      {
        title: "Description",
        dataIndex: "description"
      },
      {
        title: "Rate (per hour)",
        dataIndex: "rate"
      },
      {
        title: "Time",
        dataIndex: "time"
      },
      {
        title: "Total",
        dataIndex: "total"
      }
    ];
    const data = [
      {
        key: invoice.id,
        description: invoice.description,
        rate: "Rs. " + invoice.rate + "/-",
        time: invoice.time,
        total: "Rs. " + invoice.total + "/-"
      }
    ];
    return (
      <Modal
        title=""
        centered
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={"100em"}
      >
        <div style={{ padding: "2em" }} id="nodeToRenderAsPDF">
          <Row>
            <Col span={8}>
              <p className="Header-text">Invoice</p>
              <span>no. : #{invoice.invoice_id}</span>
              <br />
              <span>Invoice to : {invoice.user_name}</span><br />
              <span>Parked by : {invoice.parked_by}</span>
            </Col>
            <Col offset={16}>
              <img src={company} className="Invoice-logo" alt="logo" />
            </Col>
          </Row>
          <p>{getDate(invoice.leave_timestamp)}</p>
          <Divider />
          <Descriptions layout="vertical" size="small">
            <Descriptions.Item label="Area Name">
              {invoice.area_name}
            </Descriptions.Item>
            <Descriptions.Item label="Spot Name">
              {invoice.spot_name}
            </Descriptions.Item>
            <Descriptions.Item label="Vehicle Type">
              {invoice.vehicle_cat}
            </Descriptions.Item>
            <Descriptions.Item label="Rate">{invoice.rate}</Descriptions.Item>
            <Descriptions.Item label="Vehicle No.">
              {invoice.vehicle_no}
            </Descriptions.Item>
            <Descriptions.Item label="Booked time">
              {invoice.book_timestamp
                ? getTime(invoice.book_timestamp)
                : "Directly Parked."}
            </Descriptions.Item>
            <Descriptions.Item label="Parked time">
              {getTime(invoice.entry_timestamp)}
            </Descriptions.Item>
            <Descriptions.Item label="Leave Time">
              {getTime(invoice.leave_timestamp)}
            </Descriptions.Item>
          </Descriptions>
          <Divider />

          <Table
            columns={columns}
            dataSource={data}
            size="small"
            pagination={false}
            bordered
          />
          <Divider />
          <Row>
            <Col offset={2} span={20}>
              <Row>
                <Col offset={22} span={2}>
                  <Row>
                    <Col span={2}>
                      <span>
                        <Tag color="geekblue">
                          Tax : {invoice.tax.toFixed(2)}
                        </Tag>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={2}>
                      <span>
                        <Tag color="red">
                          Discount : {invoice.discount.toFixed(2)}
                        </Tag>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={2}>
                      <span>
                        <Tag color="green">
                          Grand Total : Rs. {invoice.total}/-
                        </Tag>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={16}>
              <h6>Terms and Conditions</h6>
              <div>
                As a condition of use, you promise not to use Services for any
                propose that is unlawful or probihited by these Terms.
                <ul>
                  <li>Term 1</li>
                  <li>Term 2</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <Divider />
        <Button type="primary" ghost className="btn-right" onClick={onCancel}>
          <PDFDownloadLink
            document={
              <MyDocument data={data} columns={columns} invoice={invoice} />
            }
            fileName="invoice.pdf"
          >
            {({ loading }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>
        </Button>
        <br />
        <Divider />
      </Modal>
    );
  }
}

export default Invoice;
