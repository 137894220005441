import React, {Component } from "react";
import { withRouter } from "react-router";
import { Menu } from "antd";
import CustomLayout from "../Layout/Custom_Layout/Layout";
import { isAdmin } from "../../helpers/userPolicy";
import NotificationView from "./NotificationView";
import Spinner from "../loadSpinner/loadSpinner";
import { ReservationContext } from "../../contexts/ReservationContext";
import Media from "react-media";

class AreaList extends Component{
  static contextType = ReservationContext;

  constructor(){
    super();
    this.state = {areaId: null}
  }

  componentDidMount() {
    if (!isAdmin()){
      this.props.history.push("/unauthorized");
    }
  }

  onAreaChange= (id) =>{
   this.setState({
    areaId: id
   })
  }
  
  render(){
    const { onlyAreaNames, showSpinner } = this.context
    return (
      <CustomLayout sidebarSelectedKey="notifications">
        <Media
          queries={{
            small: "(max-width: 599px)",
            medium: "(min-width: 600px) and (max-width: 1199px)",
            large: "(min-width: 1200px)"
          }}
        >
          {(matches) => (
            !showSpinner ?
            <>
              { onlyAreaNames.length > 0 &&
                <div style={{width:matches.large? "30%" : "100%", float: "left",height: matches.large? "65vh":"40vh", overflow: "scroll", paddingRight: 5}}>
                  <Menu mode="inline" defaultSelectedKeys={['1']} >
                    <Menu.Item key = "1" onClick = {() => this.onAreaChange("null")}>
                    For All Area
                    </Menu.Item>
                    { onlyAreaNames.map(area =>  (
                      <Menu.Item key={area.id} onClick={()=> this.onAreaChange(area.id)}
                      >
                        {area.street}
                      </Menu.Item>
                    )
                    )}
                  </Menu>
                </div>
              }
              { onlyAreaNames.length>0 && 
                <NotificationView
                  areaId= {this.state.areaId }
              />}
            </>
            : <Spinner />
          )}
        </Media>
      </CustomLayout>
    )
  }
}
export default withRouter(AreaList);
